import React, { useState } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { makeStyles, Grid, Typography, Button, Tooltip, Container } from '@material-ui/core';
import Carousel from '../../reusable/Carousel';

const capFirst = str => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1);
};

const getTimeBetween = (startTime, endTime) => {
  let start = moment.utc(startTime);
  let end = moment.utc(endTime);
  let dur = Math.round(Math.abs(moment.duration(end.diff(start)).asMinutes()));
  return dur;
};

////////// COMPONENT //////////
function MoveDetailsLocation(props) {
  const cls = useStyles();

  const { type, move, tookan } = props;

  const [carousel, setCarousel] = useState(false);

  const getTime = () => {
    if (type === `pickup` && move.pickup_started) return moment(move.pickup_started).format(`MM/DD/YYYY LT`);
    else if (type === `delivery` && move.delivery_successful)
      return moment(move.delivery_successful).format(`MM/DD/YYYY LT`);
    else if (move[`${type}_time`]) return moment(move[`${type}_time`]).format(`MM/DD/YYYY LT`);
    else return `N/A`;
  };

  const goToLocationDetails = () => {
    props.history.push(`/locations/${type === `pickup` ? move.lane.pickup.id : move.lane.delivery.id}`);
  };

  const formatPickupStarted = moment(move.pickup_started).format(`LT`);
  const formatPickupArrived = moment(move.pickup_arrived).format(`LT`);
  const formatPickupSuccessful = moment(move.pickup_successful).format(`LT`);
  const formatDeliveryStarted = moment(move.delivery_started).format(`LT`);
  const formatDeliveryArrived = moment(move.delivery_arrived).format(`LT`);
  const formatDeliverySuccessful = moment(move.delivery_successful).format(`LT`);

  const pickupDrive = getTimeBetween(move.pickup_started, move.pickup_arrived);
  const pickupInspect = getTimeBetween(move.pickup_arrived, move.pickup_successful);
  const pickupDwell = getTimeBetween(move.pickup_successful, move.delivery_started);
  const deliveryDrive = getTimeBetween(move.delivery_started, move.delivery_arrived);
  const deliveryInspect = getTimeBetween(move.delivery_arrived, move.delivery_successful);

  const pickupTotal = pickupDrive + pickupInspect + pickupDwell;
  const deliveryTotal = deliveryDrive + deliveryInspect;

  const totalTime = pickupDrive + pickupInspect + pickupDwell + deliveryDrive + deliveryInspect;

  const useBarStyles = makeStyles(theme => ({
    p1: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${!isNaN(pickupTotal) ? (pickupDrive / pickupTotal) * 100 : 33.333}%`,
      height: theme.spacing(1),
      background: theme.palette.primary.main,
    },
    p2: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${!isNaN(pickupTotal) ? (pickupInspect / pickupTotal) * 100 : 33.333}%`,
      height: theme.spacing(1),
      background: theme.palette.secondary.main,
    },
    p3: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${!isNaN(pickupTotal) ? (pickupDwell / pickupTotal) * 100 : 33.333}%`,
      height: theme.spacing(1),
      background: theme.palette.secondary.light,
    },
    d1: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${!isNaN(deliveryTotal) ? (deliveryDrive / deliveryTotal) * 100 : 50}%`,
      height: theme.spacing(1),
      background: theme.palette.primary.main,
    },
    d2: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${!isNaN(deliveryTotal) ? (deliveryInspect / deliveryTotal) * 100 : 50}%`,
      height: theme.spacing(1),
      background: theme.palette.secondary.main,
    },
  }));
  const barCls = useBarStyles();

  try {
    return (
      <>
        <div className={cls.paper}>
          <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
            <Grid item xs>
              <Tooltip placement='top-start' title={`${capFirst(type)} Location ID`}>
                <Typography className={cls.headTxtL}>
                  {capFirst(type)}&nbsp;#
                  {type === `pickup` ? move.lane.pickup.id : move.lane.delivery.id}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip placement='top-end' title={`${capFirst(type)} Time`}>
                <Typography className={cls.headTxtR}>{getTime()}</Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <div className={cls.smallBreak} />

          <Tooltip placement='top' title={`Click to see details for this location`}>
            <div
              className={cls.location}
              onClick={() => {
                goToLocationDetails();
              }}
            >
              <Typography className={cls.nameTxt}>{move.lane[type].name}</Typography>
              <Typography className={cls.addressTxt}>{move.lane[type].address}</Typography>
            </div>
          </Tooltip>

          <div className={cls.midBreak} />

          <div className={cls.block}>
            {type === `pickup` ? (
              <>
                <Tooltip
                  title={`Pickup Drive - ${
                    pickupDrive ? `${pickupDrive} minutes (${formatPickupStarted} to ${formatPickupArrived})` : `N/A`
                  }`}
                >
                  <div className={barCls.p1} />
                </Tooltip>
                <Tooltip
                  title={`Pickup Inspection - ${
                    pickupInspect
                      ? `${pickupInspect} minutes (${formatPickupArrived} to ${formatPickupSuccessful})`
                      : `N/A`
                  }`}
                >
                  <div className={barCls.p2} />
                </Tooltip>
                <Tooltip
                  title={`Pickup Dwelling - ${
                    pickupDwell
                      ? `${pickupDwell} minutes (${formatPickupSuccessful} to ${formatDeliveryStarted})`
                      : `N/A`
                  }`}
                >
                  <div className={barCls.p3} />
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip
                  title={`Delivery Drive - ${
                    deliveryDrive
                      ? `${deliveryDrive} minutes (${formatDeliveryStarted} to ${formatDeliveryArrived})`
                      : `N/A`
                  }`}
                >
                  <div className={barCls.d1} />
                </Tooltip>
                <Tooltip
                  title={`Delivery Inspection - ${
                    deliveryInspect
                      ? `${deliveryInspect} minutes (${formatDeliveryArrived} to ${formatDeliverySuccessful})`
                      : `N/A`
                  }`}
                >
                  <div className={barCls.d2} />
                </Tooltip>
              </>
            )}
          </div>
          <Grid container spacing={0} justify='space-between' wrap='nowrap'>
            {type === `pickup` ? (
              <>
                <Grid item xs={4} className={cls.leftTxt + ' ' + cls.ellipse}>
                  <Typography className={cls.timeTxtKey + ' ' + cls.inline}>Drive:&nbsp;</Typography>
                  <Typography className={cls.timeTxtVal + ' ' + cls.inline}>
                    {pickupDrive ? `${pickupDrive}m` : `0m`}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={cls.midTxt + ' ' + cls.ellipse}>
                  <Typography className={cls.timeTxtKey + ' ' + cls.inline}>Inspect:&nbsp;</Typography>
                  <Typography className={cls.timeTxtVal + ' ' + cls.inline}>
                    {pickupInspect ? `${pickupInspect}m` : `0m`}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={cls.rightTxt + ' ' + cls.ellipse}>
                  <Typography className={cls.timeTxtKey + ' ' + cls.inline}>Dwell:&nbsp;</Typography>
                  <Typography className={cls.timeTxtVal + ' ' + cls.inline}>
                    {pickupDwell ? `${pickupDwell}m` : `0m`}
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={4} className={cls.leftTxt + ' ' + cls.ellipse}>
                  <Typography className={cls.timeTxtKey + ' ' + cls.inline}>Drive:&nbsp;</Typography>
                  <Typography className={cls.timeTxtVal + ' ' + cls.inline}>
                    {deliveryDrive ? `${deliveryDrive}m` : `0m`}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={cls.midTxt + ' ' + cls.ellipse}>
                  <Typography className={cls.timeTxtKey + ' ' + cls.inline}>Inspect:&nbsp;</Typography>
                  <Typography className={cls.timeTxtVal + ' ' + cls.inline}>
                    {deliveryInspect ? `${deliveryInspect}m` : `0m`}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={cls.rightTxt + ' ' + cls.ellipse}>
                  <Typography className={cls.timeTxtKey + ' ' + cls.inline}>Total:&nbsp;</Typography>
                  <Typography className={cls.timeTxtVal + ' ' + cls.inline}>
                    {totalTime ? `${totalTime}m` : `0m`}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <div className={cls.break} />

          <Button
            variant='outlined'
            size='large'
            style={{ width: '100%' }}
            onClick={() => setCarousel(true)}
          >{`View ${capFirst(type)} Images`}</Button>
          {carousel && <Carousel images={tookan.images} setCarousel={setCarousel} />}

          {tookan.signature ? (
            <>
              <div className={cls.break} />
              <Typography className={cls.headTxt}>{`${capFirst(type)} Signature`}</Typography>
              <div className={cls.smallBreak} />
              <Grid container spacing={2} wrap='nowrap'>
                <Grid item>
                  <Typography
                    className={cls.infoTxt}
                  >{`The ${type} signature represents the confirmation of the specified vehicle being ${
                    type === `pickup` ? `picked up at` : `delivered to`
                  } the correct location by the correct driver.`}</Typography>
                </Grid>
                <Grid item xs>
                  <div className={cls.sig}>
                    <img className={cls.sigImage} src={tookan.signature} alt={`${type} signature`} />
                    <Typography className={cls.sigTxt}>{tookan.name ? tookan.name : `N/A`}</Typography>
                  </div>
                </Grid>
              </Grid>
            </>
          ) : null}
        </div>
      </>
    );
  } catch (error) {
    console.error(error);
    return (
      <div className={cls.rootTable}>
        <Container maxWidth='sm'>
          <div className={cls.notFound}>
            <Typography className={cls.notFoundTxt}>ERROR WITH LOCATION</Typography>
          </div>
        </Container>
      </div>
    );
  }
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    cursor: 'default',
  },
  headTxtR: {
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
    cursor: 'default',
  },
  location: {
    padding: theme.spacing(1),
    borderRadius: '4px',
    backgroundColor: '#00000000',
    '&:hover': {
      backgroundColor: '#00000012',
    },
    cursor: 'pointer',
    transition: '0.2s',
  },
  nameTxt: {
    lineHeight: 1.25,
    fontSize: '18px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  addressTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  timeTxtKey: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  timeTxtVal: {
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  infoTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  sig: {
    display: 'block',
    position: 'relative',
    height: 'fit-content',
  },
  sigImage: {
    display: 'block',
    position: 'relative',
    maxWidth: '200px',
    maxHeight: '200px',
  },
  sigTxt: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1.5),
    color: '#fff',
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  midBreak: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  block: {
    display: 'block',
    height: theme.spacing(1.5),
  },
  inline: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  leftTxt: {
    textAlign: 'left',
  },
  midTxt: {
    textAlign: 'center',
  },
  rightTxt: {
    textAlign: 'right',
  },
  ellipse: {
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  rootTable: {
    verticalAlign: 'top',
    position: 'relative',
    width: '100%',
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));

////////// EXPORT //////////
export default withRouter(MoveDetailsLocation);
