import React from 'react';
import Popover from '@material-ui/core/Popover';
import { Paper, Button } from '@material-ui/core';
import { withRouter } from 'react-router'

// Function takes in the prop 'moves' which is an array of move objects
// Each move object will be rendered as a separate button

function MoveAggregateList(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  // function handleClickAway() {
  //   setAnchorEl(null);
  // }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : null;
  return (
    <>
      <div aria-describedby={id} onClick={handleClick}>
        {props.component}
      </div>
      {props.moves.length > 0 ?
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Paper style={{ textAlign: "center" }}>
            {props.moves.map(move =>
              <React.Fragment key={'fragment'+move.id}>
                <Button
                key={'button'+move.id}
                  onClick={() => props.history.push({
                    pathname: `/moves/${move.id}`,
                    state: { move: move }
                  })}
                  style={{ width: "100%" }}>
                  {move.vehicle_year || ""} {move.vehicle_color || ""} {move.vehicle_make || ""} {move.vehicle_model || "<no vehicle info available>"} {move.vehicle_stock ? `(${move.vehicle_stock})` : ""} {move.lane ? (move.lane.pickup ? `from ${move.lane.pickup.name}` : "No pickup location recorded") : "No pickup location recorded"}
                </Button>
                <br key={'br'+move.id} />
              </React.Fragment>)}
          </Paper>
        </Popover>
        : null}
    </>
  );
}

export default withRouter(MoveAggregateList);