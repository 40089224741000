import React, { useContext, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  DialogActions,
  Button,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import { GlobalContext } from '../../../global-context';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../../utils/Loading';
import { gql } from 'apollo-boost';

const useStyles = makeStyles(theme => ({
  btn: {
    margin: theme.spacing(2),
    textTransform: 'none',
  },
  pwBtn: {
    transform: 'scale(0.75)',
    marginLeft: theme.spacing(-2),
    textTransform: 'none',
  },
}));

export default function CustomerForm(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [loading, setLoading] = useState(false);
  const [inMemoryCustomer, setInMemoryCustomer] = useState({
    name: '',
    address: '',
    phone: '',
    email: '',
    billing_frequency: 'monthly',
    payment_terms: 0,
    auto_pay: false,
    notify_billing: false,
  });

  function handleDisabled() {
    if (
      !inMemoryCustomer.name ||
      (inMemoryCustomer.name && !inMemoryCustomer.name.trim()) ||
      (inMemoryCustomer.name && inMemoryCustomer.name.trim().length < 1) ||
      !inMemoryCustomer.address ||
      (inMemoryCustomer.address && !inMemoryCustomer.address.trim()) ||
      (inMemoryCustomer.address && inMemoryCustomer.address.trim().length < 1) ||
      (inMemoryCustomer.payment_terms && inMemoryCustomer.payment_terms < 0)
    )
      return true;
    else return false;
  }

  function handleCreateCustomer() {
    setLoading(true);
    try {
      ctx.apolloClient
        .mutate({
          mutation: gql`
            mutation insert_customer($customer: customers_insert_input!) {
              insert_customers(objects: [$customer]) {
                affected_rows
              }
            }
          `,
          variables: { customer: inMemoryCustomer },
        })
        .then(res => {
          setLoading(false);
          props.setModalRender(false);
          props.refetch();
        })
        .catch(err => {
          console.error(err);
          setLoading(false);
          ctx.handleNotifications(true, `error`, `Failed to create customer`);
        });
    } catch (err) {
      setLoading(false);
      console.error('Unexpected error while creating customer:', err);
      ctx.handleNotifications(true, `error`, `Failed to create customer`);
    }
  }

  function handleActionButtons() {
    return (
      <>
        <Button disabled={loading || handleDisabled()} className={cls.btn} onClick={() => handleCreateCustomer()}>
          {!loading ? 'Create Customer' : <Loading relative />}
        </Button>
      </>
    );
  }

  function handleClose() {
    if (loading) {
      if (
        window.confirm(
          'There is a pending network call that has not been resolved. Are you sure you want to close this window?'
        )
      ) {
        props.setModalRender(false);
      } else return;
    } else props.setModalRender(false);
  }

  const handleChange = event =>
    event.target.type === 'checkbox'
      ? setInMemoryCustomer(
          Object.assign({ ...inMemoryCustomer }, { [event.target.name]: !inMemoryCustomer[event.target.name] })
        )
      : setInMemoryCustomer(Object.assign({ ...inMemoryCustomer }, { [event.target.name]: event.target.value }));

  return (
    <Dialog
      open={props.modalRender}
      onClose={() => handleClose()}
      aria-labelledby='new-customer-modal'
      id='CustomerDialog'
    >
      <DialogTitle id='new-customer-modal'>{props.existingUser ? 'Edit User' : 'New User'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Create a new user customer account by filling in the required fields (*) below.
        </DialogContentText>
        <br />
        <FormControl>
          <Grid container direction='row' spacing={2} justify='center'>
            <Grid item xs={8}>
              <TextField
                id='customer-name-field'
                label='Name'
                required
                variant='outlined'
                name='name'
                value={inMemoryCustomer.name}
                fullWidth
                onChange={handleChange}
                disabled={loading}
                error={inMemoryCustomer.name && inMemoryCustomer.name.trim().length < 1}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id='customer-address-field'
                label='Address'
                required
                variant='outlined'
                name='address'
                value={inMemoryCustomer.address}
                fullWidth
                onChange={handleChange}
                disabled={loading}
                error={inMemoryCustomer.address && inMemoryCustomer.name.trim().length < 1}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id='customer-phone-field'
                label='Phone (optional)'
                variant='outlined'
                name='phone'
                value={inMemoryCustomer.phone}
                fullWidth
                onChange={handleChange}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id='customer-email-field'
                label='Billing Email (optional)'
                variant='outlined'
                name='email'
                value={inMemoryCustomer.email}
                fullWidth
                onChange={handleChange}
                disabled={loading}
                helperText="Enter a comma-delimited list of emails. The first email is the primary recipient and subsequent emails will be CC'd."
              />
              <br />
            </Grid>
            <Grid item xs={8}>
              <FormControl required variant='outlined' fullWidth>
                <InputLabel id='customer-billing-frequency-label'>Billing Frequency</InputLabel>
                <Select
                  name='role'
                  labelId='customer-billing-frequency-label'
                  id='customer-billing-frequency-select'
                  value={inMemoryCustomer.billing_frequency}
                  onChange={handleChange}
                  label='Billing Frequency'
                  value={inMemoryCustomer.billing_frequency}
                >
                  <MenuItem value={'daily'}>Daily</MenuItem>
                  <MenuItem value={'weekly'}>Weekly</MenuItem>
                  <MenuItem value={'monthly'}>Monthly</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <TextField
                type='number'
                error={inMemoryCustomer.payment_terms && inMemoryCustomer.payment_terms < 0}
                id='customer-payment-terms-field'
                label='Payment Terms'
                variant='outlined'
                name='payment_terms'
                value={inMemoryCustomer.payment_terms}
                fullWidth
                onChange={handleChange}
                disabled={loading}
                required
              />
            </Grid>
            <Grid item xs={8}>
              <FormControlLabel
                labelPlacement='start'
                label='Auto Pay'
                control={
                  <Switch
                    id='customer-auto-pay-switch'
                    disabled={loading}
                    checked={inMemoryCustomer.auto_pay}
                    onChange={handleChange}
                    color='primary'
                    name='auto_pay'
                    inputProps={{ 'aria-label': 'Auto Pay' }}
                  />
                }
              />
            </Grid>
            <Grid item xs={8}>
              <FormControlLabel
                labelPlacement='start'
                label='Notify Billing'
                control={
                  <Switch
                    id='customer-notify-billing-switch'
                    disabled={loading}
                    checked={inMemoryCustomer.notify_billing}
                    onChange={handleChange}
                    color='primary'
                    name='notify_billing'
                    inputProps={{ 'aria-label': 'Notify Billing' }}
                  />
                }
              />
            </Grid>
          </Grid>
        </FormControl>
        <br />
        <br />
        <DialogActions>
          {handleActionButtons()}
          <Button onClick={() => handleClose()} color='primary'>
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
