import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { GlobalContext } from '../../global-context';

import fragments from '../utils/graphQL/fragments';

import { makeStyles, Container, Typography } from '@material-ui/core';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import Loading from '../utils/Loading';
import AccessorialReportFilter from './AccessorialReport/AccessorialReportFilter';
import AccessorialsFooter from './AccessorialReport/AccessorialsFooter';

import { AccordianTable, AccordianRow, TableSort } from '../reusable/AccordianTable';

import { ExportToCsv } from 'export-to-csv';

const log = true;

const cap = str => {
  if (str) {
    if (!str.includes(` `)) return str.charAt(0).toUpperCase() + str.slice(1);
    else {
      let arr = str.split(` `);
      arr = arr.map(s => s.charAt(0).toUpperCase() + s.slice(1));
      return arr.join(` `);
    }
  }
};

const checkNeg = num => {
  if (num > 0) return num;
  else return 0;
};

const round = (num, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  const output = Math.round(num * multiplier) / multiplier;
  return output;
};

const getDefaultRange = () => {
  const localRange = localStorage.getItem(`accessorial-report-range`);
  if (localRange) return localRange;
  else return `week`;
};
const getDefaultStart = () => {
  const localRange = localStorage.getItem(`accessorial-report-range`);
  const localStart = localStorage.getItem(`accessorial-report-start`);
  if (localRange && localRange !== `custom`)
    return moment.utc(moment().startOf(`day`).subtract(1, localRange)).format();
  else if (localRange === `custom` && localStart) return localStart;
  else return moment.utc(moment().startOf(`day`).subtract(1, `week`)).format();
};
const getDefaultEnd = () => {
  const localRange = localStorage.getItem(`accessorial-report-range`);
  const localEnd = localStorage.getItem(`accessorial-report-end`);
  if (localRange && localRange !== `custom`) return moment.utc(moment().startOf(`day`)).format();
  else if (localRange === `custom` && localEnd) return localEnd;
  else return moment.utc(moment().startOf(`day`)).format();
};
const getDefaultDisable = () => {
  const localRange = localStorage.getItem(`accessorial-report-range`);
  if (localRange && localRange === `custom`) return false;
  else return true;
};

const defaultOrder = `desc`;
const defaultOrderBy = `ID`;

////////// COMPONENT //////////
function AccessorialReport(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [status, setStatus] = useState(null);
  const [code, setCode] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(0);
  const localCustomerId = parseInt(localStorage.getItem(`customerId`));
  const [customerId, setCustomerId] = useState(localCustomerId || null);
  const [range, setRange] = useState(getDefaultRange());
  const [start, setStart] = useState(getDefaultStart());
  const [end, setEnd] = useState(getDefaultEnd());
  const [disablePickers, setDisablePickers] = useState(getDefaultDisable());

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  // const [expandedRowId, setExpandedRowId] = useState(0);

  useEffect(() => {
    localStorage.setItem(`customerId`, customerId);
  }, [customerId]);

  // Control range picker
  const handleRangeChange = value => {
    log && console.log(`Range Change:`, value);
    localStorage.setItem(`accessorial-report-range`, value);
    if (value !== `custom`) {
      setDisablePickers(true);
      setRange(value);
      setStart(moment.utc(moment().startOf(`day`).subtract(1, value)).format());
      setEnd(moment.utc(moment().startOf(`day`)).format());
    } else {
      setDisablePickers(false);
      setRange(value);
      setStart(getDefaultStart());
      setEnd(getDefaultEnd());
    }
  };

  // Control date pickers
  const handleDateChange = (value, name) => {
    log && console.log(`Date Change:`, { value, name });
    if (name === `start`) {
      const newDate = moment.utc(moment(value).startOf(`day`)).format();
      localStorage.setItem(`accessorial-report-start`, newDate);
      setStart(newDate);
    } else {
      const newDate = moment.utc(moment(value).startOf(`day`)).format();
      localStorage.setItem(`accessorial-report-end`, newDate);
      setEnd(newDate);
    }
  };

  const applyFilters = data => {
    if (!search || search.length < 1) return data;
    else {
      return data.filter(o => {
        if (
          (o.move_id && (o.move_id + ``).toLocaleLowerCase().includes(search)) ||
          (o.code && (o.code + ``).toLocaleLowerCase().includes(search)) ||
          (o.move && o.move.driver_name && o.move.driver_name.toLocaleLowerCase().includes(search)) ||
          (o.move &&
            o.move.customer &&
            o.move.customer.name &&
            o.move.customer.name.toLocaleLowerCase().includes(search))
          // (o.gateway_transaction_id && o.gateway_transaction_id.toLocaleLowerCase().includes(search))
        ) {
          return true;
        } else return false;
      });
    }
  };

  // const applyFilters = data => {
  //   const filterByRegion = selectedRegion ? data.accessorials.filter(o=> o.move.lane.pickup.region_id === selectedRegion ) : data.accessorials
  //   const filterByCustomer = customerId ? filterByRegion.filter(o=> o.move.customer_id === customerId ) : filterByRegion
  //   const filterByCode = code ? filterByCustomer.filter(o=>o.code === code) : filterByCustomer
  //   return filterByCode
  // }

  const generateCSV = accessorials => {
    const createCsvRow = accessorial => {
      return {
        MOVE_ID: accessorial.move_id,
        DATE: moment(accessorial.createdat).format(`MM/DD/YYYY`),
        CODE: accessorial.code ? cap(accessorial.code) : `-`,
        CUSTOMER_NAME: accessorial.move && accessorial.move.customer ? accessorial.move.customer.name : `-`,
        DRIVER_NAME: accessorial.move ? accessorial.move.driver_name : `-`,
        STATUS: accessorial.status || `-`,
        CUSTOMER_CHARGE: `$${checkNeg(accessorial.ar_amount).toFixed(2)}` || `-`,
        DRIVER_PAY: `$${checkNeg(accessorial.ap_amount).toFixed(2)}` || `-`,
        TOTAL: accessorial.cost ? `$${checkNeg(accessorial.cost).toFixed(2)}` : accessorial.cost === 0 ? `$0.00` : `-`,
      };
    };
    const csvRows = accessorials.map(accessorial => createCsvRow(accessorial));
    const csvOptions = {
      filename: `${
        customerId ? accessorials[0].move.customer.name.replace(/ /g, '_') : `All`
      }_Accessorials_from_${start}_to_${end}`,
      showTitle: true,
      title: `${customerId ? accessorials[0].move.customer.name : `All`} Accessorials from ${start} to ${end}`,
      useKeysAsHeaders: true,
    };

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  };

  const goToInvoice = invoiceId => {
    localStorage.setItem('invoiceId', invoiceId);
    props.history.push(`/invoices`);
  };

  const getTableActions = accessorials => {
    return [{ label: `Generate\xa0CSV`, handler: () => generateCSV(accessorials) }];
  };
  const getRowActions = accessorial => {
    return [{ label: `View\xa0Invoice`, handler: () => goToInvoice(accessorial.arinvoice.id) }];
  };
  return (
    <>
      <div className={cls.root}>
        <Container maxWidth='lg'>
          {ctx && ctx.userIsAuthenticated() && (
            <Query
              key={props.location.key}
              query={GET_ACCESSORIALS_AND_REGIONS}
              //SET CODE VAR
              variables={{
                code: code,
                customer_id: customerId,
                start: start,
                end: end,
                region_id: selectedRegion || null,
                selected_regions: Object.keys(
                  ctx.userProfile['https://hasura.io/jwt/claims']['x-hasura-selected-regions']
                ),
              }}
              //Was causing infinited reload on error
              // onError={(err) => ctx.handleNotifications(true, `error`, `Failed to retrieve accessorials: ${err.toString()}`)}
            >
              {({ loading, error, data, refetch }) => {
                if (loading) return <Loading fixed />;
                if (error) {
                  console.log(`Failed to retrieve accessorials:`, error);
                  return (
                    <div className={cls.notFound}>
                      <Typography className={cls.notFoundTxt}>ERROR FINDING ACCESSORIALS</Typography>
                    </div>
                  );
                }
                if (data && data.accessorials && data.accessorials.length > 0) {
                  const filteredAccessorials = applyFilters(data.accessorials);
                  var amount = {};
                  const subtotalAccessorials = filteredAccessorials.filter(item => item.cost > 0);
                  amount.subtotal = round(
                    subtotalAccessorials.length > 0
                      ? subtotalAccessorials.map(item => item.cost).reduce((total, current) => total + current)
                      : 0,
                    2
                  );
                  amount.total = checkNeg(amount.subtotal);
                  const headers = [
                    { id: `MOVE_ID`, alignLeft: true, numeric: true, label: `Move ID` },
                    { id: `DATE`, alignLeft: true, numeric: false, label: `Date` },
                    { id: `CODE`, alignLeft: true, numeric: false, label: `Code` },
                    { id: `CUSTOMER_NAME`, alignLeft: true, numeric: false, label: `Customer` },
                    { id: `DRIVER_NAME`, alignLeft: true, numeric: false, label: `Driver` },
                    { id: `STATUS`, alignLeft: true, numeric: false, label: `Status` },
                    { id: `CUSTOMER_CHARGE`, alignLeft: false, numeric: true, label: `Customer Charge` },
                    { id: `DRIVER_PAY`, alignLeft: false, numeric: true, label: `Driver Pay` },
                    { id: `TOTAL`, alignLeft: false, numeric: true, label: `Total Cost` },
                  ];
                  const rows = filteredAccessorials.map(accessorial => {
                    return {
                      MOVE_ID: accessorial.move_id,
                      DATE: moment(accessorial.createdat).format(`MM/DD/YYYY`),
                      CODE: accessorial.code ? cap(accessorial.code) : `-`,
                      CUSTOMER_NAME:
                        accessorial.move && accessorial.move.customer ? accessorial.move.customer.name : `-`,
                      DRIVER_NAME: accessorial.move ? accessorial.move.driver_name : `-`,
                      STATUS: accessorial.status || `-`,
                      CUSTOMER_CHARGE: `$${checkNeg(accessorial.ar_amount).toFixed(2)}` || `-`,
                      DRIVER_PAY: `$${checkNeg(accessorial.ap_amount).toFixed(2)}` || `-`,
                      TOTAL: accessorial.cost
                        ? `$${checkNeg(accessorial.cost).toFixed(2)}`
                        : accessorial.cost === 0
                        ? `$0.00`
                        : `-`,
                      accessorial: accessorial,
                    };
                  });
                  return (
                    <>
                      <Typography className={cls.headTxt}>Accessorial Index</Typography>

                      <AccessorialReportFilter
                        code={code}
                        customerId={customerId}
                        range={range}
                        start={start}
                        end={end}
                        regions={data.regions || []}
                        selectedRegion={selectedRegion}
                        onCodeChange={setCode}
                        onStatusChange={setStatus}
                        onCustomerChange={setCustomerId}
                        onRangeChange={handleRangeChange}
                        onDateChange={handleDateChange}
                        onRegionChange={setSelectedRegion}
                        disablePickers={disablePickers}
                      />
                      <div className={cls.break} />

                      <AccordianTable
                        title={`${rows.length} Accessorials`}
                        headers={headers}
                        rows={rows}
                        actions={getTableActions(data.accessorials)}
                        search={search}
                        defaultOrder={defaultOrder}
                        defaultOrderBy={defaultOrderBy}
                        order={order}
                        orderBy={orderBy}
                        tablePage={tablePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        setSearch={setSearch}
                        setOrder={setOrder}
                        setOrderBy={setOrderBy}
                        setTablePage={setTablePage}
                        setRowsPerPage={setRowsPerPage}
                        setExpandedRowId={() => console.log('non-expandable')}
                        className={cls.table}
                        refetch={refetch}
                        refreshPersistAs='accessorial_report'
                      >
                        {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                          .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                          .map(row => (
                            <AccordianRow
                              key={`arreport-payment-${row.accessorial.id}`}
                              rowId={row.accessorial.id}
                              // expandedRowId={expandedRowId}
                              setExpandedRowId={() => console.log('non-expandable')}
                              columns={[
                                { align: 'left', value: row.MOVE_ID },
                                { align: 'left', value: row.DATE },
                                { align: 'left', value: row.CODE },
                                { align: 'left', value: row.CUSTOMER_NAME },
                                { align: 'left', value: row.DRIVER_NAME },
                                { align: 'left', value: row.STATUS },
                                { align: 'right', value: row.CUSTOMER_CHARGE },
                                { align: 'right', value: row.DRIVER_PAY },
                                { align: 'right', value: row.TOTAL },
                              ]}
                              actions={getRowActions(row.accessorial)}
                              // onClick={() => goToInvoice(row.INVOICE_ID)}
                              className={cls.row}
                            >
                              <div />
                            </AccordianRow>
                          ))}
                      </AccordianTable>

                      <div className={cls.break} />

                      <AccessorialsFooter amount={amount} />
                    </>
                  );
                } else
                  return (
                    <>
                      <Typography className={cls.headTxt}>Accessorial Index</Typography>
                      <AccessorialReportFilter
                        code={code}
                        customerId={customerId}
                        range={range}
                        start={start}
                        end={end}
                        regions={data.regions || []}
                        selectedRegion={selectedRegion}
                        onCodeChange={setCode}
                        onStatusChange={setStatus}
                        onCustomerChange={setCustomerId}
                        onRangeChange={handleRangeChange}
                        onDateChange={handleDateChange}
                        onRegionChange={setSelectedRegion}
                        disablePickers={disablePickers}
                      />
                      <div className={cls.break} />
                      <div className={cls.notFound}>
                        <Typography className={cls.notFoundTxt}>NO ACCESSORIALS FOUND</Typography>
                      </div>
                    </>
                  );
              }}
            </Query>
          )}
        </Container>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      background: theme.palette.action.hover,
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  headTxt: {
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));

////////// GRAPHQL //////////
const GET_ACCESSORIALS_AND_REGIONS = gql`
  query get_accessorials(
    $code: String
    $customer_id: bigint
    $start: timestamptz!
    $end: timestamptz!
    $region_id: bigint
    $selected_regions: [bigint!]
  ) {
    accessorials(
      where: {
        code: { _eq: $code }
        move: { customer_id: { _eq: $customer_id }, lane: { pickup: { region: { id: { _eq: $region_id } } } } }
        createdat: { _gte: $start, _lte: $end }
      }
      order_by: { id: desc }
    ) {
      id
      createdat
      status
      code
      cost
      ar_amount
      ap_amount
      move_id
      notes
      move {
        id
        driver_name
        customer_id
        customer {
          id
          name
        }
        lane {
          id
          pickup {
            id
            region_id
            region {
              id
            }
          }
        }
      }
    }
    regions(where: { id: { _in: $selected_regions } }) {
      ...Region
    }
  }
  ${fragments.region}
`;

////////// EXPORT //////////
export default withRouter(AccessorialReport);
