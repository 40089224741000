import React from "react";
import moment from "moment";
import { withStyles } from '@material-ui/core/styles';
import { Grid, Divider, Typography, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(5),
  },
  grid: {
    width: '100%',
    padding: theme.spacing(2),
  },
  invoiceHeader: {
    textAlign: 'right',
    fontWeight: "bold",
    fontSize: "200%"
  },
  reducedFont: {
    fontSize: "115%",
    '@media print': {
      fontSize: "95%"
    },
  },
  detailItem: {
    fontSize: "105%",
    '@media print': {
      fontSize: "70%"
    },
    fontWeight: "bold",
    textAlign: 'left',
  },
  detailValue: {
    fontSize: "105%",
    '@media print': {
      fontSize: "70%"
    },
    textAlign: 'left',
    float: 'right',
  },
  horLine: {
    margin: theme.spacing(0.3),
    backgroundColor: "black"
  },
  invoiceTotal: {
    marginLeft: theme.spacing(5),
    fontSize: "225%",
    textAlign: 'left',
    fontWeight: "bold",
  },
  invoiceSum: {
    marginRight: theme.spacing(5),
    fontSize: "225%",
    textAlign: 'left',
    float: 'right',
    fontWeight: "bold",
  },
  tableHeader: {
    fontSize: "100%",
    '@media print': {
      fontSize: "60%"
    },
    fontWeight: "bold",
    color: "black"
  },
  tableCell: {
    fontSize: "100%",
    '@media print': {
      fontSize: "60%"
    },
  },
})

class InvoiceLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container direction="row" justify="space-between" spacing={4}>
          <Grid item xs={6}>
            <img
              src="https://socialautotransport.com/logo-264px.png"
              alt="logo"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.invoiceHeader}>INVOICE</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.reducedFont} style={{ fontWeight: "bold" }} variant="h6">FROM</Typography>
            <Typography className={classes.reducedFont}>1717 East Cary St</Typography>
            <Typography className={classes.reducedFont}>Suite 2118</Typography>
            <Typography className={classes.reducedFont}>Richmond, VA 23223</Typography>
            <Typography className={classes.reducedFont}>804-239-4505</Typography>
            <Typography className={classes.reducedFont}><a href="https://www.hopdrive.com">hopdrive.com</a></Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.reducedFont} style={{ fontWeight: "bold" }} variant="h6">BILL TO</Typography>
            <Typography className={classes.reducedFont}>{this.props.customerName}</Typography>
            <Typography className={classes.reducedFont}>{this.props.customerAddress}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.reducedFont} style={{ fontWeight: "bold" }} variant="h6">STATS</Typography>
            <Typography display="inline" className={classes.detailItem}>Total Moves</Typography>
            <Typography display="inline" className={classes.detailValue}> {this.props.moves.length} </Typography>
            <br />
            <Typography display="inline" className={classes.detailItem}>Total Miles</Typography>
            <Typography display="inline" className={classes.detailValue}> {this.props.distanceSum.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </Typography>
            {(this.props.customerId === 2) &&
              <React.Fragment>
                <br />
                <Typography display="inline" className={classes.detailItem}>Account Suffix</Typography>
                <Typography display="inline" className={classes.detailValue}> {this.props.accountSuffix} </Typography>
              </React.Fragment>
            }
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.reducedFont} style={{ fontWeight: "bold" }} variant="h6">REFERENCE</Typography>
            <Typography display="inline" className={classes.detailItem}>Invoice</Typography>
            <Typography display="inline" className={classes.detailValue}> {(!this.props.singlePageInvoice) ? this.props.invoiceNumber : `${this.props.invoiceNumber}${(this.props.moves) ? '-' + this.props.moves[0].id : ''}`} </Typography>
            <br />
            <Typography display="inline" className={classes.detailItem}>Invoice Date</Typography>
            <Typography display="inline" className={classes.detailValue}> {moment().format("MM/DD/YYYY")} </Typography>
            <br />
            <Typography display="inline" className={classes.detailItem}>PO#</Typography>
            <Typography display="inline" className={classes.detailValue}> - </Typography>
            <br />
            <Typography display="inline" className={classes.detailItem}>Due Date</Typography>
            <Typography display="inline" className={classes.detailValue}>{moment().add(1, "M").format("MM/DD/YYYY")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.horLine} />
            <Typography display="inline" className={classes.invoiceTotal}>INVOICE TOTAL</Typography>
            <Typography display="inline" className={classes.invoiceSum}> ${this.props.priceSum.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </Typography>
            <Divider className={classes.horLine} />
          </Grid>
        </Grid>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>SAT ID</TableCell>
              <TableCell className={classes.tableHeader} align="left">Ship Date</TableCell>
              <TableCell className={classes.tableHeader} align="left">Driver</TableCell>
              <TableCell className={classes.tableHeader} align="left">Stock</TableCell>
              <TableCell className={classes.tableHeader} align="left">VIN</TableCell>
              <TableCell className={classes.tableHeader} align="left">Pickup</TableCell>
              <TableCell className={classes.tableHeader} align="left">Delivery</TableCell>
              <TableCell className={classes.tableHeader} align="left">Type</TableCell>
              <TableCell className={classes.tableHeader} align="left">Distance</TableCell>
              <TableCell className={classes.tableHeader} align="left">Class</TableCell>
              <TableCell className={classes.tableHeader} align="left">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.moves.map(move => {
              return (
                <TableRow key={"sat-move-" + move.id}>
                  <TableCell className={classes.tableCell} component="th" scope="row">{move.id}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{moment(move.pickup_started ? move.pickup_started : move.pickup_time).format(`MM/DD/YYYY`)}</TableCell>
                  <TableCell className={classes.tableCell} align="left">Social Auto Driver</TableCell>
                  <TableCell className={classes.tableCell} align="left">{move.vehicle_stock === null ? "" : move.vehicle_stock.toUpperCase()}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{move.vehicle_vin === null ? "" : (move.vehicle_vin.substr(move.vehicle_vin.length - 8).toUpperCase())}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{(this.props.customerId === 2 ? "Carmax - " : '') + move.lane.pickup.name}<br />{move.lane.pickup.address}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{(this.props.customerId === 2 ? "Carmax - " : '') + move.lane.delivery.name}<br />{move.lane.delivery.address}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{move.calculatedRateType}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{Math.round(move.lane.distance_miles, 2) + " mi"}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{move.class}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{move.calculatedPrice.toFixed(2)}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div >
    );
  }
}

export default withStyles(styles)(InvoiceLayout);