import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { palette, spacing, typography } from '@material-ui/system';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import 'typeface-roboto';

import { withStyles } from '@material-ui/core/styles';
import {
  CssBaseline,
  AppBar,
  SwipeableDrawer,
  Toolbar,
  Typography,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Tooltip,
  Icon,
} from '@material-ui/core';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import { GlobalContext } from '../global-context';
import Routes from '../Routes';
import Searchbar from './Searchbar';
import PendingCancelIcon from './PendingCancelIcon';
import PendingDisputedIcon from './PendingDisputedIcon';
import PendingZeroIcon from './PendingZeroIcon';
import './../index.css';
import NotificationPopup from './reusable/NotificationPopup';
import RegionSelectModal from './reusable/RegionSelectModal';
const logo = require('../static/logo-banner-light.svg');

let log = false;

const GET_PENDING_CANCELLED_MOVES = (type = 'query') => gql`
${type} get_pending_cancelled_moves{
    moves(
      where: {
        _and: 
          {
          cancel_status: {_like: "pending"}, 
          active: {_eq: "1"}
        }
      }
    ) {
      id
      active
      cancel_status
      move_type
      createdat
      updatedat
    }
  }
`;

const GET_DISPUTED_ARMOVES = (type = 'query') => gql`
${type} get_disputed_armoves {
  armoves(where: {disputed: {_eq: true}, move: {disputed: {_eq: true}}, active: {_eq: 1}}) {
    id
    move_id
    disputed
  }
}
`;

const GET_ZERO_DOLLAR_ARMOVES = (type = 'query') => gql`
${type} get_disputed_armoves {
  armoves(where: {
    due_amount: {_lte: 0},
    details: {name: {_eq: "INVALID Transport Cost"}},
    invoice: {customer_id: {_neq: 9}},
    move: {cancel_status: {_is_null: true}, active: {_eq: 1}},
    active: {_eq: 1}
  }) {
    id
    move_id
    disputed
  }
}
`;

const Box = styled.div`
  ${palette}${spacing}${typography}
`;
const drawerWidth = 320;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  avatar: {
    // margin: 10,
    display: 'inline-flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  branch: {
    color: 'grey',
    position: 'absolute',
    fontSize: '0.7em',
    margin: '4px 0 0 4px',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    maxHeight: '64px',
    backgroundColor: theme.palette.text.primary,
    // boxShadow: "0px 3px 8px -1px #20202850",
    boxShadow: theme.shadow.harsh,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    // overflow: 'hidden', // this was causing the drawer to not scroll
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  content: {
    position: 'relative',
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
});

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showNotification: false,
      notificationVariant: '',
      notificationMessage: '',
      showRegionModal: false,
      version: '',
      branch: '',
    };
  }

  handleVersionTag = async () => {
    let versionResult = await fetch('/VERSION');
    let versionTxt = await versionResult.text();
    let branchResult = await fetch('/BRANCH');
    let branchTxt = await branchResult.text();
    // Clean up tag to only show version
    versionTxt = versionTxt.replace(/^(.*?)(?:\-.*)?$/, '$1');
    await this.setState({ version: versionTxt, branch: branchTxt });
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    if (log) {
      console.log('close drawer');
    }
    this.setState({ open: false });
  };

  componentWillMount = async () => {
    // fetch version tag from Git and set to state on willMount
    await this.handleVersionTag();
    // Authenticate user and initialize Apollo Client
    if (this.context.auth0.isAuthenticated) {
      try {
        let { __raw: token } = await this.context.auth0.getIdTokenClaims();
        this.context.setUserAuth(this.context.auth0.user, token);
        this.context.setupApollo(token);
      } catch (err) {
        // In the event of a failed authentication, route back to the public page
        // This will allow the user to attempt the login again without an infinite render loop
        // this.props.history.push("/");
      }
    }
  };

  toggleRegionModal = () => this.setState({ showRegionModal: !this.state.showRegionModal });

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <NotificationPopup
          show={this.context.notificationShow}
          variant={this.context.notificationVariant ? this.context.notificationVariant : 'info'}
          message={this.context.notificationMessage}
          handleClose={this.context.handleNotifications}
        />

        <CssBaseline />
        <AppBar position='absolute' className={classNames(classes.appBar, this.state.open && classes.appBarShift)}>
          <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
            <IconButton
              style={{ marginRight: '10px' }}
              color='inherit'
              aria-label='Open drawer'
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, this.state.open && classes.menuButtonHidden)}
            >
              <Icon>menu</Icon>
            </IconButton>
            <Typography component='h1' variant='h6' color='inherit' noWrap className={classes.title}>
              <Link to='/'>
                <Box ml='0px'>
                  <img
                    style={{ marginTop: '14px' }}
                    alt='logo-hopdrive'
                    width='180px'
                    hidden={this.state.open}
                    src={logo}
                  />
                </Box>
              </Link>
            </Typography>

            {this.context.userIsAuthenticated() && (
              <>
                {this.state.showRegionModal && (
                  <RegionSelectModal open={this.state.showRegionModal} toggle={this.toggleRegionModal} />
                )}

                <Searchbar />
                <div className={classes.sectionDesktop}>
                  <IconButton aria-label='open region selection' color='inherit' onClick={this.toggleRegionModal}>
                    <Tooltip title={'Change selected regions'}>
                      <Icon>public</Icon>
                    </Tooltip>
                  </IconButton>

                  <IconButton aria-label='go to plan view' color='inherit'>
                    <Icon>directions_car</Icon>
                    {/*
                            <Badge badgeContent={99} color="secondary">
                              <DirectionsCar />
                            </Badge>
                            */}
                  </IconButton>
                  {/* Displays amount of pending cancelled moves */}
                  <Query
                    query={GET_PENDING_CANCELLED_MOVES('query')}
                    //Was causing infinited reload on error
                    // onError={(err) => {
                    //   console.error(err);
                    //   this.context.handleNotifications(true, "error", "Query failed to retrieve pending cancellations");
                    // }}
                  >
                    {({ subscribeToMore, ...result }) => {
                      if (result.loading) return 'Loading...';
                      if (result.error) return `Error! ${result.error.message}`;
                      if (result.data)
                        return (
                          <PendingCancelIcon
                            subscribeToMore={() => {
                              subscribeToMore({
                                document: GET_PENDING_CANCELLED_MOVES('subscription'),
                                updateQuery: (prev, { subscriptionData }) => {
                                  if (log) console.log('subscriptionData:', subscriptionData, 'prev:', prev);
                                  if (!subscriptionData.data) return prev;
                                  return Object.assign({}, prev, subscriptionData.data);
                                },
                              });
                            }}
                            moves={result.data.moves}
                          />
                        );
                    }}
                  </Query>
                  <IconButton
                    edge='end'
                    aria-label='account of current user'
                    aria-controls={'primary-search-account-menu'}
                    aria-haspopup='true'
                    color='inherit'
                    onClick={e => {
                      this.setState({ anchorEl: e.currentTarget });
                    }}
                  >
                    <Icon>account_circle</Icon>
                  </IconButton>
                </div>
                <Menu
                  getContentAnchorEl={null}
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  id={'primary-search-account-menu'}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(this.state.anchorEl)}
                  onClose={() => {
                    this.setState({ anchorEl: null });
                  }}
                >
                  <Link to='/profile'>
                    <MenuItem>Profile</MenuItem>
                  </Link>
                  <MenuItem
                    onClick={() => {
                      this.context.logout();
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </>
            )}
          </Toolbar>
        </AppBar>
        <div onClick={this.handleDrawerClose}>
          <SwipeableDrawer
            // variant="permanent"
            classes={{
              paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
            }}
            open={this.state.open}
            onOpen={this.handleDrawerOpen}
            onClose={this.handleDrawerClose}
          >
            <div className={classes.branch}>
              {this.state.branch !== 'master' || this.state.branch !== 'HEAD' ? this.state.branch : ''}
            </div>
            <div className={classes.toolbarIcon}>
              <Typography style={{ marginLeft: 5 }} color='textSecondary'>
                {this.state.version}
              </Typography>
              <IconButton onClick={this.handleDrawerClose}>
                <Icon>chevron_left</Icon>
              </IconButton>
            </div>
            <Divider />
            <List>
              {!this.context.userIsAuthenticated() && (
                <ListItem button component={Link} to='/moves' onClick={this.handleDrawerClose}>
                  <ListItemIcon>
                    <Icon>person</Icon>
                  </ListItemIcon>
                  <ListItemText primary='Login' />
                </ListItem>
              )}
              {this.context.userIsAuthenticated() && (
                <>
                  <ListItem button component={Link} to='/dashboard' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>home</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Home' />
                  </ListItem>

                  <ListItem button component={Link} to='/plans' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>subtitles</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Plans' />
                  </ListItem>
                </>
              )}
            </List>
            <Divider />
            {this.context.userIsAuthenticated() && (
              <>
                <List>
                  <ListItem button component={Link} to='/moves' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>directions_car</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Moves' />
                  </ListItem>

                  <ListItem button component={Link} to='/moves/add' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>add_box</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Add New' />
                  </ListItem>

                  <ListItem button component={Link} to='/moves/add-multiple' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>library_add</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Add Many' />
                  </ListItem>
                </List>
                <Divider />
                <List>
                  <ListSubheader>Index</ListSubheader>

                  <ListItem button component={Link} to='/map' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>explore</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Map' />
                  </ListItem>

                  <ListItem button component={Link} to='/lanes' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>linear_scale</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Lanes' />
                  </ListItem>

                  <ListItem button component={Link} to='/locations' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>store_mall_directory</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Locations' />
                  </ListItem>

                  <ListItem button component={Link} to='/regions' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>public</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Regions' />
                  </ListItem>

                  <ListItem button component={Link} to='/customers' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>contacts</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Customers' />
                  </ListItem>
                </List>
                <Divider />
                <List>
                  <ListSubheader>Customer</ListSubheader>

                  <ListItem button component={Link} to='/invoices' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>receipt</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Invoices' />
                  </ListItem>

                  <ListItem button component={Link} to='/disputes' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Query
                        query={GET_DISPUTED_ARMOVES('query')}
                        //Was causing infinited reload on error
                        // onError={(err) => {
                        //   console.error(err);
                        //   this.context.handleNotifications(true, "error", "Query failed to retrieve disputed moves");
                        // }}
                      >
                        {({ subscribeToMore, ...result }) => {
                          if (result.loading) return 'Loading...';
                          if (result.error) return `Error! ${result.error.message}`;
                          if (result.data)
                            return (
                              <PendingDisputedIcon
                                subscribeToMore={() => {
                                  subscribeToMore({
                                    document: GET_DISPUTED_ARMOVES('subscription'),
                                    updateQuery: (prev, { subscriptionData }) => {
                                      if (!subscriptionData.data) return prev;
                                      return { armoves: subscriptionData.data.armoves };
                                    },
                                  });
                                }}
                                armoves={result.data.armoves}
                              />
                            );
                        }}
                      </Query>
                    </ListItemIcon>
                    <ListItemText primary='Disputes' />
                  </ListItem>

                  <ListItem button component={Link} to='/zero-dollars' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Query
                        query={GET_ZERO_DOLLAR_ARMOVES('query')}
                        //Was causing infinited reload on error
                        // onError={(err) => {
                        //   console.error(err);
                        //   this.context.handleNotifications(true, "error", "Query failed to retrieve zero dollar moves");
                        // }}
                      >
                        {({ subscribeToMore, ...result }) => {
                          if (result.loading) return 'Loading...';
                          if (result.error) return `Error! ${result.error.message}`;
                          if (result.data)
                            return (
                              <PendingZeroIcon
                                subscribeToMore={() => {
                                  subscribeToMore({
                                    document: GET_ZERO_DOLLAR_ARMOVES('subscription'),
                                    updateQuery: (prev, { subscriptionData }) => {
                                      if (!subscriptionData.data) return prev;
                                      return { armoves: subscriptionData.data.armoves };
                                    },
                                  });
                                }}
                                armoves={result.data.armoves}
                              />
                            );
                        }}
                      </Query>
                    </ListItemIcon>
                    <ListItemText primary='Zero Dollar Moves' />
                  </ListItem>

                  <ListItem button component={Link} to='/ar-report/moves' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>assignment</Icon>
                    </ListItemIcon>
                    <ListItemText primary='AR Report (Moves)' />
                  </ListItem>

                  <ListItem button component={Link} to='/ar-report/payments' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>assignment</Icon>
                    </ListItemIcon>
                    <ListItemText primary='AR Report (Payments)' />
                  </ListItem>

                  <ListItem button component={Link} to='/accessorial-report' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>assignment</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Accessorial Report' />
                  </ListItem>

                  <ListItem button component={Link} to='/promos' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>view_list</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Promos' />
                  </ListItem>
                </List>
                <Divider />
                <List>
                  <ListSubheader>Driver</ListSubheader>

                  <ListItem button component={Link} to='/driver-pay' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>monetization_on</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Driver Pay' />
                  </ListItem>

                  <ListItem button component={Link} to='/driverpay2' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>monetization_on</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Driver Pay 2.0 (beta)' />
                  </ListItem>

                  <ListItem button component={Link} to='/ap-report' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>assignment</Icon>
                    </ListItemIcon>
                    <ListItemText primary='AP Report' />
                  </ListItem>

                  <ListItem button component={Link} to='/driver-schedule' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>date_range</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Driver Schedule' />
                  </ListItem>
                </List>
                <Divider />
                <List>
                  <ListSubheader>Utility</ListSubheader>

                  <ListItem button component={Link} to='/moves/calculator' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>monetization_on</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Move Calculator' />
                  </ListItem>

                  <ListItem button component={Link} to='/driver-emails' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>email</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Driver Emails' />
                  </ListItem>

                  <ListItem button component={Link} to='/rules' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>view_list</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Rules' />
                  </ListItem>

                  <ListItem button component={Link} to='/faq-manager' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>help</Icon>
                    </ListItemIcon>
                    <ListItemText primary='FAQ Manager' />
                  </ListItem>
                </List>
                <Divider />
                <List>
                  <ListSubheader>Legacy</ListSubheader>

                  <ListItem button component={Link} to='/invoices-legacy' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>history</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Invoices' />
                  </ListItem>

                  <ListItem button component={Link} to='/driver-pay-legacy' onClick={this.handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>history</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Driver Pay' />
                  </ListItem>
                </List>
              </>
            )}
          </SwipeableDrawer>
        </div>
        <main className={classes.content} ref={ref => (this.context.mainRef = ref)}>
          <div style={{ marginTop: '100px' }} id='login' hidden={this.context.userIsAuthenticated()}></div>
          {!window.location.pathname.includes(`/map`) ? <div style={{ width: '100%', height: '64px' }} /> : null}
          <Routes />
        </main>
      </div>
    );
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
};

Layout.contextType = GlobalContext;

export default withStyles(styles)(withRouter(Layout));
