import React, { Component } from "react";
import MoveDraggable from "./MoveDraggable";
import { Droppable } from 'react-beautiful-dnd';
import { GlobalContext } from "../../../global-context";
import Box from '@material-ui/core/Box';

// const styles = theme => ({
//     root: {
//         display: 'flex',
//         flexWrap: 'wrap',
//         justifyContent: 'space-around',
//         overflow: 'hidden',
//         backgroundColor: theme.palette.background.paper,
//     }
// })

// let log = false  //Enable for verbose console logging

const getPlanStyle = isDraggingOver => ({
    background: isDraggingOver ? 'rgb(230, 242, 255)' : '',
    border: isDraggingOver ? '1px solid rgb(210, 222, 255)' : ''
});

class Plan extends Component {
    // constructor(props) {
    //     super(props);
    // }

    // If a user bookmarks this page and returns with a fresh load of the app starting here, make sure to support that
    componentWillMount = () => {
        //this.props.plan.moves.sort((a, b) => a.sequence - b.sequence)
    }

    render() {
        const { plan, planHeight, timelineScale, index, handleSelectMove, selectedMove, allPlannedMoves } = this.props;

        return (
            <React.Fragment>
                <Droppable
                    droppableId={'plan-' + plan.id}
                    direction="horizontal">
                    {(provided, snapshot) => (
                        <Box key={plan.id} display="flex" flexDirection="row"
                            className="plan"
                            bgcolor={index % 2 == 0 ? "background.paper" : "hsl(180, 20%, 97%)"}
                            id={"plan-" + plan.id}
                            ref={provided.innerRef}
                            p={0} pl={1} m={0}
                            border={1}
                            borderTop={0}
                            borderLeft={0}
                            borderRight={0}
                            // borderBottom={1} // Now need to set border={1} then zero out the sides you don't want
                            borderColor="grey.300"
                            height={`${planHeight}px`}
                            width="100%"
                            alignItems="center"
                            style={getPlanStyle(snapshot.isDraggingOver)}>
                            {
                                plan.moves.map((move, index) => {
                                    //if (log) console.log(`   Rendering move #${move.id} which is ${index + 1} of ${plan.moves.length} moves from plan #${plan.id}`);
                                    if (move.active || move.suggested) {
                                        return (
                                            <MoveDraggable timelineScale={timelineScale} planHeight={planHeight} move={move} plan={plan} index={index} key={move.id} handleSelectMove={handleSelectMove} selectedMove={selectedMove}
                                                allPlannedMoves={allPlannedMoves} useDebugMoveTooltip={this.props.useDebugMoveTooltip} />
                                        )
                                    }
                                })
                            }
                            {provided.placeholder}
                        </Box>
                    )}
                </Droppable>
            </React.Fragment>
        )
    }
}

Plan.contextType = GlobalContext;

export default Plan;