import React, { Component } from "react";
import { GlobalContext } from "../../global-context";
import MoveForm from '../pages/moveEntry/MoveForm';
import fragments from "../utils/graphQL/fragments";
import gql from "graphql-tag";
import Loading from "../utils/Loading";
import { Query } from "react-apollo";
import Container from '@material-ui/core/Container';

let log = false;

const GET_LANES_AND_LOCATIONS_FOR_CUSTOMER = gql`
query lanes_locations_and_favorites_by_customer (
    $customer_id: bigint!
) {
  favoritelocations (where: {customer_id: {_eq: $customer_id}}) {
    ...FavoriteLocation
  }
  favoritelanes (where: {customer_id: {_eq: $customer_id}}) {
    ...FavoriteLane
  }
  locations(
    where: {customer_id: {_eq: $customer_id}, active: {_eq: 1}},
    order_by: {name: asc},
  ) {
   ...Location
  }
  lanes(
    where: {customer_id: {_eq: $customer_id}, active: {_eq: 1}},
    order_by: {description: asc},
  ) {
    ...Lane
  }
}
${fragments.favoriteLocation}
${fragments.favoriteLane}
${fragments.location}
${fragments.lane}
`;

const SUBSCRIBE_TO_FAVORITELANES = gql`
subscription sub_fave_lanes( $customer_id: bigint! ) {
    favoritelanes (where: {customer_id: {_eq: $customer_id}}) {
    ...FavoriteLane
  }
}
${fragments.favoriteLane}
`;
const SUBSCRIBE_TO_FAVORITELOCATIONS = gql`
subscription sub_fave_locs( $customer_id: bigint! ) {
    favoritelocations (where: {customer_id: {_eq: $customer_id}}) {
    ...FavoriteLocation
  }
}
${fragments.favoriteLocation}
`;
// const SUBSCRIBE_TO_LOCATIONS = gql`
// subscription sub_locs ( $customer_id: bigint! ) {
//   locations(
//     where: {customer_id: {_eq: $customer_id}, active: {_eq: 1}},
//     order_by: {name: asc},
//   ) {
//     ...Location
//   }
// }
// ${fragments.location}
// `;
const SUBSCRIBE_TO_LANES = gql`
subscription sub_lanes ( $customer_id: bigint! ) {
  lanes(
    where: {customer_id: {_eq: $customer_id}, active: {_eq: 1}},
    order_by: {description: asc},
  ) {
    ...Lane
  }
}
${fragments.lane}
`;

class Private extends Component {

  constructor(props) {
    super(props);
    this.state = {
      customer: 0
    }
  };

  handleCustomerChange = event => {
    const { value } = event.target;
    this.setState({ customer: value });
};

  render() {
    const { component: Component, ...rest } = this.props;

    return (
      <React.Fragment>
        <Container maxWidth="xl">
          {/* Run a query to fetch all available lanes and locations as well as customer-favorite lanes/locations and make them available in the MoveForm component */}
          { this.context.userProfile["https://hasura.io/jwt/claims"] && this.context.userIsAuthenticated() && (
            <Query
              query={GET_LANES_AND_LOCATIONS_FOR_CUSTOMER}
              variables={{ customer_id: this.state.customer }}
              // onError={err => {console.error(err);this.context.handleNotifications(true, "error", "Query failed to retrieve customer lanes and locations data")}}
              >
              {({ subscribeToMore, ...result }) => {
                if (log) console.log("result:", result)
                if (result.loading) return <Loading />;
                if (result.error) return `Error! ${result.error.message}`;
                return (
                  <MoveForm
                  customer={this.state.customer}
                    handleCustomerChange={this.handleCustomerChange}
                    // Each table queried will need its own subscribeToMore function defined here and prop-drilled to the child component
                    subscribeToFavoriteLanes={() => {
                      if (log) console.log("subscribe to favorite lanes");
                      subscribeToMore({
                        document: SUBSCRIBE_TO_FAVORITELANES,
                        variables: { customer_id: this.state.customer },
                        updateQuery: (prev, { subscriptionData }) => {
                          if (log) console.log("fav lanes subscriptionData:", subscriptionData);
                          if (log) console.log("fav lanes prev:", prev);
                          if (!subscriptionData.data) return prev;
                          // return Object.assign({}, prev, subscriptionData.data) // does not appear to work correctly with multiple subscribeToMore functions
                          return { favoritelanes: subscriptionData.data.favoritelanes }
                        }
                      })
                    }
                    }
                    subscribeToFavoriteLocations={() => {
                      if (log) console.log("subscribe to favorite locations");
                      subscribeToMore({
                        document: SUBSCRIBE_TO_FAVORITELOCATIONS,
                        variables: { customer_id: this.state.customer },
                        updateQuery: (prev, { subscriptionData }) => {
                          if (log) console.log("fav locations subscriptionData:", subscriptionData);
                          if (log) console.log("fav locations prev:", prev);
                          if (!subscriptionData.data) return prev;
                          return { favoritelocations: subscriptionData.data.favoritelocations }
                        }
                      })
                    }
                    }
                    subscribeToLanes={() => {
                      if (log) console.log("subscribe to favorite locations");
                      subscribeToMore({
                        document: SUBSCRIBE_TO_LANES,
                        variables: { customer_id: this.state.customer },
                        updateQuery: (prev, { subscriptionData }) => {
                          if (log) console.log("lanes subscriptionData:", subscriptionData);
                          if (log) console.log("lanes prev:", prev);
                          if (!subscriptionData.data) return prev;
                          return { lanes: subscriptionData.data.lanes }
                        }
                      })
                    }
                    }
                    subscribeToLocations={() => {
                      if (log) console.log("subscribe to favorite locations");
                      subscribeToMore({
                        document: SUBSCRIBE_TO_FAVORITELOCATIONS,
                        variables: { customer_id: this.state.customer },
                        updateQuery: (prev, { subscriptionData }) => {
                          if (log) console.log("locations subscriptionData:", subscriptionData);
                          if (log) console.log("locations prev:", prev);
                          if (!subscriptionData.data) return prev;
                          return { locations: subscriptionData.data.locations }
                        }
                      })
                    }
                    }
                    queryData={result.data} // passing the entire query response as a prop to access all retrieved data
                    queryParameters={this.props.location.search}
                    selectedID={this.props.match.params.id}
                  />
                );
              }}
            </Query>)
          }
        </Container>
      </React.Fragment>
    )
  }
}

Private.contextType = GlobalContext

export default Private

