import React from 'react';

import { makeStyles, Grid, Typography } from '@material-ui/core';

import FaqCard from './FaqCard';

////////// COMPONENT //////////
export default function FaqTables(props) {
  const cls = useStyles();
  const { allFaqs, dealerFaqs, driverFaqs, setModalData, setModalRender } = props;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={4} sm={12} xs={12}>
          {allFaqs && (
            <>
              <div className={cls.paper}>
                <Typography className={cls.head}>All FAQs</Typography>
                <Grid container spacing={2}>
                  {allFaqs.map(f => (
                    <FaqCard key={f.id} faq={f} setModalData={setModalData} setModalRender={setModalRender} />
                  ))}
                </Grid>
              </div>
            </>
          )}
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          {dealerFaqs && (
            <>
              <div className={cls.paper}>
                <Typography className={cls.head}>Dealer FAQs</Typography>
                <Grid container spacing={2}>
                  {dealerFaqs.map(f => (
                    <FaqCard key={f.id} faq={f} setModalData={setModalData} setModalRender={setModalRender} />
                  ))}
                </Grid>
              </div>
            </>
          )}
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          {driverFaqs && (
            <>
              <div className={cls.paper}>
                <Typography className={cls.head}>Driver FAQs</Typography>
                <Grid container spacing={2}>
                  {driverFaqs.map(f => (
                    <FaqCard key={f.id} faq={f} setModalData={setModalData} setModalRender={setModalRender} />
                  ))}
                </Grid>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  head: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
}));
