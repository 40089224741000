import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { GlobalContext } from '../../../global-context';

import gql from 'graphql-tag';

import { makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../reusable/ModalComponents';

const getListOfYears = () => {
  const year = moment().add(1, 'y').format('YYYY');
  const till = moment().subtract(35, 'y').format('YYYY');
  let yearList = [];
  for (var y = year; y >= till; y--) {
    yearList.push(`${y}`);
  }
  return yearList;
};

const listOfColors = [
  `Black`,
  `Blue`,
  `Brown`,
  `Burgundy`,
  `Gold`,
  `Gray`,
  `Green`,
  `Orange`,
  `Pink`,
  `Purple`,
  `Red`,
  `Silver`,
  `Tan`,
  `White`,
  `Yellow`,
];

////////// COMPONENT //////////
export default function VehicleEditModal(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const { open, onClose, vehicleInput } = props;

  const [make, setMake] = useState(``);
  const [model, setModel] = useState(``);
  const [year, setYear] = useState(``);
  const [color, setColor] = useState(``);

  const [listOfMakes, setListOfMakes] = useState([]);
  const [listOfModels, setListOfModels] = useState([]);

  useEffect(() => {
    if (vehicleInput) {
      setMake(vehicleInput.make || ``);
      setModel(vehicleInput.model || ``);
      setYear(vehicleInput.year || ``);
      setColor(vehicleInput.color || ``);
    }
  }, [vehicleInput]);

  const handleAutocompleteInput = name => (event, value, reason) => {
    if (reason === `input` || reason === `clear`) {
      if (name === `make`) setMake({ name: value, vehiclemodels: [] });
      else if (name === `model`) setModel({ name: value });
    }
  };
  const handleAutocompleteSelect = (name, value) => {
    if (name === `make`) {
      if (value) setListOfModels(listOfMakes.find(m => m.name === value.name).vehiclemodels);
      else setListOfModels([]);
      setMake(value);
    } else if (name === `model`) {
      setModel(value);
    } else if (name === `year`) {
      setYear(value);
    } else if (name === `color`) {
      setColor(value);
    }
  };

  const handleSubmit = async () => {
    handleClose({
      make: make ? make.name : null,
      model: model ? model.name : null,
      year: year,
      color: color,
    });
  };
  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  const getMakesAndModels = async () => {
    try {
      return await ctx.apolloClient
        .query({
          query: GET_MAKES_AND_MODELS,
        })
        .then(res => {
          setListOfMakes(res.data.vehiclemakes);
        });
    } catch (err) {
      console.log(`Failed to retrieve list of makes:`, err);
      ctx.handleNotifications(true, `error`, `Failed to retrieve list of makes: ` + err.toString());
    }
  };

  function handleFilterResults(options, value, subvalue = null) {
    // Manually handle filtering of options when using both onChange and onInputChange
    try {
      if (!value) return options;
      else if (!subvalue) {
        if (value.trim().length < 1) return options;
        else return options.filter(o => o.toLowerCase().includes(value.toLowerCase()));
      } else {
        if (value[subvalue].trim().length < 1) return options;
        return options.filter(o => o[subvalue].toLowerCase().includes(value[subvalue].toLowerCase()));
      }
    } catch (err) {
      return options;
    }
  }

  return (
    <>
      <Modal open={open}>
        <ModalHeader handleClose={handleClose}>Edit Vehicle</ModalHeader>

        <ModalContent
          subtitle={`Edit the current move's vehicle record stored in our database. Your changes will be made upon clicking 'Submit', then 'Save Changes'.`}
        >
          <Autocomplete
            freeSolo
            includeInputInList
            onOpen={() => getMakesAndModels()}
            options={handleFilterResults(listOfMakes, make, 'name')}
            getOptionLabel={option => option.name}
            noOptionsText='No makes found'
            value={make}
            onChange={(event, value) => handleAutocompleteSelect(`make`, value)}
            onInputChange={handleAutocompleteInput(`make`)}
            style={{ width: '100%', margin: 0 }}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                label='Make'
                placeholder='Enter or select make...'
                variant='outlined'
                margin='dense'
              />
            )}
          />

          <div className={cls.break} />

          <Autocomplete
            freeSolo
            includeInputInList
            options={handleFilterResults(listOfModels, model, 'name')}
            getOptionLabel={option => option.name}
            noOptionsText='No models found'
            value={model}
            onChange={(event, value) => handleAutocompleteSelect(`model`, value)}
            onInputChange={handleAutocompleteInput(`model`)}
            style={{ width: '100%', margin: 0 }}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                label='Model'
                placeholder='Enter or select model...'
                variant='outlined'
                margin='dense'
              />
            )}
          />

          <div className={cls.break} />

          <Autocomplete
            includeInputInList
            options={getListOfYears()}
            getOptionLabel={option => option}
            noOptionsText='No years found'
            value={year}
            onChange={(event, value) => handleAutocompleteSelect(`year`, value)}
            style={{ width: '100%', margin: 0 }}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                label='Year'
                placeholder='Select year...'
                variant='outlined'
                margin='dense'
              />
            )}
          />

          <div className={cls.break} />

          <Autocomplete
            includeInputInList
            options={listOfColors}
            getOptionLabel={option => option}
            noOptionsText='No colors found'
            value={color}
            onChange={(event, value) => handleAutocompleteSelect(`color`, value)}
            style={{ width: '100%', margin: 0 }}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                label='Color'
                placeholder='Select color...'
                variant='outlined'
                margin='dense'
              />
            )}
          />
        </ModalContent>

        <ModalFooter>
          <ModalAction onClick={() => handleSubmit()}>Submit</ModalAction>
          <ModalAction onClick={() => handleClose()} color='default'>
            Cancel
          </ModalAction>
        </ModalFooter>
      </Modal>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  break: {
    width: '100%',
    height: theme.spacing(1),
  },
}));

////////// GRAPHQL //////////
const GET_MAKES_AND_MODELS = gql`
  query get_makes_and_models {
    vehiclemakes(order_by: { name: asc }) {
      id
      name
      vehiclemodels(order_by: { name: asc }) {
        id
        name
      }
    }
  }
`;
