import gql from "graphql-tag";

const PlanHelper = {

    /**
     * Calculate all pricing and driver pay fields for the lane object
     * and populate those fields into the passed in lane object
     *
     * @param  {Moment} date - The date of the plan
     * @param  {int} driver_id - The Tookan id for the driver assigned to the plan
     * @param  {String} driver_name - The name of the driver from Tookan
     * @return  {bool} - Boolean indicating success or failure
     */
    create: async function(client, date, driver_id, driver_name, region_id) {
        const ADD_PLAN = gql`
        mutation addPlan($date: date!, $driver_id: bigint!, $driver_name: String!, $region_id: bigint!) {
            insert_plans(
              objects: {
                plan_date: $date,
                driver_id: $driver_id,
                driver_name: $driver_name
                region_id: $region_id
              }
            ) {
              affected_rows
            }
          }
        `

        return await client.mutate({
          mutation: ADD_PLAN,
          variables: {
            date: date.format('YYYY-MM-DD'),
            driver_id: driver_id,
            driver_name: driver_name,
            region_id: region_id
          }
        })

        // return await helpers.mutation(query, variables, 'insert_plans')
    },
    
    /**
     * Update the parked car location on the plan
     *
     * @param  {BigInt} plan_id - The Tookan id for the driver assigned to the plan
     * @param  {BigInt} parked_location_id - The id of the parked location of the car
     * @return  {bool} - Boolean indicating success or failure
     */
    updateParkedCarLocation: async function(client, plan_id, parked_location_id) {
      //parked_location_id param here is not null on purpose to allow it to be unset
      const UPDATE_PLAN = gql`
      mutation updatePlan($plan_id: bigint!, $parked_location_id: bigint) {
        update_plans(
            where: {
              id: {_eq: $plan_id}
            },
            _set: {
              parked_location: $parked_location_id
            }
          ) {
            affected_rows
          }
        }
      `

      return await client.mutate({
        mutation: UPDATE_PLAN,
        variables: {
          plan_id: plan_id,
          parked_location_id: parked_location_id
        }
      })
  }
};

export default PlanHelper;