const GoogleStaticMapURL = {

    //Markers array should contain 0 or more marker objects structured like this:
    //  
    //  icon:   string (image url)
    //  shadow: boolean
    //  lat:    decimal(9,8)
    //  lon:    decimal(9,8)
    /*  
    //https://maps.googleapis.com/maps/api/staticmap?
    1=1
    &zoom=9
    &size=100x100
    &maptype=roadmap
    &format=png
    &visual_refresh=true
    &center=auto
    &markers=
        |icon:https://s3.ap-south-1.amazonaws.com/social-auto/acknowledgement_images/fkpH1532077213498-faviconsavein32x32.png
        |shadow:true
        37.50498680,-77.62060770%20&markers=|icon:https://s3.ap-south-1.amazonaws.com/social-auto/acknowledgement_images/fkpH1532077213498-faviconsavein32x32.png|shadow:true37.50296690,-77.5913804037.50397685,-77.60599404999999
    
    */

    build: ({ key, markers, center, zoom, scale, size, maptype, format, visual_refresh} = {}) => {
        let url = 'https://maps.googleapis.com/maps/api/staticmap?1=1'
        url += parm('key', key)
        url += parm('zoom', zoom)
        url += parm('scale', scale)
        url += parm('size', size)
        url += parm('maptype', maptype)
        url += parm('format', format)
        url += parm('visual_refresh', visual_refresh)
        url += parm('center', (center.toLocaleLowerCase()=='auto')?autoCenter(markers):center)
        url += markers.map(marker => (marker.lat!=null)?mark(marker):'').join(' ')
        return url
    },
    buildWithAddress: ({ key, marker, center, zoom, scale, size, maptype, format, visual_refresh} = {}) => {
        let url = 'https://maps.googleapis.com/maps/api/staticmap?1=1'
        url += parm('key', key)
        url += parm('zoom', zoom)
        url += parm('scale', scale)
        url += parm('size', size)
        url += parm('maptype', maptype)
        url += parm('format', format)
        url += parm('visual_refresh', visual_refresh)
        url += parm('center', center)
        url += `&markers=icon:${marker.icon}%7Cshadow:${marker.shadow}%7C${marker.address}`
        return url
    }
}

export default GoogleStaticMapURL

const parm = (name, value) => {
    return (value?`&${name}=${value}`:'')
}

const mark = (marker) => {
    let icon = mpart('icon', marker.icon)
    let shadow = mpart('shadow', marker.shadow)
    let latlon = '|' + marker.lat + ',' + marker.lon
    return parm('markers',icon+shadow+latlon)
}

const mpart = (name, value) => {
    return (value?`|${name}:${value}`:'')
}

const autoCenter = (markers) => {
    let minLat = Math.min.apply(Math, markers.map(o => { return o.lat }))
    let maxLat = Math.max.apply(Math, markers.map(o => { return o.lat }))
    let minLon = Math.min.apply(Math, markers.map(o => { return o.lon }))
    let maxLon = Math.max.apply(Math, markers.map(o => { return o.lon }))
    let centerLat = (minLat+maxLat)/2
    let centerLon = (minLon+maxLon)/2
    return `${centerLat},${centerLon}`
}