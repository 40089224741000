import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { GlobalContext } from '../../../global-context';
import {
  makeStyles,
  withStyles,
  Grid,
  Typography,
  Tooltip,
  Switch,
  InputBase,
  TextField,
  InputAdornment,
  Icon,
  MenuItem,
  Button,
  Chip,
} from '@material-ui/core';

import PickupTimeEditModal from './PickupTimeEditModal';
import StatusEditModal from './StatusEditModal';
import VehicleEditModal from './VehicleEditModal';

const log = true;

const cap = str => {
  if (str) {
    if (!str.includes(` `)) return str.charAt(0).toUpperCase() + str.slice(1);
    else {
      let arr = str.split(` `);
      arr = arr.map(s => s.charAt(0).toUpperCase() + s.slice(1));
      return arr.join(` `);
    }
  }
};

////////// COMPONENT //////////
function MoveDetailsInfo(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();
  const { move, tookan, editMode, editVals, editSetVals } = props;
  const {
    active,
    autoAssign,
    cancelStatus,
    chargeable,
    color,
    consumerName,
    consumerPhone,
    consumerPickup,
    dealerContact,
    lyftFlag,
    make,
    manualFlag,
    model,
    moveType,
    notes,
    payable,
    pickupTime,
    priority,
    rateClass,
    rateClassOverride,
    refNum,
    rideType,
    status,
    stock,
    tags,
    vin,
    year,
  } = editVals;
  const {
    setActive,
    setAutoAssign,
    setCancelStatus,
    setChargeable,
    setColor,
    setConsumerName,
    setConsumerPhone,
    setConsumerPickup,
    setDealerContact,
    setLyftFlag,
    setMake,
    setManualFlag,
    setModel,
    setMoveType,
    setNotes,
    setPayable,
    setPickupTime,
    setPriority,
    setRateClass,
    setRateClassOverride,
    setRefNum,
    setRideType,
    setStatus,
    setStock,
    setTags,
    setVin,
    setYear,
  } = editSetVals;

  let moveTags = move.tags && move.tags !== `` ? move.tags.split(`,`) : [];
  useEffect(() => {
    if (move.tags && move.tags !== ``) moveTags = move.tags.split(`,`);
  }, [move]);

  const [selectedTag, setSelectedTag] = useState(``);

  const [pickupTimeModal, setPickupTimeModal] = useState({ open: false });
  const handlePickupTimeEditModalOpen = (input = null) => {
    setPickupTimeModal({ open: true, input: input });
  };
  const handlePickupTimeEditModalClose = (output = null) => {
    setPickupTimeModal({ ...pickupTimeModal, open: false });
    if (output) {
      setPickupTime(output.pickupTime);
    }
  };

  const [statusModal, setStatusModal] = useState({ open: false });
  const handleStatusEditModalOpen = (input = null) => {
    setStatusModal({ open: true, input: input });
  };
  const handleStatusEditModalClose = (output = null) => {
    setStatusModal({ ...statusModal, open: false });
    if (output) {
      setStatus(output.status);
      setCancelStatus(output.cancelStatus);
    }
  };

  const [vehicleModal, setVehicleModal] = useState({ open: false });
  const handleVehicleEditModalOpen = (input = null) => {
    setVehicleModal({ open: true, input: input });
  };
  const handleVehicleEditModalClose = (output = null) => {
    setVehicleModal({ ...vehicleModal, open: false });
    if (output) {
      setMake(output.make);
      setModel(output.model);
      setYear(output.year);
      setColor(output.color);
    }
  };

  useEffect(() => {
    setActive(move.active === 1 ? true : false);
    setAutoAssign(move.auto_assign === 1 ? true : false);
    setCancelStatus(move.cancel_status || ``);
    setChargeable(move.chargeable);
    setColor(move.vehicle_color || ``);
    setConsumerName(move.consumer_name || ``);
    setConsumerPhone(move.consumer_phone || ``);
    setConsumerPickup(move.consumer_pickup);
    setDealerContact(move.dealer_contact || ``);
    setLyftFlag(move.lyft_flag === 1 ? true : false);
    setMake(move.vehicle_make || ``);
    setManualFlag(move.manual_flag);
    setModel(move.vehicle_model || ``);
    setNotes(move.move_details || ``);
    setMoveType(move.move_type || ``);
    setPayable(move.payable);
    setPickupTime(move.pickup_time || ``);
    setPriority(move.priority || 10);
    setRateClass(move.class || ``);
    setRateClassOverride(move.rate_class_override === 1 ? true : false);
    setRefNum(move.reference_num || ``);
    setRideType(move.ride_type || ``);
    setStatus(move.status || ``);
    setStock(move.vehicle_stock || ``);
    setTags(move.tags ? move.tags.split(`,`) : []);
    setVin(move.vehicle_vin || ``);
    setYear(move.vehicle_year || ``);
  }, [editMode, move]);

  // Extra useEffect for pickupTime to be auto set
  // useEffect(() => {
  //   setPickupTime(move.pickup_time || ``);
  // }, [move]);

  const handleSwitch = (prev, setHandler) => {
    if (setHandler) {
      if (prev) setHandler(false);
      else setHandler(true);
    }
  };
  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };
  const handlePriorityChange = event => {
    let input = 9;

    if (event.key) input = Number(event.key);
    else if (event.target && event.target.value) input = Number(event.target.value);

    if (input >= 1 && input <= 9) setPriority(input);
  };

  const handlePickupTimeEdit = () => {
    handlePickupTimeEditModalOpen({
      pickupTime: pickupTime,
    });
  };

  const handleStatusEdit = () => {
    handleStatusEditModalOpen({
      status: status,
      cancelStatus: cancelStatus,
    });
  };

  const handleVehicleEdit = () => {
    handleVehicleEditModalOpen({
      make: { name: make, vehiclemodels: [] },
      model: { name: model },
      year: year,
      color: color,
    });
  };

  const handleAddTag = () => {
    if (selectedTag !== ``) {
      if (!tags.includes(selectedTag)) setTags([...tags, selectedTag]);
      else log && console.log(`Tag already exists on this move!`);
    } else log && console.log(`Please select a tag to add it!`);
    setSelectedTag(``);
  };
  const handleDeleteTag = tag => {
    let newTags = [...tags];
    if (newTags.includes(tag)) {
      const index = newTags.indexOf(tag);
      newTags.splice(index, 1);
      log && console.log(`Deleting tag:`, { tag, index, newTags });
      setTags(newTags);
    } else console.log(`Error: Tag not found!`, { tags, tag });
  };

  const getStatus = () => {
    if (cancelStatus && cancelStatus !== ``) {
      if (cancelStatus === `canceled`) return `Canceled`;
      else if (cancelStatus === `pending`) return `Cancel Pending`;
      else if (cancelStatus === `seen`) return `Cancel Seen`;
      else if (cancelStatus === `started`) return `Cancel Started`;
      else if (cancelStatus === `delivered`) return `Delivery Successful`;
      else return cap(cancelStatus);
    } else if (status && status !== ``) {
      if (status === `dispatched`) return `Dispatched`;
      else if (status === `pickup started`) return `Pickup Started`;
      else if (status === `pickup arrived`) return `Pickup Arrived`;
      else if (status === `pickup successful`) return `Pickup Successful`;
      else if (status === `delivery started`) return `Delivery Started`;
      else if (status === `delivery arrived`) return `Delivery Arrived`;
      else if (status === `delivery successful`) return `Delivery Successful`;
      else if (status === `pickedUp`) return `Picked Up`;
      else if (status === `droppedOff`) return `Dropped Off`;
      else if (status === `canceled`) return `Canceled`;
      else if (status === `failed`) return `Failed`;
      else return cap(status);
    } else return `Unplanned`;
  };

  const getVehicle = () => {
    let vehicle = ``;
    if (!move.vehicle_make && !move.vehicle_model) vehicle = `No Vehicle Info`;
    else vehicle = `${move.vehicle_year} ${move.vehicle_make} ${move.vehicle_model}`;

    let vehicleColor = ``;
    if (move.vehicle_color) vehicleColor = ` (${cap(move.vehicle_color)})`;

    return vehicle + vehicleColor;
  };

  const getDriverInitials = () => {
    let fullName = move.driver_name ? move.driver_name : `N/A`;
    if (fullName !== `N/A`) {
      let names = fullName.split(` `);
      let firstI = names[0][0];
      let secondI = names[names.length - 1][0];
      return `${firstI}${secondI}`.toUpperCase();
    } else return fullName;
  };

  const AntSwitch = withStyles(theme => ({
    root: {
      verticalAlign: 'top',
      float: 'right',
      display: 'flex',
      width: '36px',
      height: '17px',
      padding: 0,
      transition: '0.2s',
    },
    switchBase: {
      padding: '2px',
      color: theme.palette.text.secondary,
      '&$checked': {
        transform: 'translateX(19px)',
        color: '#fff',
        '& + $track': {
          borderColor: editMode ? theme.palette.primary.main : `#00000000`,
          backgroundColor: editMode ? theme.palette.primary.main : theme.palette.text.secondary,
          opacity: 1,
        },
      },
    },
    thumb: {
      width: '13px',
      height: '13px',
      boxShadow: 'none',
    },
    track: {
      border: `1px solid #ddd`,
      borderRadius: '8.5px',
      backgroundColor: '#fff',
      opacity: 1,
    },
    checked: {},
  }))(Switch);

  const GetMoveStyle = () => {
    return (
      <>
        {move &&
          move.consumer_pickup &&
          move.moveByReturnRideId &&
          move.moveByReturnRideId.tags &&
          move.moveByReturnRideId.tags.includes('loaner') && (
            <div>
              Concierge +{' '}
              <Button
                variant='outlined'
                size='small'
                color='primary'
                style={{ maxHeight: '20px' }}
                onClick={() => {
                  props.history.push(`/moves/${move.moveByReturnRideId.id}`);
                }}
              >
                Loaner
              </Button>
            </div>
          )}
        {move && move.consumer_pickup && !move.moveByReturnRideId && <div>Concierge (no loaner)</div>}
        {move &&
          !move.consumer_pickup &&
          !move.moveByReturnRideId &&
          !move.chargeable &&
          move.tags &&
          move.tags.includes('loaner') && <div>Loaner</div>}
        {move &&
          !move.consumer_pickup &&
          !move.moveByReturnRideId &&
          move.chargeable &&
          move.tags &&
          !move.tags.includes('loaner') && <div>Stranded Move (not concierge)</div>}
      </>
    );
  };

  return (
    <>
      <PickupTimeEditModal
        open={pickupTimeModal.open}
        onClose={handlePickupTimeEditModalClose}
        pickupTimeInput={pickupTimeModal.input}
      />

      <StatusEditModal open={statusModal.open} onClose={handleStatusEditModalClose} statusInput={statusModal.input} />

      <VehicleEditModal
        open={vehicleModal.open}
        onClose={handleVehicleEditModalClose}
        vehicleInput={vehicleModal.input}
      />

      <div className={cls.main}>
        <div className={cls.info}>
          <Typography className={cls.infoTxt}>VEHICLE</Typography>
          <div className={cls.smallBreak} />
          <Tooltip title={getVehicle()}>
            {tookan && tookan.pickupImage ? (
              <img className={cls.infoImage} src={tookan.pickupImage} alt='vehicle' />
            ) : (
              <div className={cls.infoImage}>
                <Icon className={cls.infoNoVehicleIcon}>drive_eta</Icon>
              </div>
            )}
          </Tooltip>
        </div>

        {ctx.width === `sm` || ctx.width === `xs` ? <div className={cls.bigBreak} /> : null}

        <div className={cls.paper}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Tooltip placement='top-start' title='Move ID'>
                <Typography className={cls.headTxt} style={{ textAlign: 'left' }}>
                  #{move.id}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              {editMode &&
              status !== `delivery successful` &&
              status !== `droppedOff` &&
              status !== `canceled` &&
              status !== `failed` &&
              cancelStatus !== `started` &&
              cancelStatus !== `delivered` &&
              cancelStatus !== `canceled` ? (
                <Tooltip placement='top' title='Click to edit pickup time'>
                  <Typography
                    className={cls.headTxt}
                    style={{ textAlign: 'center', color: ctx.theme.palette.primary.main, cursor: `pointer` }}
                    onClick={() => handlePickupTimeEdit()}
                  >
                    {moment(pickupTime).format(`MM/DD/YYYY`)}
                  </Typography>
                </Tooltip>
              ) : (
                <Tooltip placement='top' title='Move Date'>
                  <Typography className={cls.headTxt} style={{ textAlign: 'center' }}>
                    {moment(pickupTime).format(`MM/DD/YYYY`)}
                  </Typography>
                </Tooltip>
              )}
            </Grid>
            <Grid item xs={4}>
              {editMode ? (
                <Tooltip placement='top-end' title='Click to edit status'>
                  <Typography
                    className={cls.headTxt}
                    style={{ textAlign: 'right', color: ctx.theme.palette.primary.main, cursor: `pointer` }}
                    onClick={() => handleStatusEdit()}
                  >
                    {getStatus()}
                  </Typography>
                </Tooltip>
              ) : (
                <Tooltip placement='top-end' title='Move Status'>
                  <Typography className={cls.headTxt} style={{ textAlign: 'right' }}>
                    {getStatus()}
                  </Typography>
                </Tooltip>
              )}
            </Grid>
          </Grid>

          <div className={cls.break} />

          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Vehicle:&nbsp;&nbsp;</Typography>
                {editMode ? (
                  <Typography
                    className={cls.valTxt}
                    style={{ background: '#00000010', color: ctx.theme.palette.primary.main, cursor: 'pointer' }}
                    onClick={() => handleVehicleEdit()}
                  >
                    Click to edit vehicle
                  </Typography>
                ) : (
                  <Typography className={cls.valTxt}>{getVehicle()}</Typography>
                )}
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>
                  {editMode ? `Manual\xa0Flag` : `Transmission`}:&nbsp;&nbsp;
                </Typography>
                {editMode ? (
                  <div className={cls.switch}>
                    <AntSwitch
                      disabled={!editMode}
                      checked={manualFlag}
                      onChange={() => handleSwitch(manualFlag, setManualFlag)}
                    />
                  </div>
                ) : (
                  <Typography className={cls.valTxt}>{move.manual_flag ? `Manual` : `Automatic`}</Typography>
                )}
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Stock:&nbsp;&nbsp;</Typography>
                {editMode ? (
                  <InputBase
                    value={stock}
                    onChange={handleInputChange(setStock)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  />
                ) : (
                  <Typography className={cls.valTxt}>{move.vehicle_stock || `-`}</Typography>
                )}
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>VIN:&nbsp;&nbsp;</Typography>
                {editMode ? (
                  <InputBase
                    value={vin}
                    onChange={handleInputChange(setVin)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  />
                ) : (
                  <Typography className={cls.valTxt}>{move.vehicle_vin || `-`}</Typography>
                )}
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Reference&nbsp;#:&nbsp;&nbsp;</Typography>
                {editMode ? (
                  <InputBase
                    value={refNum}
                    onChange={handleInputChange(setRefNum)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  />
                ) : (
                  <Typography className={cls.valTxt}>{move.reference_num || `-`}</Typography>
                )}
              </div>
            </Grid>

            <div className={cls.hiddenLineBreak} />

            <Grid item sm={6} xs={12}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Driver&nbsp;Name:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{move.driver_name || `HopDriver`}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Dealer:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{move.customer.name || `Unknown Dealer`}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Drive&nbsp;Distance:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {move.lane && move.lane.distance_miles ? `${move.lane.distance_miles} miles` : `-`}
                </Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Dealer&nbsp;Contact:&nbsp;&nbsp;</Typography>
                {editMode ? (
                  <InputBase
                    value={dealerContact}
                    onChange={handleInputChange(setDealerContact)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  />
                ) : (
                  <Typography className={cls.valTxt}>{move.dealer_contact || `-`}</Typography>
                )}
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Notes/Instructions:&nbsp;&nbsp;</Typography>
                {editMode ? (
                  <InputBase
                    value={notes}
                    onChange={handleInputChange(setNotes)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  />
                ) : (
                  <Typography className={cls.valTxt}>{move.move_details || `-`}</Typography>
                )}
              </div>
            </Grid>
          </Grid>
        </div>

        {ctx.width === `sm` || ctx.width === `xs` ? <div className={cls.bigBreak} /> : null}

        <div className={cls.info}>
          <Typography className={cls.infoTxt}>DRIVER</Typography>
          <div className={cls.smallBreak} />
          <Tooltip title={move.driver_name ? move.driver_name : `HopDriver`}>
            {tookan && tookan.driver && tookan.driver.fleet_thumb_image ? (
              <img className={cls.infoImage} src={tookan.driver.fleet_thumb_image} alt='driver' />
            ) : (
              <div className={cls.infoImage}>
                <Typography className={cls.infoNoDriverTxt}>{getDriverInitials()}</Typography>
              </div>
            )}
          </Tooltip>
        </div>
      </div>

      <div className={cls.bigBreak} />

      <div className={cls.main}>
        <div className={cls.info}>
          <div className={cls.infoSpacer} />
        </div>

        <div className={cls.paper}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Active:&nbsp;&nbsp;</Typography>
                <div className={cls.switch}>
                  <AntSwitch
                    disabled={!editMode}
                    checked={editMode ? active : move.active === 1 ? true : false}
                    onChange={() => handleSwitch(active, setActive)}
                  />
                </div>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Priority:&nbsp;&nbsp;</Typography>
                {editMode ? (
                  <InputBase
                    type='number'
                    min='1'
                    max='9'
                    value={priority}
                    onKeyPress={handlePriorityChange}
                    onChange={handlePriorityChange}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  />
                ) : (
                  <Typography className={cls.valTxt}>{move.priority || `-`}</Typography>
                )}
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Move&nbsp;Type:&nbsp;&nbsp;</Typography>
                {editMode ? (
                  <InputBase
                    value={moveType}
                    onChange={handleInputChange(setMoveType)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  />
                ) : (
                  <Typography className={cls.valTxt}>{move.move_type ? cap(move.move_type) : `-`}</Typography>
                )}
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Ride&nbsp;Type:&nbsp;&nbsp;</Typography>
                {editMode ? (
                  <InputBase
                    value={rideType}
                    onChange={handleInputChange(setRideType)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  />
                ) : (
                  <Typography className={cls.valTxt}>{move.ride_type ? cap(move.ride_type) : `-`}</Typography>
                )}
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Rate&nbsp;Class:&nbsp;&nbsp;</Typography>
                {editMode ? (
                  <InputBase
                    value={rateClass}
                    onChange={handleInputChange(setRateClass)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  />
                ) : (
                  <Typography className={cls.valTxt}>{move.class ? cap(move.class) : `-`}</Typography>
                )}
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Return&nbsp;Ride:&nbsp;&nbsp;</Typography>
                <div className={cls.valTxt}>
                  {move.moveByReturnRideId ? (
                    <Button
                      variant='outlined'
                      size='small'
                      color='primary'
                      style={{ maxHeight: '20px' }}
                      onClick={() => {
                        props.history.push(`/moves/${move.moveByReturnRideId.id}`);
                      }}
                    >
                      {move.moveByReturnRideId.id}
                    </Button>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Move&nbsp;Style:&nbsp;&nbsp;</Typography>
                <div className={cls.valTxt}>
                  <GetMoveStyle />
                </div>
              </div>
            </Grid>

            <div className={cls.hiddenLineBreak} />

            <Grid item sm={6} xs={12}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Chargeable:&nbsp;&nbsp;</Typography>
                <div className={cls.switch}>
                  <AntSwitch
                    disabled={!editMode}
                    checked={editMode ? chargeable : move.chargeable}
                    onChange={() => handleSwitch(chargeable, setChargeable)}
                  />
                </div>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Payable:&nbsp;&nbsp;</Typography>
                <div className={cls.switch}>
                  <AntSwitch
                    disabled={!editMode}
                    checked={editMode ? payable : move.payable}
                    onChange={() => handleSwitch(payable, setPayable)}
                  />
                </div>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Auto&nbsp;Assign:&nbsp;&nbsp;</Typography>
                <div className={cls.switch}>
                  <AntSwitch
                    disabled={!editMode}
                    checked={editMode ? autoAssign : move.auto_assign === 1 ? true : false}
                    onChange={() => handleSwitch(autoAssign, setAutoAssign)}
                  />
                </div>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Lyft&nbsp;Flag:&nbsp;&nbsp;</Typography>
                <div className={cls.switch}>
                  <AntSwitch
                    disabled={!editMode}
                    checked={editMode ? lyftFlag : move.lyft_flag === 1 ? true : false}
                    onChange={() => handleSwitch(lyftFlag, setLyftFlag)}
                  />
                </div>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Rate&nbsp;Class&nbsp;Override:&nbsp;&nbsp;</Typography>
                <div className={cls.switch}>
                  <AntSwitch
                    disabled={!editMode}
                    checked={editMode ? rateClassOverride : move.rate_class_override === 1 ? true : false}
                    onChange={() => handleSwitch(rateClassOverride, setRateClassOverride)}
                  />
                </div>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Consumer&nbsp;Pickup:&nbsp;&nbsp;</Typography>
                <div className={cls.switch}>
                  <AntSwitch
                    disabled={!editMode}
                    checked={editMode ? consumerPickup : move.consumer_pickup}
                    onChange={() => handleSwitch(consumerPickup, setConsumerPickup)}
                  />
                </div>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Has&nbsp;Loaner:&nbsp;&nbsp;</Typography>
                <div className={cls.switch}>
                  <AntSwitch
                    disabled={true}
                    checked={
                      move.moveByReturnRideId &&
                      move.moveByReturnRideId.tags &&
                      move.moveByReturnRideId.tags.includes('loaner')
                    }
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className={cls.info}>
          <div className={cls.infoSpacer} />
        </div>
      </div>

      <div className={cls.bigBreak} />

      {(!editMode && move.consumer_pickup) || (editMode && consumerPickup) ? (
        <>
          <div className={cls.main}>
            <div className={cls.info}>
              <div className={cls.infoSpacer} />
            </div>

            <div className={cls.paper}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Consumer&nbsp;Name:&nbsp;&nbsp;</Typography>
                    {editMode ? (
                      <InputBase
                        value={consumerName}
                        onChange={handleInputChange(setConsumerName)}
                        className={cls.nakedValTxt}
                        inputProps={{ className: cls.nakedValInput }}
                      />
                    ) : (
                      <Typography className={cls.valTxt}>{move.consumer_name || `-`}</Typography>
                    )}
                  </div>
                </Grid>

                <div className={cls.hiddenLineBreak} />

                <Grid item sm={6} xs={12}>
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Consumer&nbsp;Phone:&nbsp;&nbsp;</Typography>
                    {editMode ? (
                      <InputBase
                        value={consumerPhone}
                        onChange={handleInputChange(setConsumerPhone)}
                        className={cls.nakedValTxt}
                        inputProps={{ className: cls.nakedValInput }}
                      />
                    ) : (
                      <Typography className={cls.valTxt}>{move.consumer_phone || `-`}</Typography>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={cls.info}>
              <div className={cls.infoSpacer} />
            </div>
          </div>

          <div className={cls.bigBreak} />
        </>
      ) : null}

      <div className={cls.main}>
        <div className={cls.info}>
          <div className={cls.infoSpacer} />
        </div>

        <div className={cls.paper}>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                select
                fullWidth
                disabled={!editMode}
                margin='dense'
                variant='outlined'
                label='Tag Select'
                placeholder='Select a tag...'
                value={selectedTag}
                onChange={handleInputChange(setSelectedTag)}
                className={cls.input}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        label
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value=''>
                  <em>Select a tag...</em>
                </MenuItem>
                <MenuItem value='delivery reported'>Delivery Reported</MenuItem>
                <MenuItem value='loaner'>Loaner</MenuItem>
              </TextField>
            </Grid>
            <Grid item>
              <Button disabled={!editMode} className={cls.btn} onClick={() => handleAddTag()}>
                Add Tag
              </Button>
            </Grid>
          </Grid>

          {!editMode && moveTags.length > 0 ? (
            <>
              <div className={cls.break} />
              <div className={cls.tags}>
                {moveTags.map((tag, i) => (
                  <Chip
                    key={`chip-tag-${i}`}
                    label={cap(tag)}
                    className={cls.tag}
                    style={{ background: ctx.theme.palette.secondary.light }}
                  />
                ))}
              </div>
            </>
          ) : null}

          {editMode && tags.length > 0 ? (
            <>
              <div className={cls.break} />
              <div className={cls.tags}>
                {tags.map((tag, i) => (
                  <Chip
                    key={`edit-chip-tag-${i}`}
                    label={cap(tag)}
                    clickable
                    onClick={() => handleDeleteTag(tag)}
                    icon={<Icon style={{ color: '#fff' }}>cancel</Icon>}
                    className={cls.tag}
                    style={{ background: ctx.theme.palette.primary.main }}
                  />
                ))}
              </div>
            </>
          ) : null}
        </div>

        <div className={cls.info}>
          <div className={cls.infoSpacer} />
        </div>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  info: {
    display: 'block',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  infoTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  infoImage: {
    objectFit: 'cover',
    display: 'block',
    position: 'relative',
    width: '128px',
    height: '128px',
    border: theme.border[0],
    borderRadius: '50%',
    background: theme.palette.fade[3],
    verticalAlign: 'top',
  },
  infoSpacer: {
    display: 'block',
    position: 'relative',
    width: '128px',
  },
  infoNoVehicleIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#fff',
    fontSize: '80px',
    cursor: 'default',
  },
  infoNoDriverTxt: {
    position: 'absolute',
    top: '21%',
    left: 0,
    right: 0,
    color: '#fff',
    textAlign: 'center',
    fontSize: '48px',
    fontWeight: 700,
    cursor: 'default',
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    cursor: 'default',
  },
  list: {},
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  nakedValTxt: {
    width: '100%',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  nakedValInput: {
    width: '100%',
    minHeight: '17px',
    maxHeight: '17px',
    padding: 0,
    background: '#00000010',
    textAlign: 'right',
  },
  switch: {
    width: '100%',
  },
  input: {
    margin: 0,
  },
  btn: {
    verticalAlign: 'top',
    minHeight: '40px',
    maxHeight: '40px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },
    '&:disabled': {
      color: '#fff',
      backgroundColor: theme.palette.text.disabled,
    },
  },
  tags: {
    display: 'block',
    position: 'relative',
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  tag: {
    marginRight: theme.spacing(1),
    color: `#fff`,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  tinyBreak: {
    width: '100%',
    height: theme.spacing(0.5),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#eee',
  },
  hiddenLineBreak: {
    display: 'none',
    width: '100%',
    height: '1px',
    marginTop: '-2px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: '#eee',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));

////////// EXPORT //////////
export default withRouter(MoveDetailsInfo);
