import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { GlobalContext } from '../../global-context';

import { makeStyles, Container, Typography } from '@material-ui/core';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import Loading from '../utils/Loading';
import ARReportPaymentsFilter from './ARReportPayments/ARReportPaymentsFilter';
import ARReportPaymentsFooter from './ARReportPayments/ARReportPaymentsFooter';

import { AccordianTable, AccordianRow, TableSort } from '../reusable/AccordianTable';

import { ExportToCsv } from 'export-to-csv';

const log = true;

const cap = str => {
  if (str) {
    if (!str.includes(` `)) return str.charAt(0).toUpperCase() + str.slice(1);
    else {
      let arr = str.split(` `);
      arr = arr.map(s => s.charAt(0).toUpperCase() + s.slice(1));
      return arr.join(` `);
    }
  }
};

const checkNeg = num => {
  if (num > 0) return num;
  else return 0;
};

const round = (num, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  const output = Math.round(num * multiplier) / multiplier;
  return output;
};

const getDefaultRange = () => {
  const localRange = localStorage.getItem(`arreport-payments-range`);
  if (localRange) return localRange;
  else return `week`;
};
const getDefaultStart = () => {
  const localRange = localStorage.getItem(`arreport-payments-range`);
  const localStart = localStorage.getItem(`arreport-payments-start`);
  if (localRange && localRange !== `custom`)
    return moment.utc(moment().startOf(`day`).subtract(1, localRange)).format();
  else if (localRange === `custom` && localStart) return localStart;
  else return moment.utc(moment().startOf(`day`).subtract(1, `week`)).format();
};
const getDefaultEnd = () => {
  const localRange = localStorage.getItem(`arreport-payments-range`);
  const localEnd = localStorage.getItem(`arreport-payments-end`);
  if (localRange && localRange !== `custom`) return moment.utc(moment().startOf(`day`)).format();
  else if (localRange === `custom` && localEnd) return localEnd;
  else return moment.utc(moment().startOf(`day`)).format();
};
const getDefaultDisable = () => {
  const localRange = localStorage.getItem(`arreport-payments-range`);
  if (localRange && localRange === `custom`) return false;
  else return true;
};

const defaultOrder = `desc`;
const defaultOrderBy = `ID`;

////////// COMPONENT //////////
function ARReportPayments(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [status, setStatus] = useState(null);
  const localCustomerId = parseInt(localStorage.getItem(`customerId`));
  const [customerId, setCustomerId] = useState(localCustomerId || null);
  const [range, setRange] = useState(getDefaultRange());
  const [start, setStart] = useState(getDefaultStart());
  const [end, setEnd] = useState(getDefaultEnd());
  const [disablePickers, setDisablePickers] = useState(getDefaultDisable());

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [expandedRowId, setExpandedRowId] = useState(0);

  useEffect(() => {
    localStorage.setItem(`customerId`, customerId);
  }, [customerId]);

  // Control range picker
  const handleRangeChange = value => {
    log && console.log(`Range Change:`, value);
    localStorage.setItem(`arreport-payments-range`, value);
    if (value !== `custom`) {
      setDisablePickers(true);
      setRange(value);
      setStart(moment.utc(moment().startOf(`day`).subtract(1, value)).format());
      setEnd(moment.utc(moment().startOf(`day`)).format());
    } else {
      setDisablePickers(false);
      setRange(value);
      setStart(getDefaultStart());
      setEnd(getDefaultEnd());
    }
  };

  // Control date pickers
  const handleDateChange = (value, name) => {
    log && console.log(`Date Change:`, { value, name });
    if (name === `start`) {
      const newDate = moment.utc(moment(value).startOf(`day`)).format();
      localStorage.setItem(`arreport-payments-start`, newDate);
      setStart(newDate);
    } else {
      const newDate = moment.utc(moment(value).startOf(`day`)).format();
      localStorage.setItem(`arreport-payments-end`, newDate);
      setEnd(newDate);
    }
  };

  const applyFilters = data => {
    if (!search || search.length < 1) return data;
    else {
      return data.filter(o => {
        if (
          (o.arinvoice.customer.name && o.arinvoice.customer.name.toLocaleLowerCase().includes(search)) ||
          (o.id && (o.id + ``).toLocaleLowerCase().includes(search)) ||
          (o.arinvoice.id && (o.arinvoice.id + ``).toLocaleLowerCase().includes(search)) ||
          (o.accounting_id && (o.accounting_id + ``).toLocaleLowerCase().includes(search)) ||
          (o.gateway_transaction_id && o.gateway_transaction_id.toLocaleLowerCase().includes(search))
        ) {
          return true;
        } else return false;
      });
    }
  };

  const generateCSV = arpayments => {
    const createCsvRow = arpayment => {
      return {
        ID: arpayment.id,
        CUSTOMER: arpayment.arinvoice.customer ? arpayment.arinvoice.customer.name : `-`,
        INVOICE_ID: arpayment.arinvoice.id,
        ACCOUNTING_ID: arpayment.accounting_id,
        TRANSACTION_ID: arpayment.gateway_transaction_id,
        DATE: moment(arpayment.createdat).format(`MM/DD/YYYY`),
        STATUS: arpayment.status ? cap(arpayment.status) : `-`,
        TOTAL: arpayment.amount ? `$${checkNeg(arpayment.amount).toFixed(2)}` : arpayment.amount === 0 ? `$0.00` : `-`,
      };
    };
    const csvRows = arpayments.map(arpayment => createCsvRow(arpayment));
    const csvOptions = {
      filename: `${
        customerId ? arpayments[0].arinvoice.customer.name.replace(/ /g, '_') : `All`
      }_AR_Payments_from_${start}_to_${end}`,
      showTitle: true,
      title: `${customerId ? arpayments[0].arinvoice.customer.name : `All`} AR Payments from ${start} to ${end}`,
      useKeysAsHeaders: true,
    };

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  };

  const goToInvoice = invoiceId => {
    localStorage.setItem('invoiceId', invoiceId);
    props.history.push(`/invoices`);
  };

  const getTableActions = arpayments => {
    return [{ label: `Generate\xa0CSV`, handler: () => generateCSV(arpayments) }];
  };
  const getRowActions = arpayment => {
    return [{ label: `View\xa0Invoice`, handler: () => goToInvoice(arpayment.arinvoice.id) }];
  };

  return (
    <>
      <div className={cls.root}>
        {ctx && ctx.userIsAuthenticated() && (
          <Container maxWidth='lg'>
            <Typography className={cls.headTxt}>AR Report Index (Payments)</Typography>

            <ARReportPaymentsFilter
              status={status}
              customerId={customerId}
              range={range}
              start={start}
              end={end}
              onStatusChange={setStatus}
              onCustomerChange={setCustomerId}
              onRangeChange={handleRangeChange}
              onDateChange={handleDateChange}
              disablePickers={disablePickers}
            />

            <div className={cls.break} />
            <Query
              query={GET_ARPAYMENTS}
              variables={{ status: status, customerId: customerId, start: start, end: end }}
            >
              {({ loading, error, data, refetch }) => {
                if (loading) return <Loading fixed />;
                if (error) {
                  console.log(`Failed to retrieve arpayments:`, error);
                  return (
                    <div className={cls.notFound}>
                      <Typography className={cls.notFoundTxt}>ERROR FINDING AR PAYMENTS</Typography>
                    </div>
                  );
                }
                if (data && data.arpayments && data.arpayments.length > 0) {
                  // log && console.log(`ARPayments for ARReport Index:`, data.arpayments);
                  const filteredAR = applyFilters(data.arpayments);

                  // Set a consistent amount object that holds the totals
                  var amount = {};

                  // Valid payments to calculate base totals
                  const subtotalPayments = filteredAR.filter(item => item.amount > 0);
                  const successfulPayments = subtotalPayments.filter(item => item.status === `successful`);
                  const failedPayments = subtotalPayments.filter(item => item.status === `failed`);

                  // Base totals from valid payments
                  amount.subtotal = round(
                    subtotalPayments.length > 0
                      ? subtotalPayments.map(item => item.amount).reduce((total, current) => total + current)
                      : 0,
                    2
                  );
                  amount.successful = round(
                    successfulPayments.length > 0
                      ? successfulPayments.map(item => item.amount).reduce((total, current) => total + current)
                      : 0,
                    2
                  );
                  amount.failed = round(
                    failedPayments.length > 0
                      ? failedPayments.map(item => item.amount).reduce((total, current) => total + current)
                      : 0,
                    2
                  );

                  // Set calculations from base totals
                  amount.total = checkNeg(amount.subtotal);

                  // log && console.log(`AR Amount:`, amount);

                  const headers = [
                    { id: `ID`, alignLeft: true, numeric: true, label: `ID` },
                    { id: `CUSTOMER`, alignLeft: true, numeric: false, label: `Customer` },
                    { id: `INVOICE_ID`, alignLeft: true, numeric: true, label: `Invoice\xa0ID` },
                    { id: `ACCOUNTING_ID`, alignLeft: true, numeric: true, label: `Accounting\xa0ID` },
                    { id: `TRANSACTION_ID`, alignLeft: true, numeric: true, label: `Transaction\xa0ID` },
                    { id: `DATE`, alignLeft: false, numeric: true, label: `Date` },
                    { id: `STATUS`, alignLeft: true, numeric: false, label: `Status` },
                    { id: `TOTAL`, alignLeft: false, numeric: true, label: `Total` },
                  ];
                  const rows = filteredAR.map(arpayment => {
                    return {
                      ID: arpayment.id,
                      CUSTOMER: arpayment.arinvoice.customer ? arpayment.arinvoice.customer.name : `-`,
                      INVOICE_ID: arpayment.arinvoice.id,
                      ACCOUNTING_ID: arpayment.accounting_id,
                      TRANSACTION_ID: arpayment.gateway_transaction_id,
                      DATE: moment(arpayment.createdat).format(`MM/DD/YYYY`),
                      STATUS: arpayment.status ? cap(arpayment.status) : `-`,
                      TOTAL: arpayment.amount
                        ? `$${checkNeg(arpayment.amount).toFixed(2)}`
                        : arpayment.amount === 0
                        ? `$0.00`
                        : `-`,
                      arpayment: arpayment,
                    };
                  });

                  return (
                    <>
                      <AccordianTable
                        title={`${rows.length} AR Payments`}
                        headers={headers}
                        rows={rows}
                        actions={getTableActions(data.arpayments)}
                        search={search}
                        defaultOrder={defaultOrder}
                        defaultOrderBy={defaultOrderBy}
                        order={order}
                        orderBy={orderBy}
                        tablePage={tablePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        setSearch={setSearch}
                        setOrder={setOrder}
                        setOrderBy={setOrderBy}
                        setTablePage={setTablePage}
                        setRowsPerPage={setRowsPerPage}
                        setExpandedRowId={setExpandedRowId}
                        className={cls.table}
                        refetch={refetch}
                        refreshPersistAs='ar_report_payments'
                      >
                        {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                          .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                          .map(row => (
                            <AccordianRow
                              key={`arreport-payment-${row.arpayment.id}`}
                              rowId={row.arpayment.id}
                              expandedRowId={expandedRowId}
                              setExpandedRowId={setExpandedRowId}
                              columns={[
                                { align: 'left', value: row.ID },
                                { align: 'left', value: row.CUSTOMER },
                                { align: 'left', value: row.INVOICE_ID },
                                { align: 'left', value: row.ACCOUNTING_ID },
                                { align: 'left', value: row.TRANSACTION_ID },
                                { align: 'right', value: row.DATE },
                                { align: 'left', value: row.STATUS },
                                { align: 'right', value: row.TOTAL },
                              ]}
                              actions={getRowActions(row.arpayment)}
                              onClick={() => goToInvoice(row.INVOICE_ID)}
                              className={cls.row}
                            >
                              <div />
                            </AccordianRow>
                          ))}
                      </AccordianTable>

                      <div className={cls.break} />

                      <ARReportPaymentsFooter amount={amount} />
                    </>
                  );
                } else
                  return (
                    <div className={cls.notFound}>
                      <Typography className={cls.notFoundTxt}>NO AR PAYMENTS FOUND</Typography>
                    </div>
                  );
              }}
            </Query>
          </Container>
        )}
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      background: theme.palette.action.hover,
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  headTxt: {
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));

////////// GRAPHQL //////////
const GET_ARPAYMENTS = gql`
  query get_arpayments($status: String, $customerId: bigint, $start: timestamptz!, $end: timestamptz!) {
    arpayments(
      where: {
        status: { _eq: $status }
        arinvoice: { customer_id: { _eq: $customerId } }
        createdat: { _gte: $start, _lte: $end }
      }
      order_by: { id: desc }
    ) {
      id
      accounting_id
      gateway_transaction_id
      createdat
      status
      amount
      arinvoice {
        id
        customer {
          id
          name
        }
      }
    }
  }
`;

////////// EXPORT //////////
export default withRouter(ARReportPayments);
