import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment'
import Switch from '@material-ui/core/Switch';
import { CardContent, Card } from "@material-ui/core";
import grey from '@material-ui/core/colors/grey';
import SATDateTimePicker from "../../reusable/SATDateTimePicker";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  control: {
    padding:  theme.spacing(2),
  },
  buttonSelected: {
    margin:  theme.spacing(1),
    border: '2px',
    position: 'relative',
    bottom: 5
  },
  button: {
    margin:  theme.spacing(1),
    backgroundColor: grey[300],
    // color: 'white',
    position: 'relative',
    bottom: 8
  },
  typography: {
    fontSize: '150%',
  },
  switch: {
    position: 'relative',
    bottom: '7px'
  },
  quickSelect: {
    backgroundColor: grey[300],
    // color: 'white',
    margin: '1px',
    border: '2px',
  }
})

class DateTimeEntry extends React.Component {
  state = {
    spacing: '4',
    enableDelivery: false,
  }
  // shows delivery DateTimePicker
  enableDeliveryOptions = () => {
    this.setState({ enableDelivery: !this.state.enableDelivery })
  }

  render() {
    const { classes } = this.props;
    const { spacing } = this.state;

    return (

      <div>
        <Card>
          <CardContent>
            <Grid container className={classes.root} spacing={6}>
              <Grid item xs={12}>
                <Typography className={classes.typography}>Pickup Time:</Typography>
                <hr />
                <br />
                <SATDateTimePicker handleSubmit={this.props.handleTimeSubmit} type={"pickup"} startTime={moment()} offset={{unit: 'minutes', amount: 45}} buttons={true} quarterHourMode={true}></SATDateTimePicker>
                <br />
                <hr />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.demo} spacing={Number(spacing)}>

                  <Typography className={classes.typography}>Enable Deliver By Time (optional):</Typography>

                  <Switch
                    color="primary"
                    checked={this.state.enableDelivery}
                    onChange={this.enableDeliveryOptions}
                    value="checkedA"
                    className={classes.switch}
                  />
                </Grid>
              </Grid>
              <br />
              {this.state.enableDelivery ?
                <Grid item xs={12}>
                  <Typography className={classes.typography}>Delivery Time:</Typography>
                  <hr />
                  <br />
                  <SATDateTimePicker handleSubmit={this.props.handleTimeSubmit} type={"delivery"} startTime={moment()} offset={{unit: 'hours', amount:1.75}} buttons={true} quarterHourMode={true}></SATDateTimePicker>
                  <br />
                  <hr />
                </Grid>
                :
                <div></div>}
            </Grid>
            <br />
          </CardContent>
        </Card>
        <br />
      </div>
    );
  }
}

DateTimeEntry.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DateTimeEntry);
