//PDFMAKE SECTION- This package is to large to be required within AREngne so I construct the PDFPrinter Object here
//this looks dumb but is the simplest way to get the Roboto font from within pdfmake
// https://github.com/bpampuch/pdfmake/issues/1312


var PDF = require('pdfmake/build/pdfmake');
var PDF_Fonts = require('pdfmake/build/vfs_fonts');
PDF.vfs = PDF_Fonts.pdfMake.vfs;
const pdfRoboto = PDF_Fonts.pdfMake.vfs['Roboto-Regular.ttf'];
const pdfRobotoBold = PDF_Fonts.pdfMake.vfs['Roboto-Medium.ttf'];
const fonts =
{
  Roboto: {
    normal: new Buffer.from(pdfRoboto, 'base64'),
    bold: new Buffer.from(pdfRobotoBold, 'base64')
  }
};
//No official documentation on how to create a base64 buffer of the pdf server-side
//had to use this medium article: https://medium.com/@kainikhil/nodejs-how-to-generate-and-properly-serve-pdf-6835737d118e
// const PdfPrinter = new PdfMake(fonts)

// Copied this file in from Dealer Portal. Only one helper is being used now, so I commented out the rest
const helpers = {
  filterDuplicatesByProperty: (arr, property) => {
    const unique = arr
      .map(e => e[property])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e])
      .map(e => arr[e]);
    return unique;
  },
  findLaneById: (lanes, id) => {
    return lanes.find(o => o.id === id);
  },
  findLaneByLocationIds: (lanes, pickupId, deliveryId) => {
    return lanes.find(o => o.id && o.pickup && o.delivery && o.pickup.id === pickupId && o.delivery.id === deliveryId);
  },
  validateLocationByExistingLanes: (lanes, location, locationType) => {
    const correspondingLocations = helpers.filterLocationsByExistingLane(
      lanes,
      location,
      locationType
    );
    if (
      !correspondingLocations ||
      correspondingLocations === null ||
      correspondingLocations === undefined ||
      correspondingLocations.length < 1
    )
      return false;
    return true;
  },
  // filterLocationsByExistingLane: (lanes, location, locationType) => {
  //   // Show locations for either pickup or delivery that correspond to an existing lane
  //   let correspondingLocations = [];
  //   const inverseLocationType = locationType === "delivery" ? "pickup" : "delivery";
  //   if (!location || !locationType) return correspondingLocations;
  //   const existingLanes = lanes.filter(
  //     o => o[locationType].id === location.id
  //   );
  //   console.log("lanes filtered to", existingLanes);
  //   for (let lane of existingLanes) {
  //     correspondingLocations.push(lane[inverseLocationType]);
  //   }
  //   console.log(
  //     "locations filtered to",
  //     helpers.filterDuplicatesByProperty(correspondingLocations, "id")
  //   );
  //   return helpers.filterDuplicatesByProperty(correspondingLocations, "id");
  // },
  findLocationById: (locations, id) => {
    return locations.find(o => o.id === id);
  },
  findLocationByName: (locations, name) => {
    return locations.find(o => o.name === name);
  },
  // findReturnRideById: (moves, id) => {
  //   return moves.find(o => o.id == id);
  // },
  // findGroupedReturnRides: (moves, arrOfIds) => {
  //   return moves.filter(o => {
  //     if (arrOfIds.includes(o.id)) return o;
  //   });
  // },
  // findCustomerById: (customers, id) => {
  //   return customers.find(o => o.id == id);
  // },
  // sortObjArrayByName: array => {
  //   return array.sort((a, b) => {
  //     return a.name.toUpperCase() < b.name.toUpperCase()
  //       ? -1
  //       : a.name.toUpperCase() > b.name.toUpperCase()
  //         ? 1
  //         : 0;
  //   });
  // },
  // deleteArrEleByNestedObjProp: (array, arrayOfProps, value) => {
  //   let objStructure = "";
  //   // loop through array of properties (ordered highest-level to lowest) and conditionally build object path
  //   for (let prop of Array.from(arrayOfProps)) {
  //     objStructure += `.${prop}`
  //   };
  //   let newArr = array;
  //   newArr.splice(newArr.findIndex(obj => obj + objStructure === value), 1);
  //   return newArr; // Will return a new array without the deleted element
  // },
  createNewMoveURL: (obj) => {
    let paramsArray = [];
    // check for each possible query and enter its abr. name and value
    let checkProp = (abr, key) => {
      // escapes non-URL complient characters (just in case)
      var esc = encodeURIComponent
      if (obj[key] !== undefined && obj[key] !== null) {
        let param = abr + "=" + esc(obj[key])
        paramsArray.push(param)
      }
    }
    // vehicle details
    checkProp('vs', 'vehicle_stock')
    checkProp('vv', 'vehicle_vin')
    checkProp('vc', 'vehicle_color')
    checkProp('vy', 'vehicle_year')
    checkProp('vma', 'vehicle_make')
    checkProp('vmo', 'vehicle_model')
    checkProp('mf', 'manual_flag')
    // lane
    checkProp('la', 'lane_id')
    // locations
    checkProp('p', 'pickup_id')
    checkProp('p', 'destination_location_id')
    checkProp('d', 'delivery_id')
    // add query endpoint, question mark, and ampersands
    let queryURL = '/moves/add?' + (paramsArray.join('&'))
    return queryURL
  },
  PdfPrinter: PDF,
};

export default helpers;
