import 'date-fns';
import React, { Component } from "react";
import CustomerForm from './customerEntry/CustomerForm'
// import gql from "graphql-tag";
// import { Query } from "react-apollo";
import PropTypes from 'prop-types';
import { Container, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
})


class CustomerEntry extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Typography variant='h5' align='justify'>
            {this.props.location.pathname.includes('add') ? "Add" : "Edit"}  Customer
                </Typography>
          <CustomerForm
            formType={this.props.location.pathname.includes('add') ? "add" : "edit"}
            customerId={this.props.location.pathname.includes('add') ? null : this.props.match.params.id}
          >
          </CustomerForm>
        </Container>
      </div>
    )
  }
}

CustomerEntry.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomerEntry);