import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ShareIcon from '@material-ui/icons/Share';
import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { GlobalContext } from '../../../global-context';
import DotMenu from '../../reusable/DotMenu';
import { withRouter } from 'react-router';
import LocationCreationForm from './LocationCreationForm';
import { Dialog } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { CSVLink } from 'react-csv';

// var _ = require("lodash");

// let log = false;

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'address', numeric: false, disablePadding: false, label: 'Address' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'phone', numeric: true, disablePadding: false, label: 'Phone' },
  { id: 'customer.name', numeric: false, disablePadding: false, label: 'Customer' },
  { numeric: false, disablePadding: false, label: '' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding='checkbox'>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              color='primary'
            />
          </TableCell>
          {rows.map(row => (
            <TableCell
              key={row.id}
              align='left'
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <Tooltip title='Sort' placement={row.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={row.id ? this.createSortHandler(row.id) : null}
                >
                  {row.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  spacer: {
    flex: '1 1 1%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  actionBtn: {
    // display: "inline-block",
    // float: "right"
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#3f51b5',
    color: 'white',
    position: 'relative',
    bottom: 8,
  },
});

class EnhancedTableToolbar extends React.Component {
  state = {
    formOpen: false,
  };

  handleCreateLocationForm = () => this.setState(prevState => ({ formOpen: !prevState.edit }));

  render() {
    const { numSelected, classes, locations, selected } = this.props;

    return (
      <Toolbar
        className={classNames(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <div className={classes.title}>
          {numSelected > 0 ? (
            <Typography color='inherit' variant='subtitle1'>
              {numSelected} selected
            </Typography>
          ) : (
            <Typography variant='h6' id='tableTitle'>
              Locations
              {/* <Button
                  style={{ marginLeft: "30px" }}
                  color="primary"
                  variant="contained"
                  onClick={this.handleCreateLocationForm}
                >
                  Create New
              </Button> */}
            </Typography>
          )}
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {numSelected > 0 ? (
            <React.Fragment>
              <CSVLink data={locations.filter(o => selected.indexOf(o.id) > -1)}>Export Selection as CSV</CSVLink>
              <Tooltip title='Get a shareable URL for the selected lane(s)'>
                <IconButton className={classes.actionBtn} aria-label='Share'>
                  <ShareIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Edit the selected lane'>
                <IconButton className={classes.actionBtn} aria-label='Edit' disabled={numSelected > 1}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          ) : (
            <Tooltip title='Clear Filter'>
              <IconButton aria-label='Clear Filter' onClick={this.props.clearFilter}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>

        <Dialog open={this.state.formOpen}>
          <LocationCreationForm closeForm={() => this.setState({ formOpen: false })} />
        </Dialog>
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  table: {
    minWidth: 400,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  image: {
    paddingTop: '3px',
    paddingBottom: '3px',
  },
  tableRow: {
    transition: 'all .2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.005)',
    },
  },
});

class LocationsTableView extends React.Component {
  state = {
    order: 'desc',
    orderBy: 'id',
    selected: [],
    // data: [],
    page: 0,
    rowsPerPage: 100,
    search: '',
  };

  componentWillMount = () => {
    this.props.subscribeToLocations();
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: this.props.locations.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  clearFilter = () => {
    this.setState({ order: 'desc', orderBy: 'id' });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  openDetails = id => {
    let path = `/locations/${id}`;
    this.props.history.push(path);
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleInputChange = event => this.setState({ search: event.target.value });

  handleFilteredLocations = (locations, search) => {
    if (!search || search.length < 1) return locations;
    else
      return locations.filter(
        o =>
          (o.name && o.name.toLocaleLowerCase().includes(search)) ||
          (o.nickname && o.nickname.toLocaleLowerCase().includes(search))
      );
  };

  render() {
    const locations = this.handleFilteredLocations(this.props.locations, this.state.search);
    const { classes } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, locations.length - page * rowsPerPage);

    return (
      <div className={classes.paper}>
        <EnhancedTableToolbar
          selectedID={this.state.selected[0]}
          numSelected={selected.length}
          clearFilter={this.clearFilter}
          locations={locations}
          selected={selected}
        />
        <TextField
          id='outlined-name'
          label='Search Locations'
          style={{
            marginLeft: '20px',
            marginRight: '20px',
            width: '500px',
          }}
          value={this.state.search}
          onChange={this.handleInputChange}
          margin='normal'
          variant='outlined'
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby='tableTitle'>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={locations.length}
            />
            <TableBody>
              {stableSort(locations, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  const isSelected = this.isSelected(row.id);
                  const laneActions = [
                    {
                      label: 'See location details',
                      function: () =>
                        this.props.history.push({
                          pathname: `/locations/${row.id}`,
                          state: { lane: row },
                        }),
                    },
                    {
                      label: 'Get shareable URL',
                      function: () => console.log('generate URL and save to clipboard'),
                    },
                  ];
                  return (
                    <TableRow
                      hover
                      className={classes.tableRow}
                      role='checkbox'
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isSelected}
                    >
                      <TableCell padding='checkbox'>
                        <Checkbox
                          color='primary'
                          checked={isSelected}
                          onClick={event => this.handleClick(event, row.id)}
                        />
                      </TableCell>
                      <TableCell align='left' padding='none' onClick={() => this.openDetails(row.id)}>
                        {row.name}
                      </TableCell>
                      <TableCell align='left' padding='none' onClick={() => this.openDetails(row.id)}>
                        {row.address}
                      </TableCell>
                      <TableCell align='left' padding='none' onClick={() => this.openDetails(row.id)}>
                        {row.email}
                      </TableCell>
                      <TableCell align='left' padding='none' onClick={() => this.openDetails(row.id)}>
                        {row.phone}
                      </TableCell>
                      <TableCell align='left' padding='none' onClick={() => this.openDetails(row.id)}>
                        {row.customer ? row.customer.name : ''}
                      </TableCell>
                      <TableCell align='left' padding='none'>
                        {' '}
                        <DotMenu actions={laneActions} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component='div'
          count={locations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

LocationsTableView.contextType = GlobalContext;

LocationsTableView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(LocationsTableView));
