import axios from 'axios';
import moment from 'moment';
import gql from "graphql-tag";
import * as Sentry from "@sentry/react";

let log = false;

const tookanBaseURL = `https://${process.env.REACT_APP_TKN_SD}.socialautotransport.com/V2`

const Tookan = {

  getDrivers: async function (tookanAPIKey, regions) {
    let drivers = []
    const driverLoad = await regions.map(async region => {
      if (log) console.log("fetching drivers for team #", region.team_id)
      await axios({
        method: "post",
        url: `${tookanBaseURL}/get_available_agents`,
        data: {
          api_key: tookanAPIKey,
          team_id: region.team_id
        }
      }).then(response => {
        if (response.data.message === "INVALID_API_KEY") {
          console.log('Tookan.getDrivers Invalid Tookan API key!', response)
          return
        }
        if (log) { console.log('Tookan.getDrivers success response: ', response) }
        if (log) console.log("drivers found", response.data.data)
        if (response.data.data.length > 0) drivers = drivers.concat(response.data.data)
        localStorage.setItem('drivers', JSON.stringify(drivers))
      }).catch(error => {
        console.log(`Tookan.getDrivers Failed fatching driver list from Tookan API. Attempting to load from local storage instead...`, error)
      })
    })

    return Promise.all(driverLoad).then(res => {
      if (log) console.log("get drivers Promise.all.then", res)
      if (drivers == null || drivers.length < 1) {
        if (log) console.log("loading drivers from localStorage", drivers)
        if (localStorage.hasOwnProperty("drivers")) {
          const driversJSON = localStorage.getItem("drivers")
          if (log) console.log(`driversJSON from local storage: `, driversJSON)
          drivers = JSON.parse(driversJSON)
          if (log) console.log(`Loaded ${drivers.length} drivers from local storage`, drivers)
        }
      }
      if (log) console.log("get drivers finished with:", drivers)
      return drivers
    })


  },

  saveMoveToTookan: async function (tookanAPIKey, client, moveObj, move_id) {
    await axios({
      method: "post",
      url: `${tookanBaseURL}/create_multiple_tasks`,
      data: Object.assign({}, moveObj, { api_key: tookanAPIKey }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(res => {
        if (log) console.log("saveMoveToTookan => res:", res)
        if (res.status == 200 && res.data.status == 200) {
          if (log) console.log("Tookan call successful");
          Tookan.updateMoveWithTookanIDs(client, move_id, res);
        }
      })
      .catch(err => {
        console.log(err)
      })
  },

  updateMoveInTookan: async function (tookanAPIKey, client, moveObj, move_id) {
    if (log) console.log("updateMoveInTookan with moveObj, move_id:", moveObj, move_id)
    await axios({
      method: "post",
      url: `${tookanBaseURL}/edit_multiple_tasks`,
      data: Object.assign({}, moveObj, { api_key: tookanAPIKey }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(res => {
        if (log) console.log("updateMoveInTookan => res:", res)
        if (res.status == 200 && res.data.status == 200) {
          if (log) console.log("Tookan call successful");
          Tookan.handleTookanSyncTimestamp(client, move_id);
        }
      })
      .catch(err => {
        console.log(err)
      })
  },

  cancelMoveInTookan: async function (tookanAPIKey, client, job_id, move_id) {
    if (log) console.log("cancelMoveInTookan with job_id, move_id:", job_id, move_id)
    await axios({
      method: "post",
      url: `${tookanBaseURL}/cancel_task`,
      data: { api_key: tookanAPIKey, job_id: job_id, job_status: 9 },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(res => {
        if (log) console.log("cancelMoveInTookan => res:", res)
        if (res.status == 200 && res.data.status == 200) {
          if (log) console.log("Tookan call successful");
          Tookan.handleTookanSyncTimestamp(client, move_id);
        }
      })
      .catch(err => {
        console.log(err)
      })
  },

  handleAutoAssignment: move => {
    if (move.auto_assign < 1) {
      return false;
    } else if (
      move.class.toLowerCase().trim() === "stranded" &&
      move.sequence < 2
    ) {
      return true;
    } else {
      return false;
    }
  },

  updateDriverInTookan: async function (tookanAPIKey, client, job_id, move_id, driver_id) {
    if (log) console.log("updateDriverInTookan with job_id, move_id, driver_id:", job_id, move_id, driver_id)
    await axios({
      method: "post",
      url: `${tookanBaseURL}/assign_task`,
      data: { api_key: tookanAPIKey, job_id: job_id, fleet_id: driver_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(res => {
        if (log) console.log("updateDriverInTookan => res:", res)
        if (res.status == 200 && res.data.status == 200) {
          if (log) console.log("Tookan call successful");
          Tookan.handleTookanSyncTimestamp(client, move_id);
        }
      })
      .catch(err => {
        console.log(err)
      })
  },
  // Function to take in an array of moves and perform the necessary Tookan operation (create/update/delete)
  handleTookanMoves: async function (tookanAPIKey, client, moves) {
    // Loop through each move
    moves.forEach(async move => {
      if (log) console.log("Parsing move #", move.id, "move obj:", move);
      if (log) console.log("   synced_with_tookan:", move.synced_with_tookan, ", updatedat:", move.updatedat, move.synced_with_tookan === move.updatedat)
      if (log) console.log("   tookan_relationship_id:", move.tookan_relationship_id, parseInt(move.tookan_relationship_id) > 0);
      if (log) console.log("   pickup_stop_id:", move.pickup_stop_id, parseInt(move.pickup_stop_id) > 0);
      if (log) console.log("   delivery_stop_id:", move.delivery_stop_id, parseInt(move.delivery_stop_id) > 0);
      if (move.suggested) return; // Ignore any move that is a suggested move
      if (move.move_type === 'ride' && move.parent_move) {
        if (move.sequence > move.parent_move.sequence) return // Ignore ride moves that take place after the concierge move
        else {
          // Create a pickup-only task in Tookan to reflect the Lyft-first move
          let rideUrl = await axios({
            url: `/.netlify/functions/handleLyftURL`,
            method: 'post',
            data: { id: move.parent_move.id, urlOnly: true }
          })
          let
            pickuptime = new Date(move.pickup_time).toISOString(),
            pickupDetails = {
              address: move.lane.pickup.address,
              time: moment(pickuptime)
                .tz("America/New_York")
                .format("YYYY-MM-DD HH:mm:ss"),
              phone: move.lane.pickup.phone,
              template_name: 'LyftPickup',
              template_data: [
                {
                  label: "Call_Lyft",
                  data: rideUrl ? rideUrl.data : "Call Dispatch at 804-913-7674 for your ride info, or text at 804-487-3935.",
                },
              ],
              name: `${move.lane.customer.name} - ${move.lane.pickup.name}`,
              email: move.lane.pickup.email,
              latitude: move.lane.pickup.latitude,
              longitude: move.lane.pickup.longitude,
              order_id: `Plan #${move.plan_id} - Move #${move.id}`,
              job_description: move.move_details,
            };
          // delete the coordinates from the move object if they are null or do not exist
          if (!pickupDetails.latitude) delete pickupDetails.latitude
          if (!pickupDetails.longitude) delete pickupDetails.longitude
          let moveRequestData = {
            fleet_id: move.driver_id,  // Fleet ID is equivalent to driver ID, can be set to specific driver
            timezone: "+300", // This needs to be dynamic as we expand into new timezones
            has_pickup: 1,
            has_delivery: 0,
            layout_type: 0,
            geofence: 0,
            auto_assignment: Tookan.handleAutoAssignment(move),
            pickups: [pickupDetails],
          };
          if (
            // Pickup task for Lyft already exists
            move.tookan_relationship_id &&
            parseInt(move.tookan_relationship_id) > 0 &&
            move.pickup_stop_id &&
            parseInt(move.pickup_stop_id) > 0
          ) {
            if (move.active !== 1 || ['canceled', 'cancelled'].indexOf(move.status) >= 0) {
              // set the pickup task in Tookan to 'canceled' if the Lyft has been canceled
              Tookan.cancelMoveInTookan(tookanAPIKey, client, move.pickup_stop_id, move.id)
            } else {
              if (move.synced_with_tookan === move.updatedat) return; // Ignore if in sync with Tookan
              // Else update the pickup task with new data
              moveRequestData = {
                pickups: [Object.assign(pickupDetails, { job_id: move.pickup_stop_id })],
              };
              await Tookan.updateMoveInTookan(tookanAPIKey, client, moveRequestData, move.id)
              // Check for previous driver in local storage
              let previousDriverId = localStorage.getItem(`${move.id}-driver_id`);
              // Update pickup task with new driver if move has changed plans
              if (previousDriverId && previousDriverId !== move.driver_id) {
                await Tookan.updateDriverInTookan(tookanAPIKey, client, move.pickup_stop_id, move.id, move.driver_id)
              }
            }
          }
          // Otherwise, create a new pickup task
          else Tookan.saveMoveToTookan(tookanAPIKey, client, moveRequestData, move.id)
        }
      }
      // When not a ride move:
      else {
        // Initializing parameters for Tookan obj structure -------------------------------
        let
          moveRequestData,
          deliverytime = new Date(move.delivery_time).toISOString(),
          pickuptime = new Date(move.pickup_time).toISOString(),
          pickupDetails = {
            address: move.lane.pickup.address,
            time: moment(pickuptime)
              .tz("America/New_York")
              .format("YYYY-MM-DD HH:mm:ss"),
            phone: move.lane.pickup.phone,
            template_name: this.handleTemplateName(move, 'pickup'),
            template_data: [
              {
                label: "Stock_Number",
                data: move.vehicle_stock,
              },
              {
                label: "Year",
                data: move.vehicle_year,
              },
              {
                label: "Make",
                data: move.vehicle_make,
              },
              {
                label: "Model",
                data: move.vehicle_model,
              },
              {
                label: "Color",
                data: move.vehicle_color,
              },
              {
                label: "Odometer",
                data: move.vehicle_odometer,
              },
              {
                label: "VIN",
                data: move.vehicle_vin,
              },
              {
                label: "Location_Details",
                data: `https://${(process.env.REACT_APP_A0_CB_SD === 'admin') ? `driver` : `driver-test`}.hopdrive.io/locations/${move.lane.pickup.id}`,
              },
              {
                label: "DrivePay",
                data: this.handleDriverPayAmt(move),
              },
              {
                label: "RidePay",
                data: move.moveByReturnRideId ? this.handleDriverPayAmt(move.moveByReturnRideId) : '',
              },
              {
                label: "Consumer_Name",
                data: move.consumer_name ? move.consumer_name : (move.tags && move.tags.includes('loaner') ? (move.parent_move && move.parent_move.consumer_name ? move.parent_move.consumer_name : '') : ''),
              },
              {
                label: "Consumer_Phone",
                data: move.consumer_phone ? move.consumer_phone : (move.tags && move.tags.includes('loaner') ? (move.parent_move && move.parent_move.consumer_phone ? move.parent_move.consumer_phone : '') : ''),
              },
              {
                label: "Move_Type",
                data: move.consumer_type && move.consumer_type === 'loaner' ? "Loaner Pickup" : (move.consumer_type && move.consumer_type === 'customer' ? "Consumer Pickup" : "Standard Pickup"),
                // Previously looking at 'consumer_pickup' field but that is currently always set on the 2nd move in a C+L sequence.
                // This will set the tag based on the actual vehicle type specified by the dealer.
              },
              {
                label: "Dealer_Contact",
                data: move.dealer_contact ? move.dealer_contact : '',
              },
            ],
            ref_images: [move.vehicle_image],
            name: `${move.lane.customer.name} - ${move.lane.pickup.name}`,
            email: move.lane.pickup.email,
            latitude: move.lane.pickup.latitude,
            longitude: move.lane.pickup.longitude,
            order_id: `Plan #${move.plan_id} - Move #${move.id}`,
            job_description: move.move_details,
          },
          deliveryDetails = {
            address: move.lane.delivery.address,
            time: moment(deliverytime)
              .tz("America/New_York")
              .format("YYYY-MM-DD HH:mm:ss"),
            phone: move.lane.delivery.phone,
            template_name: this.handleTemplateName(move, "delivery"),
            template_data: [
              {
                label: "Location_Details",
                data: `https://${(process.env.REACT_APP_A0_CB_SD === 'admin') ? `driver` : `driver-test`}.hopdrive.io/locations/${move.lane.delivery.id}`,
              },
              {
                label: "Consumer_Name",
                data: move.consumer_name ? move.consumer_name : '',
              },
              {
                label: "Consumer_Phone",
                data: move.consumer_phone ? move.consumer_phone : '',
              },
              {
                label: "Move_Type",
                data: move.consumer_type && move.consumer_type === 'loaner' ? "Loaner Delivery" : (move.consumer_type && move.consumer_type === 'customer' ? "Consumer Delivery" : "Standard Delivery"),
                // Previously looking at 'consumer_pickup' field but that is currently always set on the 2nd move in a C+L sequence.
                // This will set the tag based on the actual vehicle type specified by the dealer.
              },
              {
                label: "Dealer_Contact",
                data: move.dealer_contact ? move.dealer_contact : '',
              },
            ],
            name: `${move.lane.customer.name} - ${move.lane.delivery.name}`,
            email: move.lane.delivery.email,
            order_id: `Plan #${move.plan_id} - Move #${move.id}`,
            latitude: move.lane.delivery.latitude,
            longitude: move.lane.delivery.longitude,
            job_description: move.move_details,
          };
        // --------------------------------------------------------------------------------
        // delete the coordinates from the move object if they are null or do not exist
        if (!pickupDetails.latitude) delete pickupDetails.latitude
        if (!pickupDetails.longitude) delete pickupDetails.longitude
        if (!deliveryDetails.latitude) delete deliveryDetails.latitude
        if (!deliveryDetails.longitude) delete deliveryDetails.longitude
        // Conditions for an existing move to be updated
        if (
          move.tookan_relationship_id &&
          parseInt(move.tookan_relationship_id) > 0 &&
          move.pickup_stop_id &&
          parseInt(move.pickup_stop_id) > 0 &&
          move.delivery_stop_id &&
          parseInt(move.delivery_stop_id) > 0
        ) {
          if (move.active !== 1) { // ignore active moves
            // set both the pickup and delivery tasks in Tookan to 'canceled'
            Tookan.cancelMoveInTookan(tookanAPIKey, client, move.pickup_stop_id, move.id)
            Tookan.cancelMoveInTookan(tookanAPIKey, client, move.delivery_stop_id, move.id)
          } else {
            // Will need to check against a new timestamped field in the moves db to see if it is out of sync wih Tookan
            if (move.synced_with_tookan === move.updatedat) return;
            // update the pickup and delivery tasks of an existing move with the submitted move
            moveRequestData = {
              pickups: [Object.assign(pickupDetails, { job_id: move.pickup_stop_id })],
              deliveries: [Object.assign(deliveryDetails, { job_id: move.delivery_stop_id })],
            };
            await Tookan.updateMoveInTookan(tookanAPIKey, client, moveRequestData, move.id)
            // Check for previous driver in local storage
            let previousDriverId = localStorage.getItem(`${move.id}-driver_id`);
            // Update pickup and delivery tasks with new driver if move has changed plans
            if (previousDriverId && previousDriverId !== move.driver_id) {
              await Tookan.updateDriverInTookan(tookanAPIKey, client, move.pickup_stop_id, move.id, move.driver_id)
              Tookan.updateDriverInTookan(tookanAPIKey, client, move.delivery_stop_id, move.id, move.driver_id)
            }
          }

        } else if (move.active === 1) {
          // create a pickup and delivery task in Tookan from the submitted move
          moveRequestData = {
            fleet_id: move.driver_id,  // Fleet ID is equivalent to driver ID, can be set to specific driver
            timezone: "+300", // This needs to be dynamic as we expand into new timezones
            has_pickup: 1,
            has_delivery: 1,
            layout_type: 0,
            geofence: 0,
            auto_assignment: Tookan.handleAutoAssignment(move),
            pickups: [pickupDetails],
            deliveries: [deliveryDetails],
          };
          Tookan.saveMoveToTookan(tookanAPIKey, client, moveRequestData, move.id)
        }
      }
    }); // closes out moves.forEach()
  },

  // Function to update a move entry in Hasura with the timestamp for when it was synced to Tookan
  handleTookanSyncTimestamp: async function (client, id) {
    // GQL update statement
    const UPDATE_TIMESTAMPS_QUERY = gql`
    mutation updateTimestamps(
      $id: bigint!
      ){
        update_moves(where: {id: {_eq: $id}}, _set: { synced_with_tookan: "now()", updatedat: "now()"}) {
          returning {
            id
            synced_with_tookan
            updatedat
          }
        }
      }
      `;
    await client.mutate({
      mutation: UPDATE_TIMESTAMPS_QUERY,
      variables: { id: id }
    })
      .then(res => {
        if (log) console.log(res)
        if(res.errors){
          Sentry.captureException(res.errors[0])
        }
      })
      .catch(err => {
        console.log(err)
        Sentry.captureException(err)
      })
    return null
  },

  updateMoveWithTookanIDs: async function (client, move_id, tookan_res) {
    // GQL update statement
    const UPDATE_MOVE_QUERY = gql`
      mutation updateTimestamps(
        $id: bigint!
        $tookan_relationship_id: String!
        $pickup_stop_id: String!
        $delivery_stop_id: String
          ){
            update_moves(where: {id: {_eq: $id}}, _set: {
              status: "dispatched",
              synced_with_tookan: "now()"
              updatedat: "now()"
              tookan_relationship_id: $tookan_relationship_id
              pickup_stop_id: $pickup_stop_id
              delivery_stop_id: $delivery_stop_id
            }) {
              returning {
                id
                synced_with_tookan
                updatedat
                tookan_relationship_id
                pickup_stop_id
                delivery_stop_id
              }
            }
          }
          `,
      // Parameters to update
      UPDATE_MOVE_VARIABLES = {
        id: move_id,
        tookan_relationship_id: tookan_res.data.data.pickups[0].job_token.toString(),
        pickup_stop_id: tookan_res.data.data.pickups[0].job_id.toString(),
        delivery_stop_id: tookan_res.data.data.deliveries ? tookan_res.data.data.deliveries[0].job_id.toString() : null,
      };
    await client.mutate({
      mutation: UPDATE_MOVE_QUERY,
      variables: UPDATE_MOVE_VARIABLES
    })
      .then(res => {
        if (log) console.log(res)
        if(res.errors){
          Sentry.captureException(res.errors[0])
        }
      })
      .catch(err => {
        console.log(err)
        Sentry.captureException(err)
      })
  },

  handleMoveUnplan: async function (tookanAPIKey, client, move) {
    if (log) console.log("handleMoveUnplan with move:", move)
    // GQL update statement
    const UPDATE_MOVE_QUERY = gql`
    mutation updateUnplannedMove(
      $id: bigint!
      ){
        update_moves(where: {id: {_eq: $id}}, _set: {
          synced_with_tookan: "now()"
          updatedat: "now()"
          tookan_relationship_id: null
          pickup_stop_id: null
          delivery_stop_id: null
          driver_id: null
          driver_name: null
        }) {
          returning {
            id
            synced_with_tookan
            updatedat
            tookan_relationship_id
            pickup_stop_id
            delivery_stop_id
            driver_id
            driver_name
          }
        }
      }
      `;
    // Set the statuses of the pickup and stop tasks in Tookan to 'canceled'
    Tookan.cancelMoveInTookan(tookanAPIKey, client, move.pickup_stop_id, move.id);
    if (move.delivery_stop_id) Tookan.cancelMoveInTookan(tookanAPIKey, client, move.delivery_stop_id, move.id);
    // Update the move entry in Hasura and delete the related Tookan ID fields
    await client.mutate({
      mutation: UPDATE_MOVE_QUERY,
      variables: { id: move.id }
    })
      .then(res => {
        if (log) console.log(res)
        if(res.errors){
          Sentry.captureException(res.errors[0])
        }
      })
      .catch(err => {
        console.log(err)
        Sentry.captureException(err)
      })
  },

  handleDriverPayAmt: function (move) {
    try {
      return `$${(move.appayments.find(o => o.type === 'move pay').amount).toFixed(2)}`
    } catch (err) {
      return ''
    }
  },

  handleTemplateName: function (move, task_type) {
    let name;
    if (task_type === 'pickup' || task_type === 'Pickup') {
      name = "Pickup"
      try {
        // First check if there is a specific template for the customer
        name = move && move.lane && move.lane.customer && move.lane.customer.pickup_template_name ? move.lane.customer.pickup_template_name : "Pickup"
      } catch (err) {
        // Default to the current template if there is a malformed object
        return name;
      }
    }
    if (task_type === 'delivery' || task_type === 'Delivery') {
      name = "Delivery"
      try {
        // First check if there is a specific template for the customer
        name = move && move.lane && move.lane.customer && move.lane.customer.delivery_template_name ? move.lane.customer.delivery_template_name : "Delivery"
      } catch (err) {
        // Default to the current template if there is a malformed object
        return name;
      }
    }
    return name;
  },

}

export default Tookan;
