import React, { useContext, useState } from 'react';
import { makeStyles, Container, Typography, Button, Icon, Chip } from '@material-ui/core';
import Loading from '../utils/Loading';
import { Query } from 'react-apollo';
import { GlobalContext } from '../../global-context';
import gql from 'graphql-tag';
import { AccordianTable, AccordianRow, TableSort } from '../reusable/AccordianTable';
import fragments from '../utils/graphQL/fragments';
import CustomerForm from './customerDetails/CustomerForm';

////////// TOOLS //////////
const defaultOrder = `desc`;
const defaultOrderBy = `id`;

////////// COMPONENT //////////
export default function CustomerIndex(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [expandedRowId, setExpandedRowId] = useState(0);

  const [modalRender, setModalRender] = useState(false);

  const applyFilters = rows => {
    if (!search || search.length < 1) return rows;
    else {
      return rows.filter(
        row =>
          (row.name && row.name.toLocaleLowerCase().includes(search)) ||
          (row.id && (row.id + ``).toLocaleLowerCase().includes(search))
      );
    }
  };

  const buildContextMenuActions = customer => {
    return [
      {
        name: `customer-details`,
        label: `View\xa0Customer\xa0Details`,
        id: customer.id,
        handler: handleContextMenuClick,
      },
    ];
  };

  const handleContextMenuClick = async (e, data, target) => {
    if (data.action.name === `customer-details`) {
      props.history.push(`/customers/${data.action.id}`);
      return;
    }
  };

  return (
    <div className={cls.root}>
      {ctx && ctx.userIsAuthenticated() && (
        <Query
          query={gql`
            query {
              customers {
                ...Customer
              }
            }
            ${fragments.customer}
          `}
          //Was causing infinited reload on error
          // onError={err => { console.error(err); ctx.handleNotifications(true, "error", "Query failed to retrieve customer records") }}
        >
          {({ loading, error, data, refetch }) => {
            if (loading) return <Loading />;
            if (error) {
              console.log(`Error getting customer records:`, error);
              return (
                <div className={cls.rootTable}>
                  <Container maxWidth='sm'>
                    <div className={cls.notFound}>
                      <Typography className={cls.notFoundTxt}>ERROR GETTING CUSTOMER RECORDS</Typography>
                    </div>
                  </Container>
                </div>
              );
            }
            if (data) {
              const filteredData = applyFilters(data.customers);
              const headers = [
                { id: `id`, alignLeft: true, numeric: true, label: `ID` },
                { id: `name`, alignLeft: true, numeric: false, label: `Customer\xa0Name` },
                { id: `phone`, alignLeft: true, numeric: true, label: `Phone\xa0#` },
                { id: `balance`, alignLeft: true, numeric: true, label: `Balance` },
                { id: `payment_terms`, alignLeft: true, numeric: true, label: `Payment\xa0Terms` },
                { id: `active`, alignLeft: false, numeric: false, label: `Status` },
              ];
              const rows = filteredData.map(customer => {
                return {
                  id: customer.id,
                  name: customer.name || `N/A`,
                  phone: customer.phone || `N/A`,
                  balance: 'Coming soon',
                  payment_terms: customer.payment_terms,
                  active: (
                    <Chip
                      variant={customer.active ? 'default' : 'outlined'}
                      icon={customer.active ? null : <Icon fontSize='small'>close</Icon>}
                      size='small'
                      color={customer.active ? 'primary' : 'secondary'}
                      label={customer.active ? 'active' : 'inactive'}
                    />
                  ),
                };
              });
              return (
                <>
                  {modalRender && (
                    <CustomerForm modalRender={modalRender} setModalRender={setModalRender} refetch={refetch} />
                  )}
                  <div className={cls.rootTable}>
                    <Container className={cls.titleBtn} maxWidth='lg'>
                      <Button
                        onClick={() => setModalRender(true)}
                        variant='contained'
                        color='primary'
                        className={cls.button}
                      >
                        {' '}
                        Add Customer{' '}
                      </Button>
                    </Container>
                    {data.customers && data.customers.length > 0 ? (
                      <>
                        <Container className={cls.rootTable} maxWidth='lg'>
                          <AccordianTable
                            title={`${data.customers.length} Customers`}
                            size={`small`}
                            headers={headers}
                            rows={rows}
                            actions={[]}
                            search={search}
                            order={order}
                            orderBy={orderBy}
                            tablePage={tablePage}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            setSearch={setSearch}
                            setOrder={setOrder}
                            setOrderBy={setOrderBy}
                            setTablePage={setTablePage}
                            setRowsPerPage={setRowsPerPage}
                            setExpandedRowId={setExpandedRowId}
                            refetch={refetch}
                            refreshPersistAs='customers'
                          >
                            {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                              .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                              .map(row => (
                                <AccordianRow
                                  onClick={() => props.history.push(`/customers/${row.id}`)}
                                  key={`customer-${row.id}-row`}
                                  rowId={row.id}
                                  expandedRowId={expandedRowId}
                                  setExpandedRowId={setExpandedRowId}
                                  columns={[
                                    { align: 'left', value: row.id },
                                    { align: 'left', value: row.name },
                                    { align: 'left', value: row.phone },
                                    { align: 'left', value: row.balance },
                                    { align: 'left', value: row.payment_terms },
                                    { align: 'right', value: row.active },
                                  ]}
                                  actions={buildContextMenuActions(row)}
                                  // className={expandedRowId === row.id ? cls.rowActive : cls.row}
                                  className={cls.row}
                                />
                              ))}
                          </AccordianTable>
                        </Container>
                      </>
                    ) : (
                      <Container maxWidth='sm'>
                        <div className={cls.notFound}>
                          <Typography className={cls.notFoundTxt}>NO CUSTOMER RECORDS FOUND</Typography>
                        </div>
                      </Container>
                    )}
                  </div>
                </>
              );
            } else {
              return (
                <>
                  <div className={cls.rootTable}>
                    <Container maxWidth='sm'>
                      <div className={cls.notFound}>
                        <Typography className={cls.notFoundTxt}>NO CUSTOMER RECORDS FOUND</Typography>
                      </div>
                    </Container>
                  </div>
                </>
              );
            }
          }}
        </Query>
      )}
    </div>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  rootTable: {
    verticalAlign: 'top',
    position: 'relative',
    width: '100%',
  },
  titleBtn: {
    width: '100%',
    textAlign: 'right',
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(2),
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  row: {
    height: 45,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    color: theme.palette.text.primary,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowActive: {
    height: 45,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: theme.palette.primary.main,
    color: '#fff',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  button: {
    boxShadow: `none`,
    '&:hover, &:active': {
      boxShadow: `none`,
    },
  },
}));
