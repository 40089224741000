import React, { useState, useContext, useEffect, Component } from 'react';
import { GlobalContext } from '../../../global-context';

import { makeStyles, Icon, Tooltip, Typography } from '@material-ui/core';

import sdk from "@hopdrive/sdk";
import axios from "axios";
import gql from "graphql-tag";
import { Query } from 'react-apollo';
import * as Sentry from "@sentry/react";

import Loading from '../../utils/Loading';

import fragments from '../../utils/graphQL/fragments';
import CustomerSelect from "../../reusable/CustomerSelect";
import LocationSelect from './LocationSelect';
import CalculatorOutput from "./CalculatorOutput";

const log = false;

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    border: '1px solid #ddd',
    borderRadius: '8px',
    background: '#fff',
  },
  lane: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  laneL: {
    width: "100%",
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginRight: "0px",
      marginTop: theme.spacing(2)
    },
  },
  laneR: {
    width: "100%",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: "0px",
      marginTop: theme.spacing(2)
    },
  },
  spacer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: theme.spacing(2),
    minHeight: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '48px',
    minHeight: '48px',
  },
  swapIcon: {
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.text.primary,
    },
    transition: '0.2s',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      transform: 'rotate(90deg)',
    },
  },
  favIcon: {
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.error.main,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  favIconActive: {
    color: theme.palette.error.main,
    "&:hover": {
      color: theme.palette.error.light,
    },
    transition: '0.2s',
    cursor: 'pointer',
  }
}));

////////// COMPONENT //////////
export default function CalculatorForm(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  // CUSTOMER DATA
  const { onLaneChange, getLaneByLocationIds, validation } = props;
  const [ customer, setCustomer ] = useState(1);

  const moveCount = 1;
  const [lane, setLane] = useState([]);
  const [pickup, setPickup] = useState(null);
  const [delivery, setDelivery] = useState(null);

  const [ rateRules, setRateRules ] = useState([]);
  const [ payRateGroups, setPayRateGroups ] = useState([]);

  useEffect(() => {
    handleLane();
  }, [pickup, delivery, customer]);

  // GET RATES / SET DISTANCE & PAY RATES
  useEffect(() => {
    log && console.log("LANE", lane)
    if (lane && customer && pickup && delivery) {
      getRates(ctx.apolloClient, pickup, delivery, customer, pickup.region_id);
    }  
  }, [lane]);

  const getRates = async (client, origin, destination, custId, regionId) => {
    const res = await client.query({
      query: GET_LANES_AND_RATES,
      variables: { pickup_id: origin.id, delivery_id: destination.id, customer_id: custId, region_id: regionId },
      fetchPolicy: "network-only" //bypasses cache and checks db directly     
    })
    console.log("RES", res)
    if (res.data.raterules) {
      console.log('RATE_RULES', res.data.raterules)
      setRateRules(res.data.raterules)
    } else {
      //handle error
    }

    if (res.data.payrategroups) {
      console.log('PAY_RATE_RULES', res.data.payrategroups)
      setPayRateGroups(res.data.payrategroups)
    } else {
      //handle error
    }
  }

  const handleLane = async () => {
    if (pickup && delivery) {
      if (pickup.id && delivery.id ) {
        if (pickup.id === delivery.id || pickup.address === delivery.address) {
          log && console.log('Duplicate locations')
          if (onLaneChange) onLaneChange({ pickup: null, delivery: null });
          setLane(null);

        } else {
          const foundLane = await getLaneByLocationIds(pickup.id, delivery.id);
          log && console.log(`Setting new .. (Full Lane):`, foundLane);
          if (!foundLane) {
            // if (onLaneChange) onLaneChange({ pickup: pickup, delivery: delivery });
            noLane();
          } else {
            if (onLaneChange) onLaneChange(foundLane);
            setLane(foundLane);
          }
        }
      } else {
        //  Working with Google locations built without IDs
          if (pickup.address === delivery.address) {
            log && console.log('Duplicate locations')
            if (onLaneChange) onLaneChange({ pickup: null, delivery: null });
            setLane(null);

          } else {
            console.log(pickup, delivery);
            noLane();
          }
      }
    } else if (pickup || delivery) {
        if (onLaneChange) onLaneChange({ pickup: pickup, delivery: delivery });
    } else {
      // if (onLaneChange) onLaneChange({ pickup: null, delivery: null });
      setLane(null);
    }
  }

  const noLane = () => {
    log && console.log("...existing lane not found...creating lane...");
    axios({
      method: "POST",
      url: "/.netlify/functions/build-lanes",
      data: {
        type: "fromAddresses",
        customer_id: customer,
        pickup: { name: pickup.name.trim(), address: pickup.address },
        delivery: { name: delivery.name.trim(), address: delivery.address }
      },
    }).then((res) => {
      log && console.log("No Lane Response:", res);
      if (res) {
        setLane(res.data[0]);
      }
    });
  };

  const handlePickup = pickup => {
    setPickup(pickup);
  };

  const handleDelivery = delivery => {
    setDelivery(delivery);
  };

  function handleCustomerChange(event) {
    const { value } = event.target;
    setCustomer(value); setPickup(null); setDelivery(null);
  };


  return (<>
    <Query query={GET_LANES} variables={{ customerId: customer }}>
      {({ loading, error, data, refetch }) => {
        if (loading) return <Loading /> 
        if (error) {
          log && console.log(`Failed to retrieve lanes:`, error);
          ctx.handleNotifications(true, `error`, `Query failed to retrieve lanes: ` + error.toString());
        }
        if (data && data.lanes) {
          let lanes = data.lanes.length > 0 ? data.lanes : [];
          
          return (<>
            <div className={cls.paper}>
              <div className={cls.lane}>
                <CustomerSelect 
                    required
                    value={ customer || "Select a customer..."}
                    onChange={ handleCustomerChange }
                />
              </div>
              
              <div  className={cls.lane}>
                <div className={cls.laneL}>              
                  <LocationSelect 
                    customerId= { customer }  
                    valid={validation && validation.pickup ? validation.pickup : false} 
                    locationData={pickup} 
                    onChange={handlePickup} 
                    label="Pickup Location" 
                  />
                </div>
                
                <div className={cls.laneR}>
                  <LocationSelect 
                    customerId= { customer } 
                    valid={validation && validation.delivery ? validation.delivery : false} 
                    locationData={delivery} 
                    onChange={handleDelivery} 
                    label="Delivery Location" 
                  />
                </div>
              </div>

              <div style={{ display: moveCount ? `block` : `none` }}>
                { pickup && delivery && customer && rateRules && payRateGroups &&
                  <CalculatorOutput
                      lane = { lane }
                      customer= { customer }
                      pickup= { pickup }
                      delivery= { delivery }
                      rateRules = { rateRules }
                      payRateGroups = { payRateGroups } 
                  /> 
                } 
              </div>
            </div>
          </>)
        } else {
          return <div>Error Finding Lanes</div>;
        }
      }}

    </Query>
  </>)
}

////////// GRAPHQL //////////

const GET_LANES = gql`
query get_lanes($customerId: bigint!) {
  lanes(where: {customer_id: {_eq: $customerId}, active: {_eq: 1}}, order_by: [{favorite: desc}, {description: asc}]) {
    id
    description
    favorite
    pickup {
      id
      name
      nickname
      address
      email
      phone
    }
    delivery {
      id
      name
      nickname
      address
      email
      phone
    }
    delivery_inspection_sec
    duration_sec
    pickup_inspection_sec
    return_ride_wait_sec
  }
}
`;


const GET_LANES_AND_RATES = gql`
query get_lanes_and_rates($delivery_id: bigint, $pickup_id: bigint, $customer_id: bigint, $region_id: bigint){
  lanes(where: { destination_location_id: { _eq: $delivery_id }, origin_location_id: { _eq: $pickup_id } }) {
    ...Lane
  }
  raterules(where: { customer_id: { _eq: $customer_id } }, order_by: { distance_end: asc }) {
    ...Raterules
  }
  payrategroups(where:{
    end_date: {_gte: "now()"},
    active: { _eq: true },
    _or: [
      { customer_id: { _eq: $customer_id } },
      {_and: [
        { customer_id: { _is_null: true } },
        { region_id: { _eq: $region_id } }
      ]},
      {_and: [
        { customer_id: { _is_null: true } },
        { region_id: { _is_null: true } }
      ]},
    ]
  }) 
  {
    name,
    description,
    begin_date
    end_date
    payraterules{
      id
      distance_end
      distance_start
      per_mile_rate
      per_minute_rate
  }
}
}
${ fragments.lane}
${ fragments.raterules}
`;