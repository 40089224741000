import React, { useState, useContext, useEffect } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { GlobalContext } from "../../../global-context";
import { Container, Button, FormControl, InputLabel, Select, MenuItem, TextField, Grid, Typography, InputAdornment, Icon } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import CustomerSelect from '../../reusable/CustomerSelect';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';


let log = true

const useStyles = makeStyles(theme => ({
    selectInput: {
        marginLeft: "45px",
        width: "500px",
        padding: theme.spacing(1)
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        display: 'flex',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
    inputDescription: {
        color: theme.palette.text.secondary,
        marginLeft: "45px",
        marginBottom: "10px"
    },
    inputField: {
        marginLeft: "45px",
    },
    booleanSelect: {
        marginLeft: "45px",
        width: "150px",
    },
    date: {
        marginLeft: "45px",
        // paddingLeft: "25%",
        // display: "flex",
        // justifyContent: "center",
    },
    button: {
        marginLeft: "45px",
    }
}));

export default function PromoForm(props) {

    const context = useContext(GlobalContext);
    const classes = useStyles();
    const [createForm, setCreateForm] = useState(false);
    //RULE
    const [ruleId, setRuleId] = useState(0);
    const [rules, setPromoTypes] = useState([]);
    const [name, setName] = useState(0)
    const [schema, setSchema] = useState([]);
    // const [jsonb, setJsonb] = useState({});
    const [description, setDescription] = useState(0)
    //PROMO
    const [promoId, setPromoId] = useState(props.match.params.id);
    const [customer, setCustomer] = useState(0)
    const [regionsArr, setRegionsArr] = useState([])
    const [region, setRegion] = useState(0);
    const [activationDate, setActivationDate] = useState(moment().add(1, 'day').format('YYYY-MM-DD'));
    const [expirationDate, setExpirationDate] = useState(moment().add(1, 'month').add(1, 'day').format('YYYY-MM-DD'));
    const [config, setConfig] = useState([]);

    useEffect(() => {
        if (context && context.userIsAuthenticated()) {
            // log && console.log('jsonb', jsonb)
            //Edit version of form
            if (promoId) {
                getRegions();
                getPromo();
                log && console.log('view/edit');
            } else {
                // getRule()
                getRegions();
                getRules();
                log && console.log('create');
            }
        }
    }, [])
    const handlePromoTypeSelect = event => {
        setRuleId(event.target.value)
        log && console.log('handlePromoTypeSelect', event.target.value)
        rules.forEach(rule => {
            if (event.target.value === rule.id) {
                log && console.log(`Setting rule as: ${rule}`)
                setDescription(rule.description);
                rule.jsonb ? setSchema(rule.jsonb) : setSchema([]);
            }
        })
    };
    const handleDateChange = (name, event) => {
        if (name === 'activation') {
            //for some reason this requires the addition of a aday to work correctly
            setActivationDate(moment(event).add(1, 'day').format('YYYY-MM-DD'))
        } else if (name === 'expiration') {
            setExpirationDate(moment(event).add(1, 'day').format('YYYY-MM-DD'))
        }
    }
    const getRegions = () => {
        log && console.log("GET REGIONS")
        context.apolloClient.query({
            query: GET_REGIONS,
        }).then(res => {
            log && console.log("GET_REGIONS -- response.data:", res.data);
            setRegionsArr(Array.from(res.data.regions) || [])
        }).catch(err => {
            context.handleNotifications(true, "error", `Failed to retrieve Regions data from Hasura: ${err.toString()}`);
        })
    }
    const getPromo = () => {
        log && console.log("GET PROMO")
        context.apolloClient.query({
            query: GET_PROMO,
            variables: { id: props.match.params.id }
        }).then(res => {
            log && console.log("GET_RULE -- response.data:", res.data);
            log && console.log('query')
            let rule = res.data.promos[0].rule
            let promo = res.data.promos[0]
            setPromoId(promo.id)
            setCustomer(promo.customer_id || 0)
            setRegion(promo.region_id || 0)
            if (promo.activation_date) setActivationDate(promo.activation_date)
            if (promo.expiration_date) setExpirationDate(promo.expiration_date)
            setRuleId(rule.id)
            setName(rule.name)
            setDescription(rule.description)
            if (!promo.config) {
                log && console.log("NO CONFIG")
                context.handleNotifications(true, "warning", `No configuration found! One will be initialized now...`);
                setConfig({})
            } else setConfig(promo.config)
            //Need to do some finessing to get the config values into the schema to map out the form inputs
            if (createForm) {
                setSchema(rule.jsonb || [])
            } else {
                //Set form values to match current config values
                let config = promo.config ? promo.config : {}
                log && console.log('config', config)
                rule.jsonb.forEach(item => {
                    log && console.log(config[item.name])
                    if (config[item.name] && item.type === 'boolean') {
                        item.default = config[item.name] === null ? "null" : Boolean(config[item.name])
                    } else if (config[item.name]) {
                        item.default = config[item.name]
                    }
                })
                console.log('newSchema', rule.jsonb)
                setSchema(rule.jsonb)
            }
        }).catch(err => {
            context.handleNotifications(true, "error", `Failed to retrieve PROMO data from Hasura: ${err.toString()}`);
        });
    }

    const addPromo = () => context.apolloClient.mutate({
        mutation: ADD_PROMO,
        variables: {
            activation_date: activationDate || null,
            config: config,
            customer_id: customer || null,
            expiration_date: expirationDate || null,
            region_id: region || null,
            rule_id: ruleId
        }
    }).then(res => {
        log && console.log("PROMO ADDED ---", res.data)
        context.handleNotifications(true, "success", `Promo added`);
        //link back to promos table
        props.history.push({ pathname: '/promos/' })
    }
    ).catch(err => {
        console.log('Error inserting promo');
        context.handleNotifications(true, "error", `Failed to insert Promo data into Hasura: ${err.toString()}`);
    })

    const updatePromo = () => context.apolloClient.mutate({
        mutation: UPDATE_PROMO,
        variables: {
            id: promoId,
            activation_date: activationDate || null,
            config: config,
            customer_id: customer || null,
            expiration_date: expirationDate || null,
            region_id: region || null,
            rule_id: ruleId
        }
    }).then(res => {
        log && console.log("PROMO UPDATED ---", res.data)
        context.handleNotifications(true, "success", `Promo updated`);
    }
    ).catch(err => {
        console.log('Error updating promo');
        context.handleNotifications(true, "error", `Failed to update Promo data into Hasura: ${err.toString()}`);
    })

    const getRules = () => context.apolloClient.query({
        query: GET_PROMO_TYPES,
    }).then(res => {
        if (log) console.log("GET_RULE -- response.data:", res.data);
        log && console.log('query')
        var bizRules = res.data.businessrules;
        log && console.log('rules', bizRules)
        setCreateForm(true)
        setPromoTypes(bizRules)
    }).catch(err => {
        context.handleNotifications(true, "error", `Failed to retrieve PROMO_TYPE data from Hasura: ${err.toString()}`);
    })

    const handleCustomerChange = event => {
        const { value } = event.target;
        setCustomer(value);
    };
    const handleRegionChange = event => {
        const { value } = event.target;
        setRegion(value);
    };
    const PromoTypeSelect = (props) => {
        return (<TextField
            select
            variant="outlined"
            margin="dense"
            style={props.style ? props.style : {
                width: '100%',
                margin: 0,
            }}
            // labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={ruleId}
            onChange={handlePromoTypeSelect}
            label="Select Promo Type"
            InputProps={{
                startAdornment: (
                    <InputAdornment style={{ verticalAlign: "top" }} position="start">
                        <Icon color="disabled" fontSize="small">view_list</Icon>
                    </InputAdornment>)
            }}
        >
            <MenuItem key={'0menuItem'} value={0}>
                <em>None</em>
            </MenuItem>
            {rules.map(br =>
                <MenuItem key={br.id + 'menuItem'} value={br.id}>{br.name}</MenuItem>
            )}
        </TextField>)
    }
    const RegionSelect = (props) => {
        return (<TextField
            select
            // required={props.required ? props.required : false}
            name="region"
            label={"Region"}
            placeholder="Select a region..."
            value={region || 0}
            onChange={handleRegionChange}
            variant="outlined"
            margin="dense"
            style={props.style ? props.style : {
                width: '100%',
                margin: 0,
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment style={{ verticalAlign: "top" }} position="start">
                        <Icon color="disabled" fontSize="small">public</Icon>
                    </InputAdornment>)
            }}
        >
            <MenuItem key={"All Regions"} value={0}>All Regions</MenuItem> :
            {regionsArr && regionsArr.map(region => (
                <MenuItem key={region.name} value={region.id}>
                    {region.id} - {region.name}
                </MenuItem>
            ))}
        </TextField>)
    }
    const formatLabel = (string) => {
        let formattedString = string.replace(/_/g, " ");
        formattedString.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
        return formattedString
    }
    //CONFIG HANDLING
    useEffect(() => {
        log && console.log('Schema', schema)
        if (schema && schema.length > 0) createConfig();
    }, [schema])
    useEffect(() => {
        log && console.log('Config', config)
    }, [config])
    const createConfig = () => {
        let newConfig = {};
        schema.forEach(item => {
            newConfig[item.name] = item.default
        });
        console.log('newConfig', newConfig)
        setConfig(newConfig);
    }
    const handleConfigChange = (name, event) => {
        let configClone = Object.assign({}, config)
        log && console.log('configClone', configClone)
        let value = event.target.value
        // console.log('NAME+VALUE', name, value)
        configClone[name] = Number(value)
        setConfig(configClone)
    }
    const handleConfigBoolChange = (name, event) => {
        let configClone = Object.assign({}, config)
        log && console.log('configClone', configClone)
        let value = event.target.value === "null" ? null : Boolean(event.target.value)
        // console.log('NAME+VALUE', name, value)
        configClone[name] = value
        setConfig(configClone)
    }

    return (
        <div className={classes.root}>
            { context && context.userIsAuthenticated() && (
                <Container maxWidth="lg">
                    <Grid container direction="column">
                        {createForm ?
                            <Typography style={{ marginLeft: "50px" }} variant={'h6'} >
                                Add Promo
                            </Typography>
                            :
                            <Typography style={{ marginLeft: "50px" }} variant={'h6'} >
                                Edit Promo
                            </Typography>}
                        {createForm ?
                            (<Grid container direction="row">
                                <Grid item xs={6}>
                                    <FormControl variant="outlined" className={classes.selectInput}>
                                        <PromoTypeSelect item xs={6} md={5} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className={classes.selectInput}>
                                        <InputLabel>{description ? "Promo Description" : "Select a Promo"}</InputLabel>
                                        <div>{description || ""}</div>
                                    </div>
                                </Grid>
                            </Grid>)
                            :
                            (<Grid container direction="row">
                                <Grid item xs={6}>
                                    <div className={classes.selectInput}>
                                        <InputLabel>Promo Name</InputLabel>
                                        <div>{name}</div>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className={classes.selectInput}>
                                        <InputLabel>Promo Description</InputLabel>
                                        <div>{description}</div>
                                    </div>
                                </Grid>
                            </Grid>)
                        }
                        <Grid container direction="row">
                            <Grid item xs={6}>
                                <FormControl variant="outlined" className={classes.selectInput}>
                                    <CustomerSelect item xs={6} md={5}
                                        value={customer}
                                        onChange={handleCustomerChange}
                                        selectAllItem={true}
                                    />
                                </FormControl>
                            </Grid>

                            {/* add name and description fields */}
                            <Grid item xs={6}>
                                <FormControl variant="outlined" className={classes.selectInput}>
                                    {regionsArr &&
                                        <RegionSelect item xs={6} md={5} />}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item container direction="row" justify="space-evenly">
                                <Grid item md={4} sm={12}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        autoOk={true}
                                        className={classes.date}
                                        // style={{ marginRight: '6px' }}
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="dense"
                                        id="activationDate"
                                        label="Start Date"
                                        value={activationDate}
                                        onChange={event => handleDateChange('activation', event)}
                                        InputProps={{
                                            classes: {
                                                input: classes.resize,
                                            },
                                        }}
                                        InputLabelProps={{
                                            // shrink: true,
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change start date',
                                        }}
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        autoOk={true}
                                        className={classes.date}
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="dense"
                                        id="expirationDate"
                                        label="End Date"
                                        value={expirationDate}
                                        onChange={event => handleDateChange('expiration', event)}
                                        InputProps={{
                                            classes: {
                                                input: classes.resize,
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change end date',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <br />
                        <br />
                        <Grid item container direction="row" justify="flex-start">
                            {schema.map(item =>
                                item.type !== 'boolean' ?
                                    <Grid item xs={6}>
                                        <FormControl className={classes.inputField}>
                                            <TextField
                                                key={item.name}
                                                label={formatLabel(item.name)}
                                                defaultValue={item.default}
                                                onChange={(event) => handleConfigChange(item.name, event)}
                                            />
                                        </FormControl>
                                        <div className={classes.inputDescription} >{item.description}</div>
                                    </Grid>
                                    :
                                    <Grid item xs={6}>
                                        <FormControl className={classes.booleanSelect}>
                                            <InputLabel htmlFor={'boolean-select-' + item.name}>{formatLabel(item.name)}</InputLabel>
                                            <Select
                                                key={item.name}
                                                // label={formatLabel(item.name)}
                                                defaultValue={item.default}
                                                onChange={(event) => handleConfigBoolChange(item.name, event)}
                                                inputProps={{
                                                    name: item.name + 'bool',
                                                    id: 'boolean-select-' + item.name
                                                }}
                                            >
                                                <option value={"null"}>Null</option>
                                                <option value={true}>True</option>
                                                <option value={false}>False</option>
                                            </Select>
                                        </FormControl>
                                        <div className={classes.inputDescription} >{item.description}</div>
                                    </Grid>
                            )}
                        </Grid>
                        <br />
                        <br />
                        <Grid>
                            {createForm ?
                                <Button
                                    className={classes.button}
                                    id='saveRuleButton'
                                    disabled={!(ruleId)}
                                    onClick={() => { addPromo() }}
                                    color='primary'
                                    size='medium'
                                    variant='outlined'
                                >
                                    Save Rule
                                </Button>
                                :
                                <Button
                                    className={classes.button}
                                    id='updateRuleButton'
                                    disabled={!(promoId && ruleId)}
                                    onClick={() => { updatePromo() }}
                                    color='primary'
                                    size='medium'
                                    variant='outlined'
                                >
                                    Update Rule
                            </Button>}
                        </Grid>

                    </Grid>
                </Container>)
            }
        </div>
    );
}

const GET_PROMO = gql`
query get_promos($id: bigint!) {
    promos(where: {id: {_eq: $id}}) {
        id
        customer_id
        region_id
        customer{
          name
        }
        rule{
            id
            name
            description
            jsonb
        }
        expiration_date
        activation_date
        config
    }
  }
`;
const ADD_PROMO = gql`
mutation add_promo(
    $activation_date: timestamptz,
    $config: jsonb,
    $customer_id: bigint,
    $expiration_date: timestamptz,
    $region_id: bigint,
    $rule_id: bigint!
    ) {
    insert_promos(objects: {
        activation_date: $activation_date,
        active: true,
        config: $config,
        createdat: "now()",
        customer_id: $customer_id,
        expiration_date: $expiration_date,
        region_id: $region_id,
        rule_id: $rule_id
    }) {
        affected_rows
        returning {
            activation_date
            active
            createdat
            config
            customer_id
            expiration_date
            id
            region_id
            rule_id
            updatedat
        }
    }
}
`;
const UPDATE_PROMO = gql`
mutation update_promo(
    $id: bigint!, 
    $activation_date: timestamptz, 
    $config: jsonb, 
    $customer_id: bigint, 
    $expiration_date: timestamptz, 
    $region_id: bigint, 
    $rule_id: bigint!) {
    update_promos(where: {id: {_eq: $id}}, 
        _set: {
            activation_date: $activation_date, 
            config: $config, 
            customer_id: $customer_id, 
            expiration_date: $expiration_date, 
            region_id: $region_id, 
            rule_id: $rule_id}) {
          affected_rows
        returning {
            activation_date
            active
            createdat
            config
            customer_id
            expiration_date
            id
            region_id
            rule_id
            updatedat
        }
    }
}
`;

const GET_PROMO_TYPES = gql`
query get_rules {
    businessrules(where: { active: { _eq: true }, type: { _eq: 2 } }) {
        id
        name
        description
        jsonb
        ruletype{
            id
            name
        }
    }
}
`;
const GET_REGIONS = gql`
query get_regions {
    regions{
        id
        team_id
        name
        description
        geofence
        createdat
        updatedat
        last_synced
        region_id
    }
}
`;