import React, { useState, useEffect } from 'react';

import { makeStyles, TextField, MenuItem } from '@material-ui/core';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../reusable/ModalComponents';

const statuses = [
  {
    name: `Dispatched`,
    value: `dispatched`,
  },
  {
    name: `Pickup Started`,
    value: `pickup started`,
  },
  {
    name: `Pickup Started`,
    value: `pickup started`,
  },
  {
    name: `Pickup Arrived`,
    value: `pickup arrived`,
  },
  {
    name: `Pickup Successful`,
    value: `pickup successful`,
  },
  {
    name: `Delivery Started`,
    value: `delivery started`,
  },
  {
    name: `Delivery Arrived`,
    value: `delivery arrived`,
  },
  {
    name: `Delivery Successful`,
    value: `delivery successful`,
  },
  {
    name: `Picked Up`,
    value: `pickedUp`,
  },
  {
    name: `Dropped Off`,
    value: `droppedOff`,
  },
  {
    name: `Canceled`,
    value: `canceled`,
  },
  {
    name: `Failed`,
    value: `failed`,
  },
];

const cancelStatuses = [
  {
    name: `Pending`,
    value: `pending`,
  },
  {
    name: `Seen`,
    value: `seen`,
  },
  {
    name: `Started`,
    value: `started`,
  },
  {
    name: `Delivered`,
    value: `delivered`,
  },
  {
    name: `Canceled`,
    value: `canceled`,
  },
];

////////// COMPONENT //////////
export default function StatusEditModal(props) {
  const cls = useStyles();

  const { open, onClose, statusInput } = props;

  const [status, setStatus] = useState(``);
  const [cancelStatus, setCancelStatus] = useState(``);

  useEffect(() => {
    if (statusInput) {
      setStatus(statusInput.status || ``);
      setCancelStatus(statusInput.cancelStatus || ``);
    }
  }, [statusInput]);

  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };

  const handleSubmit = async () => {
    handleClose({
      status: status,
      cancelStatus: cancelStatus,
    });
  };
  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  return (
    <>
      <Modal open={open}>
        <ModalHeader handleClose={handleClose}>Edit Status</ModalHeader>

        <ModalContent
          subtitle={`Edit the current move's statuses stored in our database. Your changes will be made upon clicking 'Submit', then 'Save Changes'.`}
        >
          <TextField
            select
            fullWidth
            label='Status'
            placeholder='Select a status...'
            variant='outlined'
            margin='none'
            value={status}
            onChange={handleInputChange(setStatus)}
          >
            <MenuItem value=''>
              <em>Unplanned</em>
            </MenuItem>
            {statuses.map((s, i) => (
              <MenuItem key={`modal-status-${i}`} value={s.value}>
                {s.name}
              </MenuItem>
            ))}
          </TextField>

          <div className={cls.break} />

          <TextField
            select
            fullWidth
            label='Cancel Status'
            placeholder='Select a cancel status...'
            variant='outlined'
            margin='none'
            value={cancelStatus}
            onChange={handleInputChange(setCancelStatus)}
          >
            <MenuItem value=''>
              <em>Not Canceled</em>
            </MenuItem>
            {cancelStatuses.map((cs, i) => (
              <MenuItem key={`modal-cancel-status-${i}`} value={cs.value}>
                {cs.name}
              </MenuItem>
            ))}
          </TextField>
        </ModalContent>

        <ModalFooter>
          <ModalAction onClick={() => handleSubmit()}>Submit</ModalAction>
          <ModalAction onClick={() => handleClose()} color='default'>
            Cancel
          </ModalAction>
        </ModalFooter>
      </Modal>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));
