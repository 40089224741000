import React, { Component } from "react";
import { GlobalContext } from "../../../global-context";
import moment from "moment-timezone";
import Box from '@material-ui/core/Box';

let log = false  //Enable for verbose console logging

class TripTimelineHeader extends Component {

    componentWillMount = async () => {
        await this.setState({
            timezone: moment.tz.guess()
        })
    }


    getTripStartDatetimeSafely = () => {
        const { plans } = this.props

        return (() => {
            try {
                return moment(plans[0].moves[0].pickup_time);
            } catch (error) {
                try {
                    return moment(plans[1].moves[0].pickup_time);
                } catch (error) {
                    try {
                        return moment(plans[2].moves[0].pickup_time);
                    } catch (error) {
                        try {
                            return moment(plans[3].moves[0].pickup_time);
                        } catch (error) {
                            return moment('1969-01-01');
                        }
                    }
                }
            }
        })();
    }

    getLatestDeliveryTime = () => {
        let start = this.getTripStartDatetimeSafely()
        let latestDeliveryTime = start  //Start with the pickup time
        //if (log) console.log(`Getting latest delivery time across ${this.context.trip.plans.length} plans`)
        this.props.plans.map((currentPlan, currentPlanIndex) => {
            let planDurationSec = 0
            if (currentPlan.moves) {
                currentPlan.moves.map((currentMove, currentMoveIndex) => {
                    planDurationSec += currentMove.lane.duration_sec
                        + currentMove.lane.pickup_inspection_sec
                        + currentMove.lane.delivery_inspection_sec
                })
            }
            else {
                return latestDeliveryTime
            }
            let planLatest = start.add(planDurationSec, 'seconds')
            if (planLatest.isAfter(latestDeliveryTime)) {
                latestDeliveryTime = planLatest
            }
        })
        return latestDeliveryTime
    }

    getScaledMinuteWidth = (minutes) => ({
        //Use the calculated time scale width
        width: (minutes * this.props.timelineScale) + 'px'
    });

    makeTimelineHeaderArray = () => {
        let headerTimes = []
        let nextHour = moment().startOf('day')
        for (let index = 1; index < 26; index++) {
            nextHour.add((index > 1) ? 1 : 0, 'hours')
            headerTimes.push({
                display: moment(nextHour).format('h A'),
                duration: 60
            })
        }
        if (log) console.log('Header times array:', headerTimes)
        return headerTimes
    }



    render() {
        const { timelineScale } = this.props;
        return (
            <Box
                id='timeline-header-box'
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                ml={'-40px'}
                width={60 + 40 + (26 * 60 * timelineScale)}
                height='60px'
                bgcolor="background.paper">
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-end"
                    width={`270px`}
                    color="grey.500"
                    border={1}
                    borderTop={0}
                    borderRight={0}
                    // borderBottom={0} // Now need to set border={1} then zero out the sides you don't want
                    // borderLeft={0} // Now need to set border={1} then zero out the sides you don't want
                    ml={0}
                    borderColor="grey.300">
                    <Box
                        height='25px'
                        fontSize={14}
                        ml={0}
                        bgcolor='white'>
                        buffer
                                </Box>
                </Box>
                {
                    this.makeTimelineHeaderArray().map((header, index) => {
                        //if (log) console.log(`Rendering timeline header ${header.display} with width: ${header.duration * timelineScale} from ${header.duration} * ${timelineScale}`)
                        return (
                            <Box
                                id={`${header.display}-${index}`}
                                key={`${header.display}-${index}`}
                                display="flex"
                                flexDirection="row"
                                alignItems="flex-end"
                                width={`${header.duration * timelineScale}px`}
                                color="grey.500"
                                border={1}
                                borderTop={0}
                                borderRight={0}
                                borderLeft={0}
                                // borderBottom={0} // Now need to set border={1} then zero out the sides you don't want
                                borderLeft={(index > 0) ? 1 : 0}
                                ml={0}
                                borderColor="grey.300">
                                <Box
                                    height='25px'
                                    fontSize={14}
                                    ml={(index > 0) ? -2 : 0}
                                    bgcolor='white'>
                                    {header.display}
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>
        );
    }
}

TripTimelineHeader.contextType = GlobalContext;

export default TripTimelineHeader;