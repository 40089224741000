import React, { useState, useContext } from 'react';
import { GlobalContext } from '../../../../global-context';
import { makeStyles, Container, Typography, Button, Icon } from '@material-ui/core';
import { Timeline } from '@material-ui/lab/';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import Loading from '../../../utils/Loading';
import MoveDetailsTimelineItem from './MoveDetailsTimelineItem';

const log = false;

export default function MoveDetailsTimeline(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [limit, setLimit] = useState(10);

  const showMore = () => {
    setLimit(limit + 10);
  };
  const showLess = () => {
    setLimit(10);
  };

  return (
    <Container maxWidth='md'>
      <Query
        query={GET_MOVE_EVENTS}
        variables={{ moveId: props.moveId, limit: limit }}
        // onError={err => ctx.handleNotifications(true, `error`, `Failed to retrieve eventlogs: ${err.toString()}`)}
      >
        {({ loading, data, refetch }) => {
          if (loading) return <Loading relative />;
          if (data && data.eventlogs && data.eventlogs.length > 0) {
            // Seems to only output 1 so i changed it to reflect the length of what we get back
            // const aggregate = data.eventlogs[0].actiontype.eventlogs_aggregate.aggregate.count;
            const eventsArray = Object.keys(data.eventlogs).map(key => [data.eventlogs[key]]);
            const aggregate = eventsArray.length;
            log && console.log(`Aggregate=${aggregate}, Limit=${limit}`, eventsArray);

            return (
              <>
                <Timeline className={cls.timeline} align='left'>
                  {eventsArray.map(event => {
                    return <MoveDetailsTimelineItem eventLog={event[0]} />;
                  })}
                </Timeline>
                {aggregate >= limit ? (
                  <Button className={cls.load} onClick={showMore}>
                    show more <Icon>expand_more</Icon>
                  </Button>
                ) : (
                  <Button className={cls.load} onClick={showLess}>
                    show less <Icon>expand_less</Icon>
                  </Button>
                )}
              </>
            );
          } else
            return (
              <div className={cls.notFound}>
                <Typography className={cls.notFoundTxt}>NO LOGS AVAILABLE</Typography>
              </div>
            );
        }}
      </Query>
    </Container>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  timeline: {
    position: 'relative',
    padding: 0,
    margin: 0,
  },
  load: {
    left: '50%',
    transform: 'translateX(-50%)',
    marginTop: theme.spacing(1),
    fontSize: 16,
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));

////////// GRAPHQL //////////

const GET_MOVE_EVENTS = gql`
  query get_move_events($moveId: bigint!, $limit: Int) {
    eventlogs(where: { move_id: { _eq: $moveId } }, order_by: { event_time_utc: desc }, limit: $limit) {
      actiontype {
        description
        name
        eventlogs_aggregate(where: { move_id: { _eq: $moveId } }) {
          aggregate {
            count
          }
        }
      }
      driver_id
      event_time_utc
      metadata
      roletype {
        name
      }
    }
  }
`;
