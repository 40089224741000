import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LaneSelection from './LaneSelection';
import DateTimeEntry from './DateTimeEntry'
import moment from 'moment'
import VehicleSelection from './VehicleSelection';
import Review from './Review';
import { Grid, Badge } from '@material-ui/core';
import { Redirect } from 'react-router'
import { GlobalContext } from '../../../global-context';
import mutations from '../../utils/graphQL/mutations'
import { Mutation } from "react-apollo";
import fragments from '../../utils/graphQL/fragments';
import gql from "graphql-tag";
import axios from "axios";
import Error from '@material-ui/icons/Error';
import sdk from '@hopdrive/sdk';

const queryString = require('query-string');

const GET_LANE = gql`
query getLaneByLocationIds($origin_id: bigint!, $destination_id: bigint!, $customer_id: bigint!)  {
  lanes(
    where: {
        active: {
          _eq: "1"
        }, 
        origin_location_id: {
          _eq: $origin_id
        },  
        customer_id:{
          _eq: $customer_id
        },
    _and: { 
        destination_location_id: {
            _eq: $destination_id
        }
      }
    }
  ) {
    ...Lane
  }
}
${fragments.lane}
`;
const INSERT_LANES = gql`  
mutation insert_lanes(
  $laneObjects: [lanes_insert_input!]!){
  insert_lanes(objects: $laneObjects) {
    returning {
      ...Lane
    }
  }
 }
 ${fragments.lane}
 `;

let log = true;

const styles = theme => ({
  root: {
    width: '100%',
  },
  vehicleBadge: {
    position: "relative",
    right: "63px",
    top: "-10px",
    transform: "scale(0.8)",
  },
  button: {
    marginRight: theme.spacing(1),
    display: "inline-block",
    float: "right",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

// let vehicles = []

function getSteps() {
  return ['Lane', 'Time', 'Vehicle(s)', 'Review'];
}

class MoveForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preventClick: false,
      activeStep: 0,
      skipped: new Set(),
      customer: this.props.customer,
      ready_by: null,
      deliver_by: null,
      stock: null,
      vin: null,
      year: null,
      make: null,
      model: null,
      color: null,
      manual: false,
      instructions: null,
      pickup: null,
      delivery: null,
      vehicles: [],
      lane: null,
      checkP: true,
      checkD: true,
      checkLa: true,
      consumer_pickup: false,
      consumer_name: '',
      consumer_number: '',
      reference_num: '',
    };
  }

  componentWillMount = () => {
    // parse vehicle info from URL
    let parsed = queryString.parse(this.props.queryParameters);
    if (log) { console.log('PARSED', parsed) }
    // vv=vin vs=stock vy=year vc=color vma=make vmo=model mf=manual flag
    this.newVehicleFromURL(
      parsed.vv ? parsed.vv : null,
      parsed.vs ? parsed.vs : null,
      parsed.vy ? parsed.vy : null,
      parsed.vc ? parsed.vc : null,
      parsed.vma ? parsed.vma : null,
      parsed.vmo ? parsed.vmo : null,
      parsed.mf ? parsed.mf : null
    )

    if (parsed.p) {
      if (log) { console.log(this.props.queryData.locations) }
      let pickup = this.props.queryData.locations.find(location => location.id === parseInt(parsed.p))
      if (log) { console.log(pickup) }
      this.handleSetLocation('pickup', pickup)
    }
    if (parsed.d) {
      this.handleSetLocation('delivery', parsed.d)
    }
  }

  isStepOptional = step => step === 1;

  addVehicle = (obj) => {
    let vehicle = {
      stock: obj.stock,
      vin: obj.vin,
      color: obj.color,
      make: obj.make,
      model: obj.model,
      year: obj.year,
      manual: obj.manual,
      instructions: obj.instructions,
      reference_num: obj.reference_num
    }

    if (this.state.consumer_pickup) {
      let message = `Consumer pickup, call before pickup: ${this.state.consumer_name} @ ${this.state.consumer_number}`;
      if (!vehicle.instructions) Object.assign(vehicle, { instructions: message, tags: "consumer pickup" })
      else Object.assign(vehicle, { instructions:  `${message} -- ${vehicle.instructions}`, tags: "consumer pickup" })
    }

    this.setState(prevState => ({
      vehicles: [...prevState.vehicles, vehicle]
    }))

    this.setState({ stock: '', vin: '', color: '', make: '', model: '', year: '', manual: false, instructions: '', consumer_pickup: false, consumer_name: '', consumer_number: '', reference_num: '' })

  }

  removeVehicle = (e, index) => {
    if (index === undefined) {
      e.preventDefault()
      let targetIndex = parseInt(e.currentTarget.id);
      this.setState({ vehicles: this.state.vehicles.filter((_, i) => i !== targetIndex) });
    }
    else {
      let targetIndex = parseInt(index);
      this.setState({ vehicles: this.state.vehicles.filter((_, i) => i !== targetIndex) });
    }
  }

  clearVehicle = () => {
    this.setState({ vin: "", stock: "", year: "", color: "", make: "", model: "", manual: null, instructions: "", reference_num: '' })
  }

  componentDidMount = () => {
    // Call each subscribeToMore function here to enable live refreshing of data 
    this.props.subscribeToFavoriteLanes();
    this.props.subscribeToFavoriteLocations();
    this.props.subscribeToLanes();
    this.props.subscribeToLocations();
  }

  handleNext = () => {
    const { activeStep } = this.state;
    let { skipped } = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: activeStep + 1,
      skipped,
    });

  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };
  // Called in componentWillMount to set vehicle if using querystrings in url
  newVehicleFromURL = (vVin, vStock, vYear, vColor, vMake, vModel, vManual) => {

    if (vMake !== null && vMake !== undefined) {
      vMake = vMake.toLowerCase()
      vMake = vMake.charAt(0).toUpperCase() + vMake.substring(1)
    }
    let newVehicle = {
      vin: vVin,
      stock: vStock,
      year: vYear,
      color: vColor,
      make: vMake,
      model: vModel,
      manual: vManual
    }
    // prevents creation of null vehicle row
    if (newVehicle.stock !== null) { this.addVehicle(newVehicle) }
    else
    // pre-fills form
    { this.setState({ vin: vVin, stock: vStock, year: vYear, color: vColor, make: vMake, model: vModel, manual: vManual }) }
  }

  updateVehicleData = (vin, stock, year, color, make, model, manual, instructions, reference_num) => {

    if (make !== null && make !== undefined) {
      make = make.toLowerCase()
      make = make.charAt(0).toUpperCase() + make.substring(1)
    }

    this.setState({ vin: vin, stock: stock, year: year, color: color, make: make, model: model, manual: manual, instructions: instructions, reference_num: reference_num })
  }

  getStepContent(step) {
    switch (step) {
      case 0:
        return <LaneSelection customer={this.props.customer} handleCustomerChange={this.props.handleCustomerChange} handlePreventClick={this.handlePreventClick} queryData={this.props.queryData} handleSetLocation={this.handleSetLocation} handleFavoriteLane={this.handleFavoriteLane} pickup={this.state.pickup} delivery={this.state.delivery} lane={this.state.lane} handleReverseLocations={this.handleReverseLocations} updateLocationById={this.updateLocationById} />;
      case 1:
        return <DateTimeEntry handleTimeSubmit={this.handleTimeSubmit} />;
      case 2:
        return (
          <VehicleSelection vehicles={this.state.vehicles} newVehicleFromURL={this.newVehicleFromURL} updateVehicleData={this.updateVehicleData} model={this.state.model} color={this.state.color} vin={this.state.vin} stock={this.state.stock} make={this.state.make} year={this.state.year} manual={this.state.manual}
            instructions={this.state.instructions} handleCheckChange={this.handleCheckChange} handleChange={this.handleChange} handleSelectChange={this.handleSelectChange} handleAttributeChange={this.handleAttributeChange} addVehicle={this.addVehicle} removeVehicle={this.removeVehicle} clearVehicle={this.clearVehicle} consumer_pickup={this.state.consumer_pickup} consumer_name={this.state.consumer_name} consumer_number={this.state.consumer_number} reference_num={this.state.reference_num} />
        );
      case 3:
        return <Review state={this.state} />;
      default:
        return 'Unknown step';
    }
  }

  handleSkip = () => {
    const { activeStep } = this.state;
    if (!this.isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    this.setState(state => {
      const skipped = new Set(state.skipped.values());
      skipped.add(activeStep);
      return {
        activeStep: state.activeStep + 1,
        skipped,
      };
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  isStepSkipped(step) {
    return this.state.skipped.has(step);
  }

  handleChange = (name, value) => event => {
    this.setState({ [name]: value === undefined ? event.target.value : value });
  };

  // handleCustomerChange = (value) => {
  //   this.setState({ customer: value });
  // };

  handleAttributeChange = name => event => {
    let attribute = "";
    if (name === "stock") attribute = "vehicle_stock";
    if (name === "vin") attribute = "vehicle_vin";
    this.setState({ [name]: event ? event.value : "" }, () => {
      if (event && event.value) this.runQuery(name, attribute);
    })
  }

  handleSelectChange = name => event => {
    if (log) console.log("handleSelectChange event:", event ? event : "no event value");
    this.setState({ [name]: event ? event.value : "" })
  }

  // Query GQL and return the most recent completed move that matches the value of the entered attribute
  runQuery = (name, attribute) => {
    // Do not run the query if the attribute field is blank
    if (!this.state[name] || this.state[name].trim().length < 1) return
    this.context.apolloClient.query({
      // Passes in the attribute prop from the parent component to use as the search criteria
      query: gql`query lookup_move($value: String!) {
        moves(where: {${attribute}: {_eq: $value}}, order_by: {delivery_successful: desc}, limit: 1) {
          ...Move
        }
      }
      ${fragments.move}
    `,
      // onError: this.context.handleNotifications(true, "error", "Query failed to retrieve move data."),
      variables: { value: this.state[name] } // searches against the value entered in the input field
    }).then((data) => {
      if (data.data.moves.length !== 0) {
        this.updateVehicleData(data.data.moves[0].vehicle_vin, data.data.moves[0].vehicle_stock, data.data.moves[0].vehicle_year,
          data.data.moves[0].vehicle_color, data.data.moves[0].vehicle_make, data.data.moves[0].vehicle_model)
      }
      else {
        return
      }
    })
  }

  handleCheckChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleSetLocation = (name, value) => {
    if (log) console.log("Setting location for", name, "to", value ? value : "null")
    this.setState({ [name]: value ? value : null }, () => {
      this.handleLaneByLocations();
    });
  };

  handleFavoriteLane = (pickup, delivery, event) => {
    if (log) console.log("handleFavoriteLane event triggered with preventClick state of ", this.state.preventClick);
    if (log) console.log("  event:", event.target);
    if (this.state.preventClick) return;
    if (log) console.log("Setting lane as:", pickup, "to", delivery);
    this.setState({ pickup: pickup, delivery: delivery }, async () => {
      await this.handleLaneByLocations();
    });
  };

  handleReverseLocations = () => {
    const { pickup, delivery } = this.state;
    this.setState({ pickup: delivery, delivery: pickup }, async () => {
      await this.handleLaneByLocations();
    });
  }

  updateLocationById = (location) => {
    let { pickup, delivery } = this.state;
    if (!location || !pickup || !delivery) return;
    if (pickup.id === location.id) this.setState({ pickup: location });
    if (delivery.id === location.id) this.setState({ delivery: location });
  }

  // Finds the corresponding lane when two locations are selected
  // If no lane is found, a new lane is created along with a reverse lane (not created if it already exists)
  handleLaneByLocations = async () => {
    if (!this.state.pickup || this.state.pickup === null || !this.state.delivery || this.state.delivery === null) {
      // put error snackbar here
      if (log) console.log("Two locations not set.");
      this.setState({ lane: null });
      return;
    };
    const customerID = parseInt(this.state.customer);
    const laneRes = await sdk.lanes.get({customer_id: customerID, origin_location_id: this.state.pickup.id, destination_location_id: this.state.delivery.id})
    //check for errors
    if(laneRes.success === false && laneRes.errors && laneRes.errors.lenght > 0) throw laneRes.errors[0]
    const exists = laneRes.success && laneRes.data && laneRes.data.lenght > 0
    if (log) { console.log('exists: ' + exists) }
    // Set state to the found lane (or null if no lane is found)
    if (exists) {
      // Send the new lane(s) to the GraphQL server
      this.context.apolloClient.query({
        query: GET_LANE,
        variables: { origin_id: this.state.pickup.id, destination_id: this.state.delivery.id, customer_id: customerID }
      }).then(res => {
        // Set state to the newly created lane that matches the user's chosen locations 
        if (log) console.log("query response:", res.data.lanes)
        const returnArray = res.data.lanes;
        // left this in in case multiple lanes are returned for whatever reason
        const matchingLane = returnArray.find(o => o.origin_location_id === this.state.pickup.id && o.destination_location_id === this.state.delivery.id);
        this.setState({ lane: matchingLane });
      }).catch(function (err) {
        console.error(err);
        throw err
      });
    } else {
      try {
        // netlify function to create lane
        const response = await axios({
          method: 'POST',
          url: '/.netlify/functions/build-lanes',
          data: {
            type: 'fromLocations',
            customer_id: customerID,
            pickup: { name: this.state.pickup.name, address: this.state.pickup.address, id: this.state.pickup.id },
            delivery: { name: this.state.delivery.name, address: this.state.delivery.address, id: this.state.delivery.id }
          }
        })
        let newLanes = response.data

        if (log) { console.log('detailed', newLanes) };
        let res = await this.context.apolloClient.mutate(
          {
            mutation: INSERT_LANES,
            variables: { laneObjects: newLanes }
          })
        // Set state to the newly created lane that matches the user's chosen locations 
        if (log) { console.log("mutation response: ", res.data.insert_lanes.returning) };
        const returnArray = res.data.insert_lanes.returning;
        const matchingLane = returnArray.find(o => o.origin_location_id === this.state.pickup.id && o.destination_location_id === this.state.delivery.id);
        this.setState({ lane: matchingLane });
      } catch (err) {
        console.error(err);
        this.context.handleNotifications(true, "error", "Failed to create new lane: " + err.toString())
      }
    }
  }
  // handles pick up and delivery time sections of form
  handleTimeSubmit = (type, time) => {
    if (type === "pickup") {
      this.setState({ ready_by: time })
    } else if (type === "delivery") {
      this.setState({ deliver_by: time })
    }
  }

  NextOrFinishButton = () => {
    // will disable next button from the 'Vehicles step if form is invalid
    if (this.state.activeStep === 2) {
      return (
        <Button
          disabled={!this.validateWholeForm()}
          variant="contained"
          color="primary"
          onClick={this.handleNext}
          className={this.props.classes.button}
        > Next </Button>
      )
    } else
      if (this.state.activeStep < 2) {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleNext}
            className={this.props.classes.button}
          > Next </Button>
        )
      }
      else {
        const moveObjStatic = {
          customer_id: this.state.lane.customer_id,
          lane_id: this.state.lane.id,
          ready_by: this.state.ready_by,
          deliver_by: this.state.deliver_by,
          updatedat: "now()"
        }
        let vehiObjArr = this.state.vehicles.map(vehi => ({
          vehicle_year: vehi.year,
          vehicle_make: vehi.make,
          vehicle_model: vehi.model,
          vehicle_color: vehi.color,
          vehicle_vin: vehi.vin,
          vehicle_stock: vehi.stock,
          manual_flag: vehi.manual,
          move_details: vehi.instructions,
          tags: vehi.tags,
          reference_num: vehi.reference_num

        }))
        const movesObjArr = vehiObjArr.map(vehiObj =>
          Object.assign({}, moveObjStatic, vehiObj)
        )

        return (
          <Mutation mutation={mutations.addNewMove} variables={{ movesObjectArray: movesObjArr }}>
            {(insertMoves, { data }) =>
              <Button
                disabled={!this.validateWholeForm()}
                variant="contained"
                color="secondary"
                // onError={(err) => {console.error(err);this.context.handleNotifications(true, "error", "Move(s) failed to send.  Please check all entered data and resubmit your request.  If the problem persists, please contact HopDrive.")}}
                onClick={() => {
                  insertMoves().then(res => {
                    if (log) console.log("insertMoves .then -- res:", res);
                    if (res.data && res.data.insert_moves && res.data.insert_moves.returning.length > 0) {
                      // this.context.handleNotifications(true, "success", "Move(s) successfully created.");
                      this.handleNext()
                    } else {
                      this.context.handleNotifications(true, "error", "Move(s) failed to send.  Please check all entered data and resubmit your request.  If the problem persists, please contact HopDrive.");
                    }
                  })
                    .catch(err => {
                      if (log) console.log("insertMoves .catch -- err:", err)
                      this.context.handleNotifications(true, "error", "Move(s) failed to send.  Please check all entered data and resubmit your request.  If the problem persists, please contact HopDrive.");
                    })
                }}
                className={this.props.classes.button}
              > Finish </Button>
            }
          </Mutation>
        )
      }
  }

  handlePreventClick = () => {
    this.setState({ preventClick: !this.state.preventClick })
  }

  // Checks for a location object in both the pickup and delivery state values
  // Returns false if non-existent or if the locations are identical
  validateLaneStep = () => {
    const { pickup, delivery } = this.state;
    if (!pickup || !delivery || pickup === null || delivery === null) return false;
    if (pickup && delivery) {
      if (pickup.id === delivery.id) return false;
    };
    return true;
  };

  validateTimeStep = () => {
    const { ready_by, deliver_by } = this.state;
    if (!ready_by) return false;
    if (deliver_by && moment(deliver_by) < moment(ready_by)) return false;
    return true;
  };

  // Loops through the vehicles state array and returns false if there are no vehicles present
  validateVehiclesStep = () => {
    const { vehicles } = this.state;
    let valid = true;
    if (vehicles.length < 1) valid = false;
    vehicles.map(vehicle => {
      if (!vehicle.make || !vehicle.model || !vehicle.stock) valid = false
    })
    return valid;
  };

  // Runs the three validate functions and returns a true if all functions pass
  validateWholeForm = () => {
    if (this.validateLaneStep() && this.validateTimeStep() && this.validateVehiclesStep()) return true;
    return false;
  }

  render() {
    let parsed = queryString.parse(this.props.queryParameters);

    if (this.props.queryData.locations && this.props.queryData.locations.length > 1 && this.state.checkP && parsed.p) {
      let pickup = this.props.queryData.locations.find(location => location.id === parseInt(parsed.p))
      this.setState({ pickup: pickup, checkP: false })
    }

    if (this.props.queryData.locations && this.props.queryData.locations.length > 1 && this.state.checkD && parsed.d) {
      let delivery = this.props.queryData.locations.find(location => location.id === parseInt(parsed.d))
      this.setState({ delivery: delivery, checkD: false })
    }

    if (this.props.queryData.lanes && this.props.queryData.lanes.length > 1 && this.state.checkLa && parsed.la) {
      let lane = this.props.queryData.lanes.find(lane => lane.id === parseInt(parsed.la))
      this.setState({ lane: lane, checkLa: false, pickup: lane.pickup, delivery: lane.delivery })
    }


    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;

    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        spacing={3}
      >
        <div className={classes.root}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const props = {};
              const labelProps = {};

              if (label === "Lane") {
                return this.validateLaneStep()
                  ?
                  <Step key={label + index} {...props}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                  :
                  <>
                    <Error color="error" style={{ transform: "scale(1.3)", marginLeft: "7px" }} /> <Typography color={index === activeStep ? "textPrimary" : "textSecondary"} style={{ marginLeft: "7px", marginRight: "7px" }} inline="true"> {label} </Typography>
                  </>
              } else if (label === "Time") {
                return this.validateTimeStep()
                  ?
                  <Step key={label + index} {...props}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                  :
                  <>
                    <Error color="error" style={{ transform: "scale(1.3)", marginLeft: "7px" }} /> <Typography color={index === activeStep ? "textPrimary" : "textSecondary"} style={{ marginLeft: "7px", marginRight: "7px" }} inline="true"> {label} </Typography>
                  </>
              } else if (label === "Vehicles") {
                return this.validateVehiclesStep()
                  ?
                  <Step key={label + index} {...props}>
                    <StepLabel {...labelProps}>
                      {label}
                      <Badge className={classes.vehicleBadge} badgeContent={this.state.vehicles.length} color="secondary" />
                    </StepLabel>
                  </Step>
                  :
                  <>
                    <Error color="error" style={{ transform: "scale(1.3)", marginLeft: "7px" }} /> <Typography color={index === activeStep ? "textPrimary" : "textSecondary"} style={{ marginLeft: "7px", marginRight: "7px" }} inline="true"> {label} </Typography>
                  </>
              } else return <Step key={label + index} {...props}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            })}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <div className={classes.instructions}>
                  <Redirect to={"/moves"} />
                </div>
              </div>
            ) : (
                <div>
                  <div className={classes.instructions}>
                    <Grid item xs={12}>
                      {this.getStepContent(activeStep)}
                    </Grid>
                  </div>
                  <div>
                    {this.NextOrFinishButton()}
                    <Button
                      disabled={activeStep === 0}
                      onClick={this.handleBack}
                      className={classes.button}
                    >
                      Previous
                </Button>
                    <br />
                    <br />
                  </div>
                </div>
              )}
          </div>
        </div>
      </Grid >
    );
  }
}

MoveForm.contextType = GlobalContext;

MoveForm.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(MoveForm);