import 'date-fns';
import React, { Component } from "react";
import gql from "graphql-tag";
import PropTypes from 'prop-types';
import fragments from '../../utils/graphQL/fragments';
import {TextField, Button} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { GlobalContext } from '../../../global-context';
import { withRouter } from 'react-router';

const log = false;

const styles = theme => ({
    grid: {
        width: '60%',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
})

const GET_CUSTOMER_BY_ID = gql`

  query get_customer_by_id(
      $id: bigint!
      ){
        customers( where: 
          {id: 
            {_eq: $id}
          })
           {
            ...Customer
           }
      }
      ${fragments.customer}
`;

const UPSERT_CUSTOMERS = gql`
mutation upsert_customers(
    $customerObjects: [customers_insert_input!]!){
    insert_customers(
      objects: $customerObjects
      on_conflict: {
        constraint: idx_21595_primary
        update_columns:[
            name
            address
            email
            phone
            updatedat
        ]
      }
      ) {
        returning {
          ...Customer
        }
      }
    }
    ${fragments.customer}
`;


class CustomerForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: 1,
            name: '',
            address: '',
            email: '',
            phone:'',
            createdat: "now()",
            updatedat: "now()"
        }
    }

        // sets customer if in 'edit mode'
        componentDidMount() {
            if (this.props.formType === 'edit') {
                this.context.apolloClient.query({
                    query: GET_CUSTOMER_BY_ID,
                    variables: { id: parseInt(this.props.customerId) },
                }).then(res => {
                    const foundCustomer = res.data.customers[0]
                    if (log) console.log("GET_CUSTOMER_BY_ID response (res.data):", res.data);
                    this.setState({
                        id: foundCustomer.id,
                        name: foundCustomer.name,
                        address: foundCustomer.address,
                        email: foundCustomer.email,
                        phone: foundCustomer.phone,
                        createdat: foundCustomer.createdat
                    });
                }).catch(function (err) {
                    console.error(err);
                    throw err
                });
            }
        }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    clearForm = () => {
        this.setState({name: '', address: '', email: '', phone: ''});
    }

    upsertCustomer = () => {
        if (log) console.log("upsertCustomer input:", this.state);
        try {
            this.context.apolloClient.mutate({
                mutation: UPSERT_CUSTOMERS,
                variables: {customerObjects: this.state},
            }).then(res => {
                if(this.props.formType==='add'){
                if (log) console.log("upsertCustomer - CREATE_NEW_CUSTOMER response (res.data):", res.data);
                this.props.history.push(`/customers/`)
                }
            }).catch(function (err) {
                console.log(err);
                throw err
            });
        }
        catch (err) {
            console.log(err);
            return err
        }
    }

    render() {
        // const { classes } = this.props;
        return (
            <>
                <TextField
                    required
                    name="name"
                    label="Name"
                    style={{
                        marginTop: "50px",
                        marginLeft: "45px",
                        width: "500px",
                    }}
                    value={this.state.name}
                    onChange={this.handleInputChange}
                    margin="normal"
                    variant="outlined"
                />
                <br />
                <TextField
                    required
                    name="address"
                    label="Address"
                    style={{
                        marginLeft: "45px",
                        width: "500px",
                    }}
                    value={this.state.address}
                    onChange={this.handleInputChange}
                    margin="normal"
                    variant="outlined"
                />
                <br />
                <TextField
                    name="email"
                    label="Email"
                    style={{
                        marginLeft: "45px",
                        width: "500px",
                    }}
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    margin="normal"
                    variant="outlined"
                />
                <br />
                <TextField
                    name="phone"
                    label="Phone"
                    style={{
                        marginLeft: "45px",
                        width: "500px",
                    }}
                    value={this.state.phone}
                    onChange={this.handleInputChange}
                    margin="normal"
                    variant="outlined"
                />
                <br />
                <Button
                    disabled={!this.state.name.trim() }
                    style={{
                        marginTop: "30px",
                        marginLeft: "30px"
                    }}
                    color="primary"
                    variant="contained"
                    onClick={this.upsertCustomer}
                >
                    {this.props.formType==='add'? "Save":"Edit"}
            </Button>
            {this.props.formType==='add' &&
                <Button
                    style={{
                        marginTop: "30px",
                        marginLeft: "60px"
                    }}
                    color="default"
                    variant="contained"
                    onClick={this.clearForm}
                >
                    Clear
            </Button>
            }
            </>
        )
    }
};

    CustomerForm.contextType = GlobalContext;

    CustomerForm.propTypes = {
        classes: PropTypes.object.isRequired,
    };

    export default withRouter(withStyles(styles)(CustomerForm));