import "date-fns";
import React, { Component, useEffect } from "react";
import gql from "graphql-tag";
import { GlobalContext } from "../../../global-context";
import DetailsSection from "./DetailsSection";
import { Query } from "react-apollo";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Container,
  FormHelperText
} from "@material-ui/core";
import CustomerSelect from "../../reusable/CustomerSelect";
import LocationSelect from "../../reusable/LocationSelect";
import { withRouter } from "react-router";
import fragments from "../../utils/graphQL/fragments";
import axios from "axios";

import sdk from '@hopdrive/sdk';

let log = true;

const GET_LOCATIONS = gql`
  query get_locations_of_customer($customer_id: bigint!) {
    locations(
      order_by: { updatedat: desc_nulls_last }
      where: { active: { _eq: 1 }, customer_id: { _eq: $customer_id } }
    ) {
      ...Location
    }
  }
  ${fragments.location}
`;

const LOCATIONS_SUBSCRIPTION = gql`
  subscription sub_to_locations_of_customer($customer_id: bigint!) {
    locations(
      order_by: { updatedat: desc_nulls_last }
      where: { active: { _eq: 1 }, customer_id: { _eq: $customer_id } }
    ) {
      ...Location
    }
  }
  ${fragments.location}
`;

const GET_LANE_BY_ID = gql`
  query get_lanes_by_id($id: bigint!) {
    lanes(
      order_by: { updatedat: desc }
      where: { active: { _eq: 1 }, id: { _eq: $id } }
    ) {
      ...Lane
    }
  }
  ${fragments.lane}
`;

const GET_LANE_BY_LOCATION_IDS = gql`
  query get_lanes_by_location_ids(
    $origin_location_id: bigint!
    $destination_location_id: bigint!
  ) {
    lanes(
      order_by: { updatedat: desc }
      where: {
        origin_location_id: { _eq: $origin_location_id }
        destination_location_id: { _eq: $destination_location_id }
      }
    ) {
      ...Lane
    }
  }
  ${fragments.lane}
`;

const styles = (theme) => ({
  grid: {
    width: "60%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dlPairs: {
    margin: "1em 0",
    borderBottom: "1px solid rgb(231, 231, 231)",
  },
  rootTable: {
    verticalAlign: "top",
    position: "relative",
    width: "100%",
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
});

class LaneForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pickupLocation: null,
      deliveryLocation: null,
      pickupName: "",
      pickupStreet: "",
      pickupCity: "",
      pickupState: "VA",
      deliveryName: "",
      deliveryStreet: "",
      deliveryCity: "",
      deliveryState: "VA",
      primaryLane: null,
      inverseLane: null,
      customer: "",
      submitedPrimaryLane: false,
      submitedInverseLane: false,
      addressError: false,
    };
  }

  // sets lanes if in 'edit mode'
  componentDidMount() {
    try {
      const _this = this;
      if (this.props.formType === "edit") {
        this.context.apolloClient
          .query({
            query: GET_LANE_BY_ID,
            variables: { id: parseInt(this.props.laneId) },
          })
          .then((res) => {
            const laneOne = res.data.lanes[0];
            if (log)
              console.log("GET_LANE_BY_ID response (res.data):", res.data);
            this.setState({ primaryLane: laneOne });
            this.context.apolloClient
              .query({
                query: GET_LANE_BY_LOCATION_IDS,
                variables: {
                  origin_location_id: parseInt(laneOne.destination_location_id),
                  destination_location_id: parseInt(laneOne.origin_location_id),
                },
              })
              .then((res) => {
                // inverse lane exists
                if (res.data.lanes.length > 0) {
                  const laneTwo = res.data.lanes[0];
                  if (log)
                    console.log(
                      "GET_LANE_BY_LOCATION_IDS response (res.data):",
                      res.data
                    );
                  let laneOneSimple = laneOne;
                  // delete laneOneSimple.pickup
                  // delete laneOneSimple.delivery
                  delete laneOneSimple.customer;
                  delete laneTwo.customer;
                  this.setState({
                    primaryLane: laneOneSimple,
                    inverseLane: laneTwo,
                  });
                }
                // inverse lane must be created
                else {
                  try {
                    axios({
                      method: "POST",
                      url: "/.netlify/functions/build-lanes",
                      data: {
                        type: "fromAdresses",
                        customer_id: this.state.primaryLane.customer_id,
                        pickup: {
                          name: this.state.primaryLane.delivery.name.trim(),
                          address: this.state.primaryLane.delivery.address,
                        },
                        delivery: {
                          name: this.state.primaryLane.pickup.name.trim(),
                          address: this.state.primaryLane.pickup.address,
                        },
                      },
                    })
                      .then((res) => {
                        const laneTwo = res.data[0];
                        if (log)
                          console.log(
                            "build-lanes response (res.data):",
                            res.data
                          );
                        //removed because it was causing re-renders
                        // this.context.handleNotifications(
                        //   true,
                        //   "success",
                        //   "New Inverse Lane Built!"
                        // );
                        let laneOneSimple = laneOne;
                        // delete laneOneSimple.pickup;
                        // delete laneOneSimple.delivery;
                        delete laneOneSimple.customer;
                        delete laneTwo.customer;
                        this.setState({
                          primaryLane: laneOneSimple,
                          inverseLane: laneTwo,
                        });
                      })
                      .catch(function (err) {
                        console.error(err);
                        this.context.handleNotifications(
                          true,
                          "error",
                          "Failed to fetch/build inverse lane" + err.toString()
                        );
                        throw err;
                      });
                  } catch (error) {
                    console.error(error);
                  }
                }
              })
              .catch(function (err) {
                console.error(err);
                _this.context.handleNotifications(
                  true,
                  "error",
                  "Failed to GET Lane" + err.toString()
                );
                throw err;
              });
          });
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSetSubmit = (name) => {
    this.setState({ [name]: true });
  };

  validateAddress = (event) => {
    const {value} = event.target;
    if(sdk.utilities.validateAddress(value) === false){
      console.log("invalid address")
      this.setState({addressError: true})
    }else{
      this.setState({addressError: false})
    }
  }

  handleCustomerChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    // Gets locations for dropdowns
    this.context.apolloClient
      .query({
        query: GET_LOCATIONS,
        variables: { customer_id: parseInt(value) },
      })
      .then((res) => {
        if (log) console.log("GET_LOCATIONS response (res.data):", res.data);
        this.setState({ locations: res.data });
      })
      .catch(function (err) {
        console.error(err);
        this.context.handleNotifications(
          true,
          "error",
          "Failed to GET locations"
        );
        throw err;
      });
  };

  handleSetLocation = (name, value) => {
    if (log) console.log("Setting location for", name, "to", value);
    this.setState({ [name]: value });
    if (value !== null) {
      if (name.includes("pickup")) {
        this.setState({
          pickupName: value.name,
        });
      } else if (name.includes("delivery")) {
        this.setState({
          deliveryName: value.name,
        });
      }
    } else {
      if (name.includes("pickup")) {
        this.setState({
          pickupName: "",
          pickupStreet: "",
          pickupCity: "",
          pickupState: "VA",
        });
      } else if (name.includes("delivery")) {
        this.setState({
          deliveryName: "",
          deliveryStreet: "",
          deliveryCity: "",
          deliveryState: "VA",
        });
      }
    }
  };

  LocationFormRow = (props) => {
    useEffect(() => {
      props.subscribeToLocations();
    });
    const Location = props.pickup
      ? this.state.pickupLocation
      : this.state.deliveryLocation;
    return (
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            {props.pickup ? "Pickup Location" : "Delivery Location"}
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid container item xs={12}>
            <Grid item xs={3}>
              <Typography variant="body1" component="p">
                Location Lookup:
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={9}>
              <LocationSelect
                queryData={props.locations}
                // updateLocationById={this.props.updateLocationById}
                handleSetLocation={this.handleSetLocation}
                location={
                  props.pickup
                    ? this.state.pickupLocation
                    : this.state.deliveryLocation
                }
                formLabel={props.pickup ? "pickupLocation" : "deliveryLocation"}
              />
            </Grid>
          </Grid>
        </Grid>
        {Location ? (
          <br />
        ) : (
          <Grid container item xs={12} spacing={1}>
            <Grid container item xs={12}>
              <Grid item xs={3}>
                <Typography variant="body1" component="p">
                  New Location:
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              justify="flex-start"
              xs={12}
              spacing={1}
            >
              <Grid
                container
                item
                alignItems="center"
                justify="space-between"
                xs={9}
                spacing={1}
              >
                <Grid item md={3} xs={4}>
                  <TextField
                    name={props.pickup ? "pickupName" : "deliveryName"}
                    label="Name"
                    value={
                      props.pickup
                        ? this.state.pickupName
                        : this.state.deliveryName
                    }
                    onChange={this.handleInputChange}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={9} xs={8}>
                  <TextField
                    style={{ minWidth: "25rem" }}
                    name={props.pickup ? "pickupStreet" : "deliveryStreet"}
                    label="Address"
                    value={
                      props.pickup
                        ? this.state.pickupStreet
                        : this.state.deliveryStreet
                    }
                    onBlur={this.validateAddress}
                    onChange={this.handleInputChange}
                    margin="normal"
                    variant="outlined"
                    error={this.state.addressError}
                  />
                  <FormHelperText id='address-input-error-text' style={{display: this.state.addressError ? 'block' : 'none'}}>Address must contain commas between address, city, and state</FormHelperText>
                </Grid>
                {/* <Grid item md={2} xs={6}>
                                        <TextField
                                            name={props.pickup ? "pickupCity" : "deliveryCity"}
                                            label="City"
                                            value={props.pickup ? this.state.pickupCity : this.state.deliveryCity}
                                            onChange={this.handleInputChange}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <TextField
                                            name={props.pickup ? "pickupState" : "deliveryState"}
                                            label="State"
                                            value={props.pickup ? this.state.pickupState : this.state.deliveryState}
                                            onChange={this.handleInputChange}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid> */}
              </Grid>
              {/* <Grid item md={1} xs={6}>
                                    <Button
                                        id='newLocationClearButton'
                                        onClick={this.clearLocationForm(props.pickup ? 'pickup' : 'delivery')}
                                        variant="outlined"
                                        color='secondary'
                                    >
                                        clear
                                    </Button>
                                </Grid> */}
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  // clearLocationForm = name => (e) => {
  //     e.preventDefault();
  //     this.setState({ [name + 'Name']: "", [name + 'Street']: "", [name + 'City']: "", [name + 'State']: "", })
  // }

  // Methods for manipulating address strings
  formatAddress = (street, city, state) => {
    let formattedAddress = `${street}, ${city}, ${state}`;
    return formattedAddress;
  };
  createNewLanes = async () => {
  //removed because it was causing re-renders
  // this.context.handleNotifications(true, "info", "Working on Lane...");
    // if both new locations
    // let pickupAddress = this.formatAddress(this.state.pickupStreet, this.state.pickupCity, this.state.pickupState);
    // let deliveryAddress = this.formatAddress(this.state.deliveryStreet, this.state.deliveryCity, this.state.deliveryState);
    let pickupAddress = this.state.pickupStreet;
    let deliveryAddress = this.state.deliveryStreet;
    // if location(s) selected from dropdowns
    if (this.state.pickupLocation && this.state.deliveryLocation) {
      // check if either lane or inverse already exists
      let lanesExist = await Promise.all([
        sdk.lanes.get({
          customer_id: 1,
          origin_location_id: 1,
          destination_location_id: 2,
        }),
        sdk.lanes.get({
          customer_id: 1,
          origin_location_id: 2,
          destination_location_id: 1,
        }),
      ]);
      let primaryExists = lanesExist[0].success;
      let inverseExists = lanesExist[1].success;
      if (primaryExists && inverseExists) {
        //removed because it was causing re-renders
        // this.context.handleNotifications(true, "error", "Lanes already exist!");
        console.log("these two lanes already exist!");
        this.setState({ pickupLocation: "", deliveryLocation: "" });
        return;
      } else if (primaryExists) {
        // get primary lane
        this.context.apolloClient
          .query({
            query: GET_LANE_BY_LOCATION_IDS,
            variables: {
              origin_location_id: parseInt(this.state.pickupLocation.id),
              destination_location_id: parseInt(this.state.deliveryLocation.id),
            },
          })
          .then((res) => {
            const laneOne = res.data.lanes[0];
            if (log)
              console.log(
                "GET_LANE_BY_LOCATION_IDS response (res.data):",
                res.data
              );
            this.setState({ primaryLane: laneOne });
          })
          .catch(function (err) {
            console.error(err);
            //removed because it was causing re-renders
            // this.context.handleNotifications(
            //   true,
            //   "error",
            //   "Error on Lane GET"
            // );
            throw err;
          });
      } else if (inverseExists) {
        // get inverse lane
        this.context.apolloClient
          .query({
            query: GET_LANE_BY_LOCATION_IDS,
            variables: {
              origin_location_id: parseInt(this.state.deliveryLocation.id),
              destination_location_id: parseInt(this.state.pickupLocation.id),
            },
          })
          .then((res) => {
            const laneTwo = res.data.lanes[0];
            if (log)
              console.log(
                "GET_LANE_BY_LOCATION_IDS response (res.data):",
                res.data
              );
            this.setState({ inverseLane: laneTwo });
          })
          .catch(function (err) {
            console.error(err);
            //removed because it was causing re-renders
            // this.context.handleNotifications(
            //   true,
            //   "error",
            //   "Error on Lane GET"
            // );
            throw err;
          });
      } else {
        pickupAddress = this.state.pickupLocation.address;
        deliveryAddress = this.state.deliveryLocation.address;
      }
    } else if (this.state.pickupLocation) {
      pickupAddress = this.state.pickupLocation.address;
    } else if (this.state.deliveryLocation) {
      deliveryAddress = this.state.deliveryLocation.address;
    }
    axios({
      method: "POST",
      url: "/.netlify/functions/build-lanes",
      data: {
        type: "fromAddresses",
        customer_id: this.state.customer,
        pickup: { name: this.state.pickupName.trim(), address: pickupAddress },
        delivery: {
          name: this.state.deliveryName.trim(),
          address: deliveryAddress,
        },
      },
    })
      .then((res) => {
        const newLanes = res.data;
        if (log) console.log("createNewLane - created Lane object:", newLanes);
        // one new lane, one old lane
        if (newLanes.length === 1 && this.state.primaryLane !== null) {
          this.setState({ inverseLane: newLanes[0] });
          //removed because it was causing re-renders
          // this.context.handleNotifications(
          //   true,
          //   "success",
          //   "Selected Lane Built!"
          // );
        } else if (newLanes.length === 1 && this.state.inverseLane !== null) {
          this.setState({ primaryLane: newLanes[0] });
          //removed because it was causing re-renders
          // this.context.handleNotifications(
          //   true,
          //   "success",
          //   "Inverse of Selected Lane Built"
          // );
        }
        // two new lanes
        else if (newLanes[0] !== null && newLanes[1] !== null) {
          this.setState({ primaryLane: newLanes[0], inverseLane: newLanes[1] });
          //removed because it was causing re-renders          
          // this.context.handleNotifications(
          //   true,
          //   "success",
          //   "Two New Lanes Built!"
          // );
        }
      })
      .catch(function (err) {
        console.error(err);
        //removed because it was causing re-renders
        // this.context.handleNotifications(
        //   true,
        //   "error",
        //   "Lane failed to build: " + err.toString()
        // );
        throw err;
      });
  };
  clearLocations = () => {
    this.setState({
      pickupLocation: null,
      deliveryLocation: null,
      pickupName: "",
      pickupStreet: "",
      deliveryName: "",
      deliveryStreet: "",
      primaryLane: null,
      inverseLane: null,
    });
  };

  render() {
    try {
      return (
        <Card>
          <CardHeader
            title={
              this.props.formType === "add" ? "Create Lanes" : "Edit Lanes"
            }
            // subheader="Inverse lane is created automatically"
          />
          <CardContent>
            {/* create lane form section */}
            {this.props.formType === "add" && (
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h6">
                    Customer
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <CustomerSelect
                    value={this.state.customer}
                    onChange={this.handleCustomerChange}
                  />
                </Grid>
                {this.state.customer ? (
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="baseline"
                    spacing={2}
                  >
                    <Query
                      query={GET_LOCATIONS}
                      variables={{ customer_id: parseInt(this.state.customer) }}
                      // onError={(err) => {
                      //   console.error(err);
                      //   this.context.handleNotifications(
                      //     true,
                      //     "error",
                      //     "Query failed to retrieve location data"
                      //   );
                      // }}
                    >
                      {({ subscribeToMore, ...result }) => {
                        if (result.loading)
                          return (
                            <>
                              <this.LocationFormRow
                                style={{ marginTop: "20px" }}
                                pickup={true}
                                // locations={result.data}
                                subscribeToLocations={() => {
                                  if (log) {
                                    console.log("loading");
                                  }
                                }}
                              ></this.LocationFormRow>
                              <this.LocationFormRow
                                style={{ marginTop: "20px" }}
                                pickup={false}
                                // locations={result.data}
                                subscribeToLocations={() => {
                                  if (log) {
                                    console.log("loading");
                                  }
                                }}
                              ></this.LocationFormRow>
                            </>
                          );
                        if (result.error)
                          return `Error! ${result.error.message}`;
                        const subscribeToLocations = () => {
                          subscribeToMore({
                            document: LOCATIONS_SUBSCRIPTION,
                            variables: {
                              customer_id: parseInt(this.state.customer),
                            },
                            updateQuery: (prev, { subscriptionData }) => {
                              if (!subscriptionData.data) return prev;
                              return {
                                locations: subscriptionData.data.locations,
                              };
                            },
                          });
                        };
                        return (
                          <>
                            <this.LocationFormRow
                              style={{ marginTop: "20px" }}
                              pickup={true}
                              locations={result.data}
                              subscribeToLocations={subscribeToLocations}
                            ></this.LocationFormRow>
                            <this.LocationFormRow
                              style={{ marginTop: "20px" }}
                              pickup={false}
                              locations={result.data}
                              subscribeToLocations={subscribeToLocations}
                            ></this.LocationFormRow>
                          </>
                        );
                      }}
                    </Query>
                    <Grid
                      container
                      justify="flex-start"
                      style={{ marginTop: "20px" }}
                    >
                      <Grid item xs={2}>
                        <Button
                          disabled={
                            !this.state.pickupName || !this.state.deliveryName || this.state.addressError
                          }
                          size="medium"
                          color="primary"
                          variant="outlined"
                          onClick={this.createNewLanes}
                        >
                          Set Locations
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          size="medium"
                          color="secondary"
                          variant="outlined"
                          onClick={this.clearLocations}
                        >
                          Clear Locations
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            )}
            {/* Edit Lane Details section */}
            {this.state.primaryLane && this.state.inverseLane && (
              <>
                <Grid container>
                  <DetailsSection
                    primary={true}
                    onChange={this.handleInputChange}
                    handleSetSubmit={this.handleSetSubmit}
                    lane={this.state.primaryLane}
                  />
                  <DetailsSection
                    primary={false}
                    onChange={this.handleInputChange}
                    handleSetSubmit={this.handleSetSubmit}
                    lane={this.state.inverseLane}
                  />
                </Grid>
                {this.props.formType === "edit" ? (
                  <Grid container justify="flex-end">
                    <Grid item xs={3}>
                      <Button
                        size="medium"
                        color="primary"
                        variant="outlined"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.goBack();
                        }}
                      >
                        Done
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container justify="flex-end">
                    <Grid item xs={2}>
                      <Button
                        disabled={
                          this.state.submitedPrimaryLane ||
                          this.state.submitedInverseLane
                        }
                        size="medium"
                        color="primary"
                        variant="outlined"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.goBack();
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        disabled={
                          !this.state.submitedPrimaryLane ||
                          !this.state.submitedInverseLane
                        }
                        size="medium"
                        color="primary"
                        variant="outlined"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.goBack();
                        }}
                      >
                        Done
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </CardContent>
        </Card>
      );
    } catch (error) {
      console.error(error);
      return (
        <div className={this.props.classes.rootTable}>
          <Container maxWidth="sm">
            <div className={this.props.classes.notFound}>
              <Typography className={this.props.classes.notFoundTxt}>
                ERROR WITH LANE, PLEASE CHECK RELATED LOCATIONS
              </Typography>
            </div>
          </Container>
        </div>
      );
    }
  }
}

LaneForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
LaneForm.contextType = GlobalContext;

export default withRouter(withStyles(styles)(LaneForm));
