import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CompareArrows from '@material-ui/icons/CompareArrows';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Favorite from '@material-ui/icons/Favorite';
import LocationSelect from '../../reusable/LocationSelect';
import CustomerSelect from '../../reusable/CustomerSelect';
import CustomerFavorites from './CustomerFavorites';
import Typography from '@material-ui/core/Typography';
import { GlobalContext } from '../../../global-context';
import gql from "graphql-tag";


const styles = theme => ({
  root: {
    // flexGrow: 1,
  },
  header: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    textAlign: 'left',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  tablePaper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  button: {
    boxShadow: "none !important",
    backgroundColor: "transparent !important",
    display: "inline-block",
    transition: "all .1s ease-in-out",
    "&:hover": {
      transform: "scale(1.15)"
    },
  },
  fab: {
    boxShadow: "none !important",
    backgroundColor: "transparent !important",
    // display: "inline-block",
    // float: "right"
  },
  flex: {
    display: "inline-grid",
  },
  arrows: {
    position: 'relative',
    top: 5
  },
  container: {
    margin: 0
  }
});

class LaneSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  };

  // In MovesForm this is done one level up, so it only triggers if createEdit is set to true
  componentDidMount = () => {
    if (this.props.createEdit) {
      // Call each subscribeToMore function here to enable live refreshing of data 
      this.props.subscribeToFavoriteLanes();
      this.props.subscribeToFavoriteLocations();
      this.props.subscribeToLanes();
      this.props.subscribeToLocations();
    }
  }

  addNewFavoriteLane = (lane) => {
    this.context.apolloClient.mutate({
      mutation: gql`
      mutation addNewFavoriteLane(
        $lane_id: bigint!,
        $customer_id: bigint!
      ){
        insert_favoritelanes(objects: {customer_id: $customer_id, lane_id: $lane_id}) {
          returning {
            lane_id
            customer_id
          }
        }
      }
      `,
      variables: { lane_id: lane.id, customer_id: lane.customer_id }
    })
  }

  deleteFavoriteLane = (lane) => {
    this.context.apolloClient.mutate({
      mutation: gql`
        mutation deleteFavoriteLane(
          $lane_id: bigint!
        ){
          delete_favoritelanes(where: {lane_id: {_eq: $lane_id}}) {
            returning {
              lane_id
            }
          }
        }
      `,
      variables: { lane_id: lane.id }
    })
  }

  handleFavoriteLane = (lane, classes) => {
    if (!lane || !this.props.queryData.favoritelanes) return null;
    for (let favorite of Array.from(this.props.queryData.favoritelanes)) {
      if (lane.id === favorite.lane_id)
        return <Button className={classes.button} disableRipple tabIndex="-1" onClick={() => this.deleteFavoriteLane(lane)}>
          <Favorite color="error" />
        </Button>
    }
    return <Button className={classes.button} disableRipple tabIndex="-1" onClick={() => this.addNewFavoriteLane(lane)}>
      <FavoriteBorder color="error" />
    </Button>;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container justify="space-between" alignItems="flex-end" className={classes.container}>
          {!this.props.createEdit &&
            <Grid container alignItems="flex-start" direction='column' spacing={6} className={classes.container}>
              <Grid item xs={12} md={5} >
                <Typography item xs={6} md={5} variant="h5">Customer</Typography>
                <CustomerSelect item xs={6} md={5}
                  value={this.props.customer !== 0 ? this.props.customer : ''}
                  onChange={this.props.handleCustomerChange}
                />
              </Grid>
            </Grid>
          }
          {(this.props.createEdit || this.props.customer) ?
            <>
              <Grid item xs={12} md={5} >
                <Typography variant="h5" className={classes.header}>Pickup</Typography>
                <Paper className={classes.paper}>
                  <LocationSelect queryData={this.props.queryData} updateLocationById={this.props.updateLocationById} handleSetLocation={this.props.handleSetLocation} location={this.props.pickup} formLabel="pickup" />
                </Paper>
              </Grid>
              <Grid container justify="center" className={classes.flex} item xs={2}>

                {this.handleFavoriteLane(this.props.lane, classes)}

                <br />
                <Button className={classes.button} disableRipple onClick={this.props.handleReverseLocations} tabIndex="-1"  >
                  <CompareArrows />
                </Button>
              </Grid>
              <Grid item xs={12} md={5} >
                <Typography variant="h5" className={classes.header}>Delivery</Typography>
                <Paper className={classes.paper}>
                  <LocationSelect queryData={this.props.queryData} updateLocationById={this.props.updateLocationById} handleSetLocation={this.props.handleSetLocation} location={this.props.delivery} formLabel="delivery" />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.tablePaper}>
                  <CustomerFavorites handlePreventClick={this.props.handlePreventClick} queryData={this.props.queryData} updateLocationById={this.props.updateLocationById} handleFavoriteLane={this.props.handleFavoriteLane} handleSetLocation={this.props.handleSetLocation} />
                </Paper>
              </Grid>
            </>
            :
            <></>}
        </Grid>
        <br />
      </div>
    )
  }

}

LaneSelection.contextType = GlobalContext;

LaneSelection.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(LaneSelection);

