import React, { useState, useEffect } from "react";
import { Tooltip, Badge, Icon } from '@material-ui/core';

export default function PendingDisputedIcon(props) {
  const [armoves, setArmoves] = useState(props.armoves);
  useEffect(() => { props.subscribeToMore() })
  useEffect(() => { setArmoves(props.armoves) }, [props.armoves])
  return (
    <Tooltip title={'Disputed ARMoves'}>
      <Badge badgeContent={armoves && armoves.length} color="error">
        <Icon>announcement</Icon>
      </Badge>
    </Tooltip>
  )
}

