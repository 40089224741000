import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import axios from 'axios';
import InvoiceLayout from './InvoiceLayout';
import { GlobalContext } from "../../../global-context";

let log = true

const GET_BOL_IMG_URL_FROM_TOOKAN = (APIKey, tookan_job_id) => axios.post(`https://${process.env.REACT_APP_TKN_SD}.socialautotransport.com/V2/get_task_details`, { api_key: APIKey, job_id: tookan_job_id })

const CHECK_BOL_OVERRIDE = image_name => axios.head(`https://s3.amazonaws.com/${process.env.REACT_APP_BOLBK}/${image_name}`)

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
  }
})

class InvoicePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filteredMoves: [],
      priceSum: 0,
      distanceSum: 0,
      averagePerMileRate: 0,
    }
  }

  componentDidMount = () => {
    this.handleCustomerInvoice(this.props.moves, this.props.addBOL);
  }

  componentWillReceiveProps = () => {
    this.handleCustomerInvoice(this.props.moves, this.props.addBOL);
  }

  calculatePrice = move => {
    //TODO: We still need to make this a calculation not a lookup from the embedded lane
    //TODO: This should be in a move price calculator module that is imported too
    if (move.class === 'base')
      return Number(move.lane.dealer_base_price)
    else
      return Number(move.lane.dealer_stranded_price)
  }

  calculateRate = move => {
    //TODO: We still need to make this a calculation not a lookup from the embedded lane
    //TODO: This should be in a move price calculator module that is imported too
    if (move.class === 'base')
      return Number(move.lane.dealer_base_rate)
    else
      return Number(move.lane.dealer_stranded_rate)
  }

  calculateRateType = move => {
    //TODO: We still need to make this a calculation not a lookup from the embedded lane
    //TODO: This should be in a move price calculator module that is imported too
    if (move.class === 'base')
      return move.lane.dealer_base_rate_type
    else
      return move.lane.dealer_stranded_rate_type
  }

  handleS3ImageCheck = (move, s3Url, s3Exists) => {
    if (s3Exists) {
      move.bolImageUrl = s3Url
      this.setState({
        [`bol_${move.id}`]: s3Url
      })
    } else {
      if (log) console.log('   No override image found in S3 bucket for move id #' + move.id)
      GET_BOL_IMG_URL_FROM_TOOKAN(this.context.userProfile["https://api_keys.io/jwt/claims"]["TookanKey"], move.delivery_stop_id)
        .then(res => {
          //   if (res.status == 200 && res.data.data[0].fields.custom_field.find(o => o.label == "BOL").fleet_data) {
          //     let uploadedURLs = res.data.data[0].fields.custom_field.find(o => o.label == "BOL").fleet_data
          if (res.status == 200 && res.data.data[0].task_history.find(o => o.description.includes("BOL"))) { // added
            let bol_obj = res.data.data[0].task_history.find(o => o.description.includes("BOL")); // added
            if (bol_obj) { // added
              let foundDescription = JSON.parse(bol_obj.description) // added
              if (foundDescription.fleet_data) { // added
                let foundURL = foundDescription.fleet_data // added
                // let uploadedURLs = JSON.parse(bol_obj).fleet_data
                // let bolArr = uploadedURLs
                // let foundURL = bolArr[bolArr.length - 1]
                move.bolImageUrl = foundURL.replace('[\"', '').replace('\"]', '')
                this.setState({
                  [`bol_${move.id}`]: move.bolImageUrl
                })
              } // added
            } // added
          }
        })
        .catch(err => {
          console.log("   Failed to retrieve Tookan BOL image URL.", err)
        })
    }
  }

  bolOverrideExists = (moveId) => {
    let imageName = process.env.REACT_APP_BOLNM.replace('{MOVEID}', moveId)
    return CHECK_BOL_OVERRIDE(imageName)
  }


  handleCustomerInvoice = (moves, addBOL) => {
    //Prepare data for display
    let priceSum = 0
    let distanceSum = 0
    let filteredMoves = []
    moves.map(move => {
      //if (log) console.log(`   Processing move #${move.id}`)
      if (move.customer_id === this.props.customer.id) {
        //if (log) console.log(`      Move is for our customer: ${move.customer_id}`)
        if (move.move_type === 'drive' && move.chargeable) {
          //if (log) console.log(`      Move is a drive type`)
          if (move.active) {
            //if (log) console.log(`      Move is active`)
            move.calculatedPrice = this.calculatePrice(move)
            move.calculatedRate = this.calculateRate(move)
            move.calculatedRateType = this.calculateRateType(move)
            // Rob 4/26 - Added BOL override check as priority over tookan bol
            ////////////////////////////////////////////////////////////////////
            if (addBOL) {
              if (log) console.log(`Adding BOL for move id ${move.id}...`)
              move.bolImageUrl = 'http://icons.iconarchive.com/icons/oxygen-icons.org/oxygen/256/Status-image-missing-icon.png'
              let overrideImageName = process.env.REACT_APP_BOLNM.replace('{moveid}', move.id)
              if (log) console.log(`   Override BOL Image Name: ${overrideImageName}`)
              CHECK_BOL_OVERRIDE(overrideImageName)
                .then(res => {
                  this.handleS3ImageCheck(move, `https://s3.amazonaws.com/${process.env.REACT_APP_BOLBK}/${overrideImageName}`, true)
                })
                .catch(err => {
                  if (!err.response) {  //Localhost returns network error cuz CORS if the image exists
                    this.handleS3ImageCheck(move, `https://s3.amazonaws.com/${process.env.REACT_APP_BOLBK}/${overrideImageName}`, true)
                  } else {  //Else it returns an actual 403 not found (the image doesnt exist in S3)
                    this.handleS3ImageCheck(move, '', false)
                  }
                });
            } ////////////////////////////////////////////////////////////////////
            filteredMoves.push(move);
            this.setState({ [`bol_${move.id}`]: move.bolImageUrl })
            //Aggregation for header
            if (log) console.log(`Invoice.loadTrips() priceSum: ${priceSum} += ${move.calculatedPrice}`)
            priceSum += move.calculatedPrice
            distanceSum += Number((move.lane.distance_miles != 'undefined') ? move.lane.distance_miles : 0)
          } else {
            if (log) console.log(`      Move excluded because it is innactive (soft deleted)`)
          }
        } else {
          if (log) console.log(`      Move excluded because it is not drive type: ${move.move_type}`)
        }
      } else {
        if (log) console.log(`      Move excluded because it is not for this customer: ${move.customer_id}`)
      }
    })
    //Get customer address for the header
    let customerAddress = this.props.customer.address

    if (log) console.log(`Invoice.loadTrips finsihed filtering and move count: ${filteredMoves.length}`, filteredMoves)

    if (log) console.log('Total distance: ', distanceSum)
    let averagePerMileRate = ((filteredMoves.length > 0) ? priceSum / distanceSum : 0)
    if (log) console.log('Total averagePerMileRate: rate, price/miles', averagePerMileRate, priceSum, distanceSum)
    this.setState({
      filteredMoves: filteredMoves,
      priceSum: priceSum,
      distanceSum: distanceSum,
      averagePerMileRate: averagePerMileRate,
      customerAddress: customerAddress,
      isLoading: false
    })
  }




  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {this.props.customer.id && this.state.filteredMoves.length > 0 ?
          <Paper className={classes.paper} elevation={5}>
            <div // Element to refer to when printing
              ref={el => this.props.assignPrintRef(el)}
            >
              {this.props.singlePageInvoice ? this.state.filteredMoves.map((move, index) => {
                let totPrice = this.calculatePrice(move),
                  totDistance = Number((move.lane.distance_miles != 'undefined') ? move.lane.distance_miles : 0);
                return (
                  <React.Fragment>
                    <InvoiceLayout // Layout for single moves per invoice
                      singlePageInvoice={this.props.singlePageInvoice}
                      customerName={this.props.customer.name}
                      customerId={this.props.customer.id}
                      invoiceNumber={this.props.invoiceNumber}
                      startDate={this.props.startDate}
                      endDate={this.props.endDate}
                      moves={[move]}
                      priceSum={totPrice}
                      distanceSum={totDistance}
                      averagePerMileRate={(totPrice > 0 && totDistance > 0) ? totPrice / totDistance : 0}
                      customerAddress={this.props.customer.address}
                      accountSuffix={this.props.accountSuffix}
                    />
                    <p style={{ pageBreakBefore: "always" }} />
                    {(this.props.addBOL) ?
                      <React.Fragment>
                        <img
                          style={{ maxHeight: '800px', maxWidth: '800px' }}
                          src={this.state[`bol_${move.id}`]}
                          alt={`BOL ${move.id}`}>
                        </img>
                        {
                          // Preventing a blank page at the end of printing
                          (index + 1 != this.state.filteredMoves.length) ? <p style={{ pageBreakBefore: "always" }} /> : null
                        }
                      </React.Fragment>
                      : null}
                  </React.Fragment>
                )
              }) :
                <React.Fragment>
                  <InvoiceLayout // Layout for multiple moves per invoice
                    customerName={this.props.customer.name}
                    customerId={this.props.customer.id}
                    invoiceNumber={this.props.invoiceNumber}
                    startDate={this.props.startDate}
                    endDate={this.props.endDate}
                    moves={this.state.filteredMoves}
                    priceSum={this.state.priceSum}
                    distanceSum={this.state.distanceSum}
                    averagePerMileRate={this.state.averagePerMileRate}
                    customerAddress={this.props.customer.address}
                    accountSuffix={this.props.accountSuffix}
                  />
                </React.Fragment>
              }
            </div>
          </Paper>
          : null
        }
      </React.Fragment>
    )
  }
}

InvoicePage.contextType = GlobalContext;

InvoicePage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(InvoicePage)