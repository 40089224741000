import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { AppBar } from '@material-ui/core';
import LocationWithNickname from '../../reusable/LocationWithNickname';


function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: '100%',
    overflowX: 'visible',
    marginTop: theme.spacing(2)
  },
  table: {
    maxWidth: "100%",
  },
  secondaryTxt: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  primaryTxt: {
    fontSize: "125%",
  },
  button: {
    margin:  theme.spacing(1),
    display: "inline-block",
    // float: "right",
  },
});

class CustomerFavorites extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const { classes, theme } = this.props;
    const locations = this.props.queryData.favoritelocations,
      lanes = this.props.queryData.favoritelanes;

    return (
      <div className={classes.root}>
        <div>
          <AppBar position="static">
            <Tabs
              lefted ="true"
              value={this.state.value}
              onChange={this.handleChange}
            >
              <Tab label="Favorite Lanes" />
              <Tab label="favorite Locations" />
            </Tabs>
          </AppBar>
        </div>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
        >
          <TabContainer className={classes.table} dir={theme.direction}>
            <Table className={classes.table}>
              <TableBody>
                {lanes && lanes.length > 0 ? lanes.map(favoriteLane => (
                  <TableRow key={"TableRow"+favoriteLane.lane_id+"Lane"} onClick={(event) => this.props.handleFavoriteLane(favoriteLane.lane.pickup, favoriteLane.lane.delivery, event)} hover>
                    <TableCell key={"PickupCell"+favoriteLane.lane_id+"Lane"} align="left" component="th" scope="row">
                      {/* <Typography inline="true" className={classes.secondaryTxt} color="textSecondary">
                        <Typography className={classes.primaryTxt} inline="true" color="textPrimary">{favoriteLane.lane.pickup.name + " "}</Typography>
                        {" " + favoriteLane.lane.pickup.address.substr(0, favoriteLane.lane.pickup.address.indexOf(','))}
                      </Typography> */}
                      <LocationWithNickname key={"PickupName"+favoriteLane.lane_id+"Lane"} handlePreventClick={this.props.handlePreventClick} updateLocationById={this.props.updateLocationById} location={favoriteLane.lane.pickup} primary={120} secondary={90} />
                    </TableCell>
                    <TableCell key={"ToCell"+favoriteLane.lane_id+"Lane"} align="left">to</TableCell>
                    <TableCell key={"DeliveryCell"+favoriteLane.lane_id+"Lane"} align="left">
                      {/* <Typography inline="true" className={classes.secondaryTxt} color="textSecondary">
                        <Typography className={classes.primaryTxt} inline="true" color="textPrimary">{favoriteLane.lane.delivery.name + " "}</Typography>
                        {" " + favoriteLane.lane.delivery.address.substr(0, favoriteLane.lane.delivery.address.indexOf(','))}
                      </Typography> */}
                      <LocationWithNickname key={"DeliveryName"+favoriteLane.lane_id+"Lane"} handlePreventClick={this.props.handlePreventClick} updateLocationById={this.props.updateLocationById} location={favoriteLane.lane.delivery} primary={120} secondary={90} />
                    </TableCell>
                  </TableRow>
                )) : null}
              </TableBody>
            </Table>
          </TabContainer>
          <TabContainer dir={theme.direction}>
            <Table className={classes.table}>
              <TableBody>
                {locations && locations.length > 0 ? locations.map(favoriteLoc => (
                  <TableRow key={"TableRow"+favoriteLoc.location.id+"Loc"} hover >
                    <TableCell key={"NameCell"+favoriteLoc.location.id+"Loc"} align="left" component="th" scope="row">
                      {/* <Typography inline="true" className={classes.secondaryTxt} color="textSecondary">
                        <Typography className={classes.primaryTxt} inline="true" color="textPrimary">{favoriteLoc.location.name + " "}</Typography>
                        {" " + favoriteLoc.location.address.substr(0, favoriteLoc.location.address.indexOf(','))}
                      </Typography> */}
                      <LocationWithNickname key={"LocationWithNickname"+favoriteLoc.location.id+"Loc"} handlePreventClick={this.props.handlePreventClick} updateLocationById={this.props.updateLocationById} location={favoriteLoc.location} primary={120} secondary={85} />
                    </TableCell>
                    <TableCell key={"PickupDeliveryCell"+favoriteLoc.location.id+"Loc"} align="center">
                      <Button key={"ButtonPickup"+favoriteLoc.location.id+"Loc"} size="small" variant="contained" className={classes.button} onClick={() => this.props.handleSetLocation("pickup", favoriteLoc.location)}>
                        Use as pickup
                    </Button>
                      <Button key={"ButtonDelivery"+favoriteLoc.location.id+"Loc"} size="small" variant="contained" className={classes.button} onClick={() => this.props.handleSetLocation("delivery", favoriteLoc.location)}>
                        Use as delivery
                    </Button>
                    </TableCell>
                  </TableRow>
                )) : null}
              </TableBody>
            </Table>
          </TabContainer>
        </SwipeableViews>
      </div>
    );
  }
}

CustomerFavorites.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CustomerFavorites);