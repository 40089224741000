import React, { Component } from "react";
import { GlobalContext } from "../../../global-context";
// import moment from 'moment';
import { Typography, withStyles, Grid, Paper, Divider } from "@material-ui/core";
import DoneIcon from '@material-ui/icons/Done';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import TimeIcon from '@material-ui/icons/AccessTime';
import MoveAggregateList from "./MoveAggregateList";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    transition: "all .2s ease-in-out",
    '&:hover': {
      transform: "scale(1.05)",
    },
  },
  paperHeader: {
    fontSize: "105%",
    fontWeight: "bold",
    marginBottom: "5px"
  },
  icon: {
    position: "relative",
    bottom: "-5px",
    marginRight: "5px"
  },
  gridItem: {
    transition: "all 0.2s",
    "&:hover": {
      transform: "scale(1.1)",
      background: "rgba(130, 130, 130, 0.2)",
      transition: "all 0.2s",
    }
  },
})
class MoveAggregate extends Component {

  componentDidMount = () => {
    this.props.subscribeToNewMoves();
  }

  handleSortMovesByDestination = (moves) => {
    let destinations = [],
      movesWithD = [];
    for (let move of moves) {
      if (!move.lane || !move.lane.delivery || !move.lane.delivery.name) continue;
      movesWithD.push(move)
      let dName = move.lane.delivery.name;
      if (destinations.indexOf(dName) < 0) {
        destinations.push(dName);
      }
    }
    let sortedArr = destinations.map(destination => { return { location: destination, moves: [] } })
    for (let locObj of sortedArr) {
      locObj.moves = movesWithD.filter(o => o.lane.delivery.name === locObj.location)
    }
    return sortedArr;
  }

  render() {
    const { classes, moves } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={6} className={classes.root}>
          <Grid item xs={12} sm={4} md={4}>
            <Paper className={classes.paper}>
              <Typography color="textPrimary"> <TimeIcon style={{ color: "orange" }} /> <br /> Pending moves: {moves.filter(o => !o.driver_name).length} </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Paper className={classes.paper}>
              <Typography color="textPrimary"> <DirectionsCarIcon style={{ color: "blue" }} /> <br /> Moves in progress: {moves.filter(o => o.driver_name && o.status !== "delivery successful").length} </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Paper className={classes.paper}>
              <Typography color="textPrimary"> <DoneIcon style={{ color: "green" }} /> <br /> Completed moves: {moves.filter(o => o.status === "delivery successful").length} </Typography>
            </Paper>
          </Grid>
        </Grid>
        {moves.length > 0 ?
          <>
            <br />
            <Divider />
            <br />
          </>
          : null
        }
        <Grid container spacing={4} className={classes.root}>
          {this.handleSortMovesByDestination(moves).map(destination => {
            let completed = destination.moves.filter(o => o.status === "delivery successful"),
              inProgress = destination.moves.filter(o => o.driver_name && o.status !== "delivery successful"),
              pending = destination.moves.filter(o => !o.driver_name);
            let id = destination.moves[0].id
            return (
            <Grid key={'wrapper'+id} item xs={12} sm={6} md={3}>
              <Paper key={'paper'+id} className={classes.paper}>
                <Typography key={'location'+id} className={classes.paperHeader} color="textPrimary"> {destination.location} </Typography>
                <Grid key={'container'+id} container className={classes.root}>

                  <Grid key={'pendingItem'+id} className={classes.gridItem} item xs={4}>
                    <MoveAggregateList key={'pendingList'+id}  moves={pending} component={<Typography key={'pendingItem'+id} className={classes.paperHeader} color="textPrimary"> <TimeIcon style={{ color: "orange" }} className={classes.icon} />{pending.length} </Typography>}>
                    </MoveAggregateList>
                  </Grid>

                  <Grid key={'inProgressItem'+id} className={classes.gridItem} item xs={4}>
                    <MoveAggregateList key={'inProgressList'+id} moves={inProgress} component={<Typography key={'pendingItem'+id} className={classes.paperHeader} color="textPrimary"> <DirectionsCarIcon style={{ color: "blue" }} className={classes.icon} />{inProgress.length} </Typography>}>
                    </MoveAggregateList>
                  </Grid>

                  <Grid key={'completedItem'+id} className={classes.gridItem} item xs={4}>
                    <MoveAggregateList key={'completedItem'+id} moves={completed} component={<Typography key={'pendingItem'+id} className={classes.paperHeader} color="textPrimary"> <DoneIcon style={{ color: "green" }} className={classes.icon} /> {completed.length} </Typography>}>
                    </MoveAggregateList>
                  </Grid>

                </Grid>
              </Paper>
            </Grid>)
          }
          )}
        </Grid>
      </React.Fragment>
    )
  }
};

MoveAggregate.contextType = GlobalContext;

export default withStyles(styles)(MoveAggregate);