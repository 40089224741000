import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import DriverPayLayout from './DriverPayLayout';
import { Paper } from '@material-ui/core';

let log = false

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
  }
})

class DriverPayPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filteredMoves: [],
      paySum: 0,
      tollsSum: 0,
      durationSum: 0,
      distanceSum: 0,
      durationSumFormatted: 0,
      payPerMove: 0,
      payPerHour: 0,
      payPerMile: 0,
      earningsSummaryNumber: 0,
      returnPaySum: 0,
      accessorials: 0,
    }
  }

  componentDidMount = () => {
    this.handleDriverPayableMoves(this.props.moves);
  }

  componentWillReceiveProps = () => {
    this.handleDriverPayableMoves(this.props.moves);
  }

  handleDriverPayableMoves = (moves) => {
    // initialize payable line items
    let paySum = 0
    let accessorials = 0
    // let returnPaySum = 0
    let tollsSum = 0
    let durationSum = 0
    let distanceSum = 0
    let filteredMoves = []

    moves.map(move => {
      if (log) console.log(`processing move #${move.id} -- `, move)
      if (move.driver_id === this.props.driver.fleet_id && move.active === 1) {
        if (move.status === 'delivery successful' || (move.move_type === 'ride' && move.parent_move && move.parent_move.status === 'delivery successful') || (move.status === 'canceled' && move.accessorials) || (move.cancel_status === 'started' && move.accessorials)) {
          if (log) console.log(`move #${move.id} is payable`)
          if ((move.status === 'canceled' && move.accessorials.length < 1) || (move.cancel_status === 'started' && move.accessorials.length < 1)) {
            if (log) console.log("Canceled move with no accessorial, not adding to filtered list")
          } else filteredMoves.push(move)
          if (move.status !== 'canceled' && move.cancel_status !== 'started') {
            paySum += Number((move.move_type === 'drive') ? move.lane.driver_base_pay : move.lane.driver_return_pay)
            tollsSum += Number(move.lane.tolls)
            durationSum += Number((move.lane.duration_sec != 'undefined') ? move.lane.duration_sec : 0)
            distanceSum += Number((move.lane.distance_miles != 'undefined') ? move.lane.distance_miles : 0)
            paySum += Number((move.move_type === 'drive') ? move.lane.tolls : 0)  // previously was not accounting for tolls
          }
          if (move.accessorials) {
            if (move.accessorials.length > 0) {
              move.accessorials.map(accessorial => {
                accessorials += Number(accessorial.ap_amount);
                paySum += Number(accessorial.ap_amount);
              })
            }
          }
        }
      }
    })

    if (log) console.log('Total duration: ', durationSum)
    if (log) console.log('Total distance: ', distanceSum)
    let durationSumFormatted = moment.utc(durationSum * 1000).format('H:mm:ss')
    let payPerMove = ((moves.length > 0) ? paySum / moves.length : 0)
    let payPerHour = ((durationSum > 0) ? paySum / (durationSum / 60 / 60) : 0)
    let payPerMile = ((distanceSum > 0) ? paySum / distanceSum : 0)
    if (log) console.log('Total payPerMove: ', payPerMove)
    if (log) console.log('Total payPerHour: ', payPerHour)
    if (log) console.log('Total payPerMile: ', payPerMile)

    this.setState({
      filteredMoves: filteredMoves,
      paySum: paySum,
      tollsSum: tollsSum,
      durationSum: durationSum,
      distanceSum: distanceSum,
      durationSumFormatted: durationSumFormatted,
      payPerMove: payPerMove,
      payPerHour: payPerHour,
      payPerMile: payPerMile,
      accessorials: accessorials,
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {this.props.driver.fleet_id && this.state.filteredMoves.length > 0 ?
          <Paper className={classes.paper} elevation={5}>
            <DriverPayLayout
              ref={el => this.props.assignPrintRef(el)}
              driverName={this.props.driver.username}
              driverId={this.props.driver.fleet_id}
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              moves={this.state.filteredMoves}
              paySum={this.state.paySum}
              returnPaySum={this.state.returnPaySum}
              tollsSum={this.state.tollsSum}
              distanceSum={this.state.distanceSum}
              durationSumFormatted={this.state.durationSumFormatted}
              payPerMove={this.state.payPerMove}
              payPerHour={this.state.payPerHour}
              payPerMile={this.state.payPerMile}
              earningsSummaryNumber={this.state.earningsSummaryNumber}
            />
          </Paper>
          : null
        }
      </React.Fragment>
    )
  }
}

DriverPayPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(DriverPayPage)