import React, { useState, useContext, useEffect } from 'react';
import {
  makeStyles,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  OutlinedInput,
  Button,
  Icon,
  Grid,
  Typography,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { GlobalContext } from '../../../global-context';
import DateFnsUtils from '@date-io/date-fns';
import RateRulesTable from './RateRulesTable';
import gql from 'graphql-tag';

const log = true;

let beginDateBefore,
  endDateBefore,
  nameBefore,
  descriptionBefore = null;

export default function RateRuleGroupEditor(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const { customer, rateRuleGroup, onHasChanges } = props;

  const [hasChanges, setHasChanges] = useState(false);
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [nameEditingEnabled, setNameEditingEnabled] = useState(false);
  const [descriptionEditingEnabled, setDescriptionEditingEnabled] = useState(false);
  const [rateRules, setRateRules] = useState(rateRuleGroup && rateRuleGroup.rateRules);
  const [actionsOpen, setActionsOpen] = useState(null);

  useEffect(() => {
    if (!rateRuleGroup) return;
    log && console.log('rateRuleGroup prop changed', rateRuleGroup);

    //Default all working values
    setHasChanges(false);
    beginDateBefore = new Date(rateRuleGroup && rateRuleGroup.begin_date);
    endDateBefore = new Date(rateRuleGroup && rateRuleGroup.end_date);
    nameBefore = rateRuleGroup && rateRuleGroup.name;
    descriptionBefore = rateRuleGroup && rateRuleGroup.description;
    setBeginDate(beginDateBefore);
    setEndDate(endDateBefore);
    setName(nameBefore);
    setRateRules(rateRuleGroup.raterules);
    setDescription(descriptionBefore);
  }, [rateRuleGroup]);

  useEffect(() => {
    if (!beginDate || !endDate || !beginDateBefore || !endDateBefore) return;
    if (beginDateBefore === beginDate && endDateBefore === endDate) return;
    log && console.log('A date has changed');
    setHasChanges(true);
  }, [beginDate, endDate]);

  useEffect(() => {
    if (!name || !nameBefore) return;
    if (nameBefore === name) return;
    log && console.log('The name has changed');
    setHasChanges(true);
  }, [name]);

  useEffect(() => {
    if (!description || !descriptionBefore) return;
    if (descriptionBefore === description) return;
    log && console.log('The description has changed');
    setHasChanges(true);
  }, [description]);

  useEffect(() => {
    log && console.log('HasChanges changed!', hasChanges);
    //if (hasChanges) {  //TODO: Figure out why this if breaks stuff
    onHasChanges();
    //}
  }, [hasChanges]);

  const handleRateRulesChange = rateRules => {
    log && console.log('About to set hasChanges to true');
    setHasChanges(true);
    setRateRules(rateRules);
    log && console.log(`Rate rules changed`, rateRules);
  };

  const duplicate = () => {
    console.log('Duplicating the rate rule group');

    //Everything is in memory, just need to force the upsert to do an insert by removing the id
    rateRuleGroup.id = -1;

    //We need to force the child rate rule rows to go into the mutation by saying they have changes
    // and removing the id from each as well
    console.log('duplicating child rate rules', rateRules);
    for (let index = 0; index < rateRules.length; index++) {
      rateRules[index].hasChanges = true;
      rateRules[index].id = rateRules[index].id * -1;
      rateRules[index].rate_rule_group_id = -1;
    }

    handleSaveChanges();
  };

  const confirmationMessage = `Are you sure you want to duplicate this rate rule group?

Doing so will create a new set with all of the mileage bands below duplicated as well.

If you do, please make sure to prevent overlapping effective and expiration dates of multiple groups.`;
  const globalActions = [
    {
      label: `Duplicate`,
      handler: () => {
        if (window.confirm(confirmationMessage)) duplicate();
      },
    },
  ];

  const handleActionsOpen = event => {
    setActionsOpen(event.currentTarget);
  };

  const handleActionsClose = event => {
    setActionsOpen(null);
  };

  const handleAction = action => {
    handleActionsClose();
    if (action.handler) action.handler();
  };

  const handleSaveChanges = async () => {
    log && console.log('User clicked the save changes button');
    log && console.log('Rate Rule Group prior to mutation prep: ', rateRuleGroup);

    //Build up a new object for mutation
    let rateRuleGroupForMutation = {
      ...rateRuleGroup,
      begin_date: beginDate.toISOString(),
      end_date: endDate.toISOString(),
      customer_id: customer.id,
      name: name,
      description: description,
    };

    //Remove properties we do not intend to update in the mutation
    delete rateRuleGroupForMutation.updatedat;
    delete rateRuleGroupForMutation.createdat;
    delete rateRuleGroupForMutation.raterules;
    if (rateRuleGroupForMutation.id < 1 || !rateRuleGroupForMutation.id) delete rateRuleGroupForMutation.id;
    delete rateRuleGroupForMutation.__typename;

    log && console.log('Rate Rule Group prepared for mutation: ', JSON.stringify(rateRuleGroupForMutation, null, 2));

    let newRateGroupCreated = false;
    try {
      const res = await pushRateRuleGroupToServer(rateRuleGroupForMutation);
      if (res) {
        const returned = res.data.insert_raterulegroups.returning[0];
        log && console.log(`pushRateRuleGroupToServer returned:`, returned);
        newRateGroupCreated = rateRuleGroupForMutation.id != returned.id;
        rateRuleGroupForMutation.id = returned.id;
        ctx.handleNotifications(true, `success`, `The rate rule group was saved successfully`);
      }
    } catch (error) {
      ctx.handleNotifications(true, `error`, `Failed to save the rate rule group: ${error.toString()}`);
      console.error('Something went wrong running the raterulegroup mutation', error);
    }

    log && console.log('Rate Rules prior to mutation prep: ', rateRules);

    //Remove any temporary hasChanges properties from rate rule rows
    let rateRulesForMutation = [];
    if (rateRules && rateRules.length > 0) {
      rateRules
        .filter(o => o.hasChanges)
        .forEach(rateRule => {
          let rateRuleForMutation = {
            ...rateRule,
            rate_rule_group_id: rateRuleGroupForMutation.id,
            customer_id: customer.id,
          };
          delete rateRuleForMutation.hasChanges;
          delete rateRuleForMutation.__typename;
          if (rateRuleForMutation.id < 1 || !rateRuleForMutation.id) delete rateRuleForMutation.id;
          rateRulesForMutation.push(rateRuleForMutation);
        });
    }

    setNameEditingEnabled(false);
    setDescriptionEditingEnabled(false);

    log && console.log('Rate Rules prepared for mutation: ', JSON.stringify(rateRulesForMutation, null, 2));

    if (rateRulesForMutation.length > 0) {
      try {
        const res = await pushRateRulesToServer(rateRulesForMutation);
        if (res) {
          const returned = res.data.insert_raterules.returning[0];
          log && console.log(`pushRateRulesToServer returned:`, returned);
          ctx.handleNotifications(true, `success`, `The changed rate rules were saved successfully`);
        }
      } catch (error) {
        ctx.handleNotifications(true, `error`, `Failed to save the rate rules: ${error.toString()}`);
        console.error('Something went wrong running the raterules mutation', error);
      }
    } else {
      log && console.log(`Skipping rate rules mutation because there was nothing to update.`);
    }
  };

  const pushRateRuleGroupToServer = async rateRuleGroupForMutation => {
    const updateCols = Object.keys(rateRuleGroupForMutation).filter(key => key !== 'id');
    return ctx.apolloClient.mutate({
      mutation: gql`
        mutation upsertRateRuleGroup(
          $rateRuleGroup: [raterulegroups_insert_input!]!
          $cols: [raterulegroups_update_column!]!
        ) {
          insert_raterulegroups(
            objects: $rateRuleGroup
            on_conflict: { constraint: idx_25769_primary, update_columns: $cols }
          ) {
            affected_rows
            returning {
              id
            }
          }
        }
      `,
      variables: {
        rateRuleGroup: [rateRuleGroupForMutation],
        cols: updateCols,
      },
    });
  };

  const pushRateRulesToServer = async rateRulesForMutation => {
    const updateCols = Object.keys(rateRulesForMutation[0]).filter(key => key !== 'id');
    return ctx.apolloClient.mutate({
      mutation: gql`
        mutation upsertRateRules($rateRules: [raterules_insert_input!]!, $cols: [raterules_update_column!]!) {
          insert_raterules(objects: $rateRules, on_conflict: { constraint: idx_21717_primary, update_columns: $cols }) {
            affected_rows
            returning {
              id
            }
          }
        }
      `,
      variables: {
        rateRules: rateRulesForMutation,
        cols: updateCols,
      },
    });
  };

  return (
    <div className={cls.root}>
      {rateRuleGroup && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container direction='row' justify='flex-start' alignItems='flex-start' spacing={2}>
            <Grid item md={8}>
              {nameEditingEnabled && (
                <FormControl fullWidth className={cls.margin} variant='outlined'>
                  <OutlinedInput
                    id='name'
                    value={name}
                    onChange={e => {
                      setName(e.target.value);
                    }}
                  />
                </FormControl>
              )}
              {!nameEditingEnabled && (
                <Typography
                  className={cls.headTxt}
                  style={{ textAlign: 'left' }}
                  onClick={() => {
                    setNameEditingEnabled(true);
                  }}
                >
                  {rateRuleGroup.name}
                </Typography>
              )}
            </Grid>
            <Grid item md={3}>
              <Typography className={cls.headTxt} style={{ textAlign: 'right' }} component='div'>
                <Button
                  variant='outlined'
                  color='primary'
                  size='medium'
                  startIcon={<Icon>check_circle</Icon>}
                  onClick={handleSaveChanges}
                  disabled={!hasChanges}
                >
                  Save Changes
                </Button>
              </Typography>
            </Grid>
            <Grid item md={1}>
              <Tooltip placement='top' title={`Actions`}>
                <IconButton
                  style={{ marginTop: '-4px', color: ctx.theme.palette.text.secondary }}
                  className={cls.iconBtn}
                  onClick={handleActionsOpen}
                >
                  <Icon>settings</Icon>
                </IconButton>
              </Tooltip>
              <Menu
                keepMounted
                id={`customer-actions-menu`}
                anchorEl={actionsOpen}
                open={Boolean(actionsOpen)}
                onClose={handleActionsClose}
              >
                {globalActions.map((action, i) =>
                  !action.hide ? (
                    <MenuItem key={`customer-action-${i}`} onClick={() => handleAction(action)}>
                      {action.label || `Action ${i + 1}`}
                    </MenuItem>
                  ) : null
                )}
              </Menu>
            </Grid>
            <Grid item md={12}>
              {descriptionEditingEnabled && (
                <FormControl fullWidth className={cls.margin} variant='outlined'>
                  <OutlinedInput
                    id='description'
                    value={description}
                    onChange={e => {
                      setDescription(e.target.value);
                    }}
                  />
                </FormControl>
              )}
              {!descriptionEditingEnabled && (
                <Typography
                  variant='caption'
                  gutterBottom
                  onClick={() => {
                    setDescriptionEditingEnabled(true);
                  }}
                >
                  {rateRuleGroup.description}
                </Typography>
              )}
            </Grid>
            <Grid item md={6}>
              <DatePicker
                autoOk
                orientation='portrait'
                format='MM/dd/yyyy'
                label='Effective Date'
                margin='dense'
                inputVariant='outlined'
                value={beginDate}
                onChange={setBeginDate}
                className={cls.date}
                style={beginDateBefore != beginDate ? { backgroundColor: '#ffe9ec' } : {}}
              />
            </Grid>

            <Grid item md={6}>
              <DatePicker
                autoOk
                orientation='portrait'
                format='MM/dd/yyyy'
                label='End Date'
                margin='dense'
                inputVariant='outlined'
                value={endDate}
                onChange={setEndDate}
                className={cls.date}
                style={endDateBefore != endDate ? { backgroundColor: '#ffe9ec' } : {}}
              />
            </Grid>

            <Grid item md={12}>
              <RateRulesTable rateRules={rateRuleGroup.raterules} onChange={handleRateRulesChange} />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      )}
    </div>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    height: '100%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  input: {
    margin: 0,
  },
  date: {
    width: '100%',
    margin: 0,
  },
  iconBtn: {
    verticalAlign: 'top',
    display: 'inline-block',
    marginTop: '-12px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-14px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-16px',
    },
  },
  newVersionBtn: {
    verticalAlign: 'top',
    marginTop: '-6px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  headTxt: {
    //lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    cursor: 'default',
  },
}));
