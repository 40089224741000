import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';

////////// COMPONENT //////////
export default function LocationDetailsInfo(props) {
  const cls = useStyles();
  const { location } = props;

  const getType = () => {
      if (location.type === `customer`) return `Standard`;
      else if (location.type === `consumer business`) return `Consumer Business`;
      else if (location.type === `consumer residential`) return `Consumer Residential`;
      else return `-`;
  }

  return (<>
    <div className={cls.main}>
      <div className={cls.paper}>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={cls.headTxt} style={{ textAlign: 'left' }}>Information</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={cls.headTxt} style={{ textAlign: 'right' }}>Location #{location.id}</Typography>
          </Grid>
        </Grid>

        <div className={cls.break} />

        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Name:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{location.name || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Address:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{location.address || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Region:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{location.region ? `${location.region.id} - ${location.region.name}` : `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Nickname:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{location.nickname || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Email:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{location.email || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Phone:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{location.phone || `-`}</Typography>
            </div>
          </Grid>

          <div className={cls.hiddenBreak} />
          
          <Grid item md={6} xs={12}>
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Type:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{getType()}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Dealer:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{location.customer && location.customer.name ? location.customer.name : `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Google&nbsp;Place&nbsp;ID:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{location.place_id || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Latitude:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{location.latitude || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Longitude:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{location.longitude || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Favorite:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{location.favorite ? `Yes` : `No`}</Typography>
            </div>
          </Grid>
        </Grid>

      </div>
    </div >
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#e0e0e0',
  },
  hiddenBreak: {
    display: 'none',
    width: '100%',
    height: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));