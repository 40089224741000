import React from 'react';
import { Typography, Popover, TextField } from '@material-ui/core';
import { GlobalContext } from '../../global-context';
import gql from "graphql-tag";
import fragments from '../utils/graphQL/fragments';

// Component requires the prop 'location' which should be the full location object as retrieved from Hasura

// Takes in the props 'primary' and 'secondary' to change font size
// Passing in either will overwrite the corresponding default text size
// These are integers that represent the % font size to use

// Also takes in a prop of noAddress, which if present will disable the rendering of the secondary address text

/* 
  If the component needs to override an onClick handler defined in the parent component, do the following: 
    1) Create a state variable in the parent component called 'preventClick' with an initial value of false
    2) Create the following function in the parent component: 
         handlePreventClick = () => this.setState({ preventClick: !this.state.preventClick })
    3) Pass this function in as a prop named 'handlePreventClick'
*/

/* 
  If the component needs to update a parent state location object (i.e. the location prop is not from a subscription response):
    1) Define a function in the parent component that updates the location object in state by id (may vary by use case)
         Example from MoveForm.js (this takes in the new location object returned from Hasura): 
            updateLocationById = (location) => {
              let { pickup, delivery } = this.state;
              if (!location || !pickup || !delivery) return;
              if (pickup.id === location.id) this.setState({ pickup: location });
              if (delivery.id === location.id) this.setState({ delivery: location });
            }
    2) Pass this function in as a prop named 'updateLocationById'
*/

let primarySize = 110,
  secondarySize = 80;

class LocationWithNickname extends React.Component {

  state = {
    anchorEl: null,
  };

  componentWillMount = () => {
    if (this.props.primary) primarySize = this.props.primary;
    if (this.props.secondary) secondarySize = this.props.secondary;
  }

  handleRightClick = e => {
    e.preventDefault();
    this.setState({ anchorEl: e.currentTarget }, () => {
      if (this.props.handlePreventClick) this.props.handlePreventClick();
    });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null }, () => {
      if (this.props.handlePreventClick) this.props.handlePreventClick();
    });
  };

  handleFormSubmit = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.updateLocationNickname(this.props.location, event.target.value);
      this.handleMenuClose();
    }
  }

  updateLocationNickname = (location, nickname) => {
    this.context.apolloClient.mutate({
      mutation: gql`mutation 
        updateLocationNickname(
          $id: bigint!,
          $nickname: String!
        ) {
          update_locations(where: { id: { _eq: $id } }
            _set: {nickname: $nickname, updatedat: "now()"}) {
              returning {
                ...Location
              }
            }
          }
          ${fragments.location}
        `,
      variables: { id: location.id, nickname: nickname }
    }).then(data => {
      const location = data.data.update_locations.returning[0];
      if (location && this.props.updateLocationById) this.props.updateLocationById(location);
    })
  }

  render() {

    const name = this.props.location ? (this.props.location.nickname && this.props.location.name ? this.props.location.nickname + " (" + this.props.location.name + ")" : (this.props.location.name ? this.props.location.name : null)) : null;
    const address = this.props.location ? (this.props.location.address ? this.props.location.address : null) : null;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <>
        <Typography
          component="div"
          onContextMenu={this.handleRightClick}
          aria-owns={open ? 'rightClickMenu' : undefined}
          aria-haspopup="true"
          inline="true"
          style={{ fontSize: primarySize.toString() + "%" }}
          color="textPrimary"> {name}
          {
            this.props.noAddress
              ? null
              : <Typography inline="true" component="div" style={{ fontSize: secondarySize.toString() + "%" }} color="textSecondary"> {address} </Typography>
          }
        </Typography>

        <Popover
          id="rightClickMenu"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <TextField
            style={{
              margin: "10px",
            }}
            defaultValue={this.props.location ? this.props.location.nickname : null || null}
            id="standard-helperText"
            onKeyPress={(event) => this.handleFormSubmit(event)}
            label="Nickname"
            helperText="Assign a nickname for this location"
            margin="normal"
          />
        </Popover>
      </>
    )
  }

}

LocationWithNickname.contextType = GlobalContext

export default LocationWithNickname; 