import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { TextField, Grid, InputAdornment, Button, Typography, Paper } from "@material-ui/core";
import InvoiceWrapper from "./InvoiceWrapper";
import moment from 'moment';
import ReactToPrint from "react-to-print";
import PrintIcon from '@material-ui/icons/Print';

export default function CarmaxInvoice(props) {

  const classes = useStyles();
  const [accSuffix, setAccSuffix] = useState("202605")
  const [printRef, assignPrintRef] = useState(null)

  function handleInputChange(e) {
    console.log(e.target.value)
    setAccSuffix(e.target.value)
  }

  function handleARMoves(armoves) {
    let moves = [];
    try {
      armoves.map(o => {
        if (props.checkedItems.includes(o.id)) moves.push(o)
      })
    } catch (err) {
      console.error("Could not parse moves from armoves array")
    }
    return moves;
  }

  return (
    <>
      <Grid container className={classes.grid} justify="space-around" alignItems="center">
        <Grid item xs={6}>
          <TextField
            style={{ position: "relative", left: "8%" }}
            id="outlined-account-suffix"
            label="Account Suffix"
            className={classes.textField}
            value={accSuffix}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">#</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <ReactToPrint
            trigger={() =>
              <Button size="small" variant="contained" color="primary" className={classes.button}>
                <PrintIcon className={classes.leftIcon} />
               Print
            </Button>
            }
            pageStyles={'@page { size: auto;  margin: 4cm; } @media print { body { -webkit-print-color-adjust: exact; } }'}
            content={() => printRef}
          />
        </Grid>
      </Grid>
      {props.checkedItems.length > 0 ?
        <InvoiceWrapper
          moves={handleARMoves(props.invoice.armoves)}
          customer={props.invoice.customer}
          startDate={moment(props.invoice.start_datetime)}
          endDate={moment(props.invoice.end_datetime)}
          assignPrintRef={assignPrintRef}
          invoiceNumber={props.invoice.id.toString().padStart(6, "0")}
          accountSuffix={accSuffix}
        />
        : <Typography style={{ margin: "auto", paddingBottom: 50 }} variant="h3">No AR move rows selected.</Typography>}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  grid: {
    width: '100%',
    marginBottom: theme.spacing(7.5)
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));