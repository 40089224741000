import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, Box, Typography, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Chip, Icon, LinearProgress, Button } from '@material-ui/core';
import moment from 'moment'
import { GlobalContext } from '../../../global-context';
import fragments from '../../utils/graphQL/fragments'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import axios from 'axios'

const log = false

export default function SendEmailButton({ payPeriod, refetch }) {
    const ctx = useContext(GlobalContext);
    const cls = useStyles();

    const [sent, setSent] = useState(false)

    const sendEmail = async (appayments) => {
        if (!appayments) {
            console.error('There are no appayments to send')
            return
        }
        try {
            await axios({
                method: `POST`,
                url: `/.netlify/functions/handleDriverPayEmail`,
                data: {
                    driverId: payPeriod.driver_id,
                    moveCount: appayments.length,
                    paidAmount: Number(appayments.reduce((a, b) => a + (b['amount'] || 0), 0)).toFixed(2),
                    startDate: moment(payPeriod.pickup_time_start).format('MM/DD/YYYY'),
                    endDate: moment(payPeriod.pickup_time_end).format('MM/DD/YYYY'),
                    payments: formatPaymentData(appayments)
                },
                headers: {
                    'authorization': `Bearer ${ctx.userToken}`
                }
            }).then(res => {
                log && console.log(`Successfully sent email`, res);
                if (refetch) refetch()
                setSent(true)
                // ctx.handleNotifications(true, "success", "Successfully sent email")
                return true
            }).catch(err => {
                console.error(`Error occured when attempting to send email:`, err);
                ctx.handleNotifications(true, "error", "Failed to send email: " + err.toString())
                return false
            })
        }
        catch (err) {
            console.error(`Error occured when attempting to send email:`, err);
            ctx.handleNotifications(true, "error", "Error occured when attempting to send email: " + err.toString())
            return false
        }
    }

    //formats the selected appayments for the table in the driver email
    const formatPaymentData = (paymentsArr) => {
      return paymentsArr.map(o => {
        if (o.type === 'move pay') {
          return {
            move: o.move.id,
            pickupDate: moment(o.move.pickup_time).format("MM/DD/YYYY"),
            pickupTime: moment(o.move.pickup_time).format("LT"),
            type: `${o.type} (${o.move.move_type})`,
            description: o.move.lane.description,
            distance: `${o.move.lane.distance_miles} mi`,
            totalPay: o.amount.toFixed(2)
          }
        } else if (o.type === 'accessorial') {
          return {
            move: o.move.id,
            pickupDate: moment(o.move.pickup_time).format("MM/DD/YYYY"),
            pickupTime: moment(o.move.pickup_time).format("LT"),
            type: `${o.type} (${o.accessorial.code})`,
            description: o.accessorial.notes,
            distance: '-',
            totalPay: o.amount.toFixed(2)
          }
        } else {
          return {
            move: o.move.id,
            pickupDate: moment(o.move.pickup_time).format("MM/DD/YYYY"),
            pickupTime: moment(o.move.pickup_time).format("LT"),
            type: `${o.notes}`,
            description: o.notes,
            distance: '-',
            totalPay: o.amount.toFixed(2)
          }
        }
      })
    }

    return (
        <Query query={GET_APPAYMENTS} variables={{ driverId: payPeriod.driver_id, start: payPeriod.pickup_time_start , end: payPeriod.pickup_time_end  }}>
            {({ loading, error, data, refetch }) => {
                return (
                    <Button disabled={loading || error || sent || (data && data.appayments && data.appayments.length == 0)} size="small" variant="outlined" endIcon={<Icon fontSize='small'>send</Icon>} color="secondary" onClick={() => sendEmail(data.appayments)}>Send Email</Button>
                )
            }}
        </Query>
    )
}


const useStyles = makeStyles(theme => ({
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));

const GET_APPAYMENTS = gql`
query get_appayments_for_sending_driver_email($driverId: bigint, $start: timestamptz, $end: timestamptz) {
  appayments(where: {
    move: {
      active: {_eq: 1},
      pickup_time: {_gte: $start, _lte: $end},
      _or: [{cancel_status: {_neq: "canceled"}}, {cancel_status: {_is_null: true}}]
    },
    active: {_eq: 1},
    driver_id: {_eq: $driverId},
  }, order_by: {move: {pickup_time: desc}}) {
    ...APPayment
    move {
      ...Move
    }
    accessorial {
      ...Accessorials
    }
  }
}
${fragments.move}
${fragments.appayment}
${fragments.accessorials}
`;