import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import { GlobalContext } from '../../../global-context';
import {
  makeStyles,
  withStyles,
  Grid,
  Typography,
  Tooltip,
  Switch,
  InputBase,
  Icon,
  MenuItem,
  Chip,
  Card,
  CardHeader,
  Avatar,
  CardMedia,
  CardContent,
  Select,
  FormControl,
} from '@material-ui/core';
import GoogleStaticMapURL from '../../utils/GoogleStaticMapUrl';

function handleEmails(email) {
  let primary = email;
  let cc = null;
  try {
    if (email.includes(',')) {
      cc = email.split(',').filter(i => i.trim() !== '');
      if (cc.length > 0) {
        primary = cc[0].trim();
        cc.shift(); //Delete the first entry in the array (the TO)
      }
    }
  } catch (e) {
    console.error(e);
    primary = email;
    cc = null;
  }
  return { primary, cc };
}

////////// COMPONENT //////////
function CustomerDetailsInfo(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();
  const { customer, editMode, inMemoryCustomer, handleInMemoryCustomer } = props;

  const staticMapUrl = GoogleStaticMapURL.buildWithAddress({
    key: ctx.userProfile['https://api_keys.io/jwt/claims']['GoogleMapsKey'],
    marker: {
      icon: 'https://s3.ap-south-1.amazonaws.com/social-auto/acknowledgement_images/fkpH1532077213498-faviconsavein32x32.png',
      shadow: true,
      address: customer.address,
    },
    center: customer.address,
    zoom: 'auto',
    scale: false,
    size: '448x248',
    maptype: 'roadmap',
    format: 'png',
    visual_refresh: true,
  });

  const AntSwitch = withStyles(theme => ({
    root: {
      verticalAlign: 'top',
      float: 'right',
      display: 'flex',
      width: '36px',
      height: '17px',
      padding: 0,
      transition: '0.2s',
    },
    switchBase: {
      padding: '2px',
      color: theme.palette.text.secondary,
      '&$checked': {
        transform: 'translateX(19px)',
        color: '#fff',
        '& + $track': {
          borderColor: editMode ? theme.palette.primary.main : theme.palette.text.secondary,
          backgroundColor: editMode ? theme.palette.primary.main : theme.palette.text.secondary,
          opacity: 1,
        },
      },
    },
    thumb: {
      width: '13px',
      height: '13px',
      boxShadow: 'none',
    },
    track: {
      border: `1px solid #ddd`,
      borderRadius: '8.5px',
      backgroundColor: '#fff',
      opacity: 1,
    },
  }))(Switch);

  return (
    <>
      <div className={cls.main}>
        {ctx.width === `sm` || ctx.width === `xs` ? <div className={cls.bigBreak} /> : null}

        <Grid container spacing={2}>
          <Grid item md={8} sm={6} xs={12}>
            <div className={cls.paper}>
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  <Tooltip placement='top-end' title='Customer ID'>
                    <Typography className={cls.headTxt} style={{ textAlign: 'left' }}>
                      #{customer.id}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={cls.headTxt} style={{ textAlign: 'left' }}>
                    {customer.name}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={cls.headTxt} style={{ textAlign: 'right' }} component='div'>
                    <Chip
                      variant={customer.active ? 'default' : 'outlined'}
                      icon={customer.active ? null : <Icon fontSize='small'>close</Icon>}
                      size='small'
                      color={customer.active ? 'primary' : 'secondary'}
                      label={customer.active ? 'active' : 'inactive'}
                    />
                  </Typography>
                </Grid>
              </Grid>

              <div className={cls.break} />
              {!editMode ? ( // Read-only copy of customer data
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>Name:&nbsp;&nbsp;</Typography>
                      <Typography className={cls.valTxt}>{customer.name}</Typography>
                    </div>
                    <div className={cls.lineBreak} />
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>Address:&nbsp;&nbsp;</Typography>
                      <Typography className={cls.valTxt}>{customer.address}</Typography>
                    </div>
                    <div className={cls.lineBreak} />
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>Phone:&nbsp;&nbsp;</Typography>
                      <Typography className={cls.valTxt}>{customer.phone}</Typography>
                    </div>
                    <div className={cls.lineBreak} />
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>Primary&nbsp;Billing&nbsp;Email:&nbsp;&nbsp;</Typography>
                      <Typography className={cls.valTxt}>{handleEmails(customer.email).primary}</Typography>
                    </div>
                    <div className={cls.lineBreak} />
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>Additional&nbsp;CC'd&nbsp;Emails:&nbsp;&nbsp;</Typography>
                      <Typography className={cls.valTxt}>
                        {handleEmails(customer.email).cc ? handleEmails(customer.email).cc.join() : 'N/A'}
                      </Typography>
                    </div>
                    <div className={cls.lineBreak} />
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>Billing&nbsp;Frequency:&nbsp;&nbsp;</Typography>
                      <Typography className={cls.valTxt}>{customer.billing_frequency}</Typography>
                    </div>
                    <div className={cls.lineBreak} />
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>Payment&nbsp;Terms:&nbsp;&nbsp;</Typography>
                      <Typography className={cls.valTxt}>{customer.payment_terms}</Typography>
                    </div>
                    <div className={cls.lineBreak} />
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>Auto&nbsp;Pay:&nbsp;&nbsp;</Typography>
                      <div className={cls.switch}>
                        <AntSwitch disabled name='auto_pay' checked={customer.auto_pay} />
                      </div>
                    </div>
                    <div className={cls.lineBreak} />
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>Notify&nbsp;Billing:&nbsp;&nbsp;</Typography>
                      <div className={cls.switch}>
                        <AntSwitch disabled name='notify_billing' checked={customer.notify_billing} />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                // Editable copy of customer data
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>Name:&nbsp;&nbsp;</Typography>
                      <InputBase
                        name='name'
                        value={inMemoryCustomer.name}
                        onChange={handleInMemoryCustomer}
                        className={cls.nakedValTxt}
                        inputProps={{ className: cls.nakedValInput }}
                      />
                    </div>
                    <div className={cls.lineBreak} />
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>Address:&nbsp;&nbsp;</Typography>
                      <InputBase
                        name='address'
                        value={inMemoryCustomer.address}
                        onChange={handleInMemoryCustomer}
                        className={cls.nakedValTxt}
                        inputProps={{ className: cls.nakedValInput }}
                      />
                    </div>
                    <div className={cls.lineBreak} />
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>Phone:&nbsp;&nbsp;</Typography>
                      <InputBase
                        name='phone'
                        value={inMemoryCustomer.phone}
                        onChange={handleInMemoryCustomer}
                        className={cls.nakedValTxt}
                        inputProps={{ className: cls.nakedValInput }}
                      />
                    </div>
                    <div className={cls.lineBreak} />
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>Email&nbsp;List:&nbsp;&nbsp;</Typography>
                      <InputBase
                        name='email'
                        value={inMemoryCustomer.email}
                        onChange={handleInMemoryCustomer}
                        className={cls.nakedValTxt}
                        inputProps={{ className: cls.nakedValInput }}
                      />
                    </div>
                    <div className={cls.lineBreak} />
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>&nbsp;</Typography>
                      <Typography color='textSecondary' className={cls.valTxt}>
                        Enter a comma-delimited list of emails, with the primary email first
                      </Typography>
                    </div>
                    <div className={cls.lineBreak} />
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>Billing&nbsp;Frequency:&nbsp;&nbsp;</Typography>
                      <FormControl className={cls.nakedForm}>
                        <Select
                          className={cls.nakedSelect}
                          value={inMemoryCustomer.billing_frequency}
                          onChange={handleInMemoryCustomer}
                          name='billing_frequency'
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                        >
                          <MenuItem value={'daily'}>daily</MenuItem>
                          <MenuItem value={'weekly'}>weekly</MenuItem>
                          <MenuItem value={'monthly'}>monthly</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className={cls.lineBreak} />
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>Payment&nbsp;Terms:&nbsp;&nbsp;</Typography>
                      <InputBase
                        name='payment_terms'
                        value={inMemoryCustomer.payment_terms}
                        onChange={handleInMemoryCustomer}
                        className={cls.nakedValTxt}
                        inputProps={{ className: cls.nakedValInput }}
                      />
                    </div>
                    <div className={cls.lineBreak} />
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>Auto&nbsp;Pay:&nbsp;&nbsp;</Typography>
                      <div className={cls.switch}>
                        <AntSwitch
                          name='auto_pay'
                          checked={inMemoryCustomer.auto_pay}
                          onChange={handleInMemoryCustomer}
                        />
                      </div>
                    </div>
                    <div className={cls.lineBreak} />
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>Notify&nbsp;Billing:&nbsp;&nbsp;</Typography>
                      <div className={cls.switch}>
                        <AntSwitch
                          name='notify_billing'
                          checked={inMemoryCustomer.notify_billing}
                          onChange={handleInMemoryCustomer}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid>

          <Grid item md={4} sm={6} xs={12}>
            <Card className={cls.paper}>
              <CardHeader
                avatar={
                  <Avatar aria-label='recipe' className={cls.avatar}>
                    {customer.name[0]}
                  </Avatar>
                }
                title='Map View'
              />
              <CardMedia className={cls.media} image={staticMapUrl} title={customer.address} />
              <CardContent>
                <Typography variant='body2' color='textSecondary' component='p'>
                  {customer.address}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {ctx.width === `sm` || ctx.width === `xs` ? <div className={cls.bigBreak} /> : null}
      </div>

      <div className={cls.bigBreak} />
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  nakedValTxt: {
    width: '100%',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  nakedForm: {
    width: '100%',
    minHeight: '17px',
    maxHeight: '17px',
    textAlign: 'right',
  },
  nakedSelect: {
    fontSize: 14,
    fontWeight: 400,
    minHeight: '17px',
    maxHeight: '17px',
    padding: 0,
    background: '#00000008',
    textAlign: 'right',
  },
  nakedValInput: {
    width: '100%',
    minHeight: '17px',
    maxHeight: '17px',
    padding: 0,
    background: '#00000008',
    textAlign: 'right',
  },
  switch: {
    width: '100%',
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#eee',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
}));

////////// EXPORT //////////
export default withRouter(CustomerDetailsInfo);
