import axios from "axios";

const auth = {

  /**
   * Update a user's app_metadata in Auth0. Will perform an upsert on submitted object properties.
   *
   * @param {String} user - The username to be updated (retrived from the userProfile global context variable)
   * @param {Object} metadata - The metadata to upsert. e.g.: metadata = { "regions": [1,2,3], "customer_id": 2 }
   * @return {Response} - Returns the response from the Netlify function called (updateUserMetadata)
   */
  updateMetadata: (user, metadata) => {
    return axios.post(`/.netlify/functions/updateUserMetadata`, { user: user, metadata: metadata })
  }
};

export default auth;
