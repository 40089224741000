import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

// ReactSelectAutocomplete class begins on line 165
// Some of the functional components are not utilized but allow for additional functionality single-value inputs
/**
 * Controlled Autocomplete form componet using react-select
 * required props are:
 * options- array of options for dropdown
 * onChange(props.name)- function to handle changing of prop value in textfield
 * onSelectChange(props.name)- function to handle changing of prop value from dropdown
 * name- name of prop value to change
 * <prop>- prop value to change, its name should equal the name prop's value
 * placeholder- placeholder text
 */

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: 200,
    },
    longRoot: {
        flexGrow: 1,
        width: 700,
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
});

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};

const generateOptions = (array) => {
    return array.map(item => ({
        label: item,
        value: item
    }))
}
const generateValueLabelOptions = (array) => {
    return array.map(item => ({
        label: item.label,
        value: item.value
    }))
}

function ReactSelectAutocomplete(props) {

    const { classes, theme } = props;

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    };

    return (
        <div className={props.long? classes.longRoot : classes.root}>
            <NoSsr>
                <Select
                    value={props[props.name]}
                    displayEmpty={true}
                    classes={classes}
                    styles={selectStyles}
                    options={props.valueOptions ? generateValueLabelOptions(props.valueOptions) : generateOptions(props.options)}
                    components={components}
                    textFieldProps={{
                        onChange: props.onChange(props.name),
                        value: props[props.name],
                        InputLabelProps: {
                            shrink: true,
                        },
                    }}
                    inputValue={props[props.name] ? props[props.name] : ''}
                    onChange={props.onSelectChange(props.name)}
                    placeholder={props.placeholder}
                    openMenuOnFocus={true}
                    isClearable={true}
                    id={`react-select-${props.name}`}
                />
                <div className={classes.divider} />
            </NoSsr>
        </div>
    );
}

ReactSelectAutocomplete.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ReactSelectAutocomplete);
