//import Pricing from "./Pricing";
import gql from "graphql-tag";
import fragments from "./graphQL/fragments";

const LaneHelper = {
    updateMultiple: async function(client, lanes) {
      if (!Array.isArray(lanes)) {
        console.error('LaneHelper.updateMultiple lanes param is not at array')
        return null
      }
      if (!lanes.length > 0) {
        console.error('LaneHelper.updateMultiple lanes param array is empty')
        return null
      }
      const cols = Object.keys(lanes[0]).map(key => key);
      const UPDATE_MULTIPLE = gql`
      mutation updateMultiple($lanes: [lanes_insert_input!]!, $cols: [lanes_update_column!]!) { 
        insert_lanes (
          objects: $lanes,
          on_conflict: {
            constraint: lanes_pkey,
            update_columns: $cols
          }
        ) {
          affected_rows
          returning {
            id
            pickup{
              id
            }
            delivery{
              id
            }
          }
        }
      }
      `
      return await client.mutate({
        mutation: UPDATE_MULTIPLE,
        variables: {
          lanes: lanes, 
          cols: cols
        }
      })
    },
    getLane: async function(client, id) {
      const MY_QUERY = gql`
        query get_lane($id: bigint!) {
          lanes(where: {id: {_eq: $id}}) {
            ...Lane
          }
        }      
        ${fragments.lane}
      `;

      const res = await client.query({
        query: MY_QUERY,
        variables: {id: id}
      })

      return res.data.lanes[0]
    },
    getLocation: async function(client, id) {
      const MY_QUERY = gql`
        query get_location($id: bigint!) {
          locations(where: {id: {_eq: $id}}) {
            ...Location
          }
        }      
        ${fragments.location}
      `;

      const res = await client.query({
        query: MY_QUERY,
        variables: {id: id}
      })

      return res.data.locations[0]
    },
    getLaneByLocations: async function(client, pickup_id, delivery_id) {
      const MY_QUERY = gql`
        query get_lane($pickup_id: bigint!, $delivery_id: bigint!) {
          lanes(where: {destination_location_id: {_eq: $delivery_id}, origin_location_id: {_eq: $pickup_id}}) {
            ...Lane
          }
        }      
        ${fragments.lane}
      `;

      const res = await client.query({
        query: MY_QUERY,
        variables: {pickup_id: pickup_id, delivery_id: delivery_id},
        fetchPolicy: "network-only" //bypasses cache and checks db directly     
      })

      return res.data.lanes[0]
    },
    getLaneByFuzzyLocationNamesAndCustomerId: async function(client, pickup_name, delivery_name, customer_id) {
      const MY_QUERY = gql`
        query get_lane($pickup_name: String!, $delivery_name: String!, $customer_id: bigint!) {
          lanes(
            where: {
              customer_id: {
                _eq: $customer_id
              }, 
              _and: [
                {pickup: {
                  name: {
                    _ilike: $pickup_name
                  }
                }},
                {delivery: {
                  name: {
                    _ilike: $delivery_name
                  }
                }}
              ]
            }
          ) {
            ...Lane
          }
        }      
        ${fragments.lane}
      `;

      const res = await client.query({
        query: MY_QUERY,
        variables: {pickup_name: pickup_name, delivery_name: delivery_name, customer_id: customer_id}
      })

      return res.data.lanes[0]
    }

};

export default LaneHelper;