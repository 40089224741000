import React, { useContext } from 'react';
import { GlobalContext } from '../../global-context';

import { makeStyles, Grid, Typography } from '@material-ui/core';

const log = false;

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  // Small Classes
  sm_step1: {
    position: 'relative',
    height: '100%',
    borderRadius: '20px 0 0 20px',
    marginRight: theme.spacing(1),
    background: '#00000050',
    '&::after': {
      content: `content`,
      position: 'absolute',
      right: 0,
      background: theme.palette.secondary.light,
    },
  },
  sm_step2: {
    position: 'relative',
    height: '100%',
    background: theme.palette.secondary.light,
  },
  sm_step3: {
    position: 'relative',
    height: '100%',
    borderRadius: '0 20px 20px 0',
    marginLeft: theme.spacing(1),
    background: theme.palette.secondary.light,
  },
  sm_stepTxt: {
    position: 'absolute',
    top: '35%',
    left: 0,
    right: 0,
    color: '#fff',
    textAlign: 'center',
    lineHeight: 1,
    fontSize: '12px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },

  // Large Classes
  lg_step1: {
    position: 'relative',
    height: '100%',
    borderRadius: '48px 0 0 48px',
    marginRight: theme.spacing(1.5),
    background: '#00000050',
    '&::after': {
      content: `content`,
      position: 'absolute',
      right: 0,
      background: '#00000050',
    },
  },
  lg_step2: {
    position: 'relative',
    height: '100%',
    background: '#00000050',
  },
  lg_step3: {
    position: 'relative',
    height: '100%',
    borderRadius: '0 48px 48px 0',
    marginLeft: theme.spacing(1.5),
    background: '#00000050',
  },
  lg_stepTxt: {
    position: 'absolute',
    top: '32.5%',
    left: 0,
    right: 0,
    color: '#fff',
    textAlign: 'center',
    lineHeight: 1,
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));

////////// COMPONENT //////////
export default function MoveStatusTracker(props) {
  const { theme } = useContext(GlobalContext);
  const cls = useStyles();
  const { move, size } = props;

  let cancelStatus = move.cancel_status;
  let status = move.status;

  let label = ``;
  let step = 0;
  let pulse = true;

  if (status) {
    if (status === `dispatched`) {
      label = `Planned`;
      step = 1;
    } else if (status === `pickup started`) {
      label = `Picking Up Vehicle`;
      step = 2;
    } else if (status === `pickup arrived`) {
      label = `Pickup Inspection`;
      step = 2;
    } else if (status === `pickup successful`) {
      label = `Preparing for Delivery`;
      step = 2;
    } else if (status === `delivery started`) {
      label = `Delivering Vehicle`;
      step = 3;
    } else if (status === `delivery arrived`) {
      label = `Delivery Inspection`;
      step = 3;
    } else if (status === `delivery successful`) {
      label = `Delivered Successfully`;
      pulse = false;
    } else {
      // label = `Failed`;
      pulse = false;
    }
  } else {
    // label = `Plan Requested`;
    step = 1;
  }

  if (cancelStatus) {
    pulse = false;
  }

  log && console.log(`Label:`, label);

  let t1Hue = step === 0 || step === 1 ? theme.palette.primary.main : theme.palette.primary.main;
  let t2Hue =
    step === 0 || step === 2
      ? theme.palette.primary.main
      : step === 3
      ? theme.palette.primary.main
      : theme.palette.fade[3];
  let t3Hue = step === 0 || step === 3 ? theme.palette.primary.main : theme.palette.fade[3];
  let t1Pulse = !pulse || (step !== 0 && step !== 1) ? `none` : `pulse 1s infinite`;
  let t2Pulse = !pulse || (step !== 0 && step !== 2) ? `none` : `pulse 1s infinite`;
  let t3Pulse = !pulse || (step !== 0 && step !== 3) ? `none` : `pulse 1s infinite`;

  return (
    <>
      {size === `small` ? (
        <>
          <div className={cls.sm_tracker}>
            <Grid container spacing={0} wrap='nowrap' style={{ height: '40px' }}>
              <Grid item xs={2}>
                <div
                  className={cls.sm_step1}
                  style={{ background: t1Hue, boxShadow: `0 0 0 0 ${t1Hue}`, animation: t1Pulse }}
                >
                  <Typography className={cls.sm_stepTxt} style={{ color: '#fff' }}>
                    PLAN
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={5}>
                <div
                  className={cls.sm_step2}
                  style={{ background: t2Hue, boxShadow: `0 0 0 0 ${t2Hue}`, animation: t2Pulse }}
                >
                  <Typography className={cls.sm_stepTxt} style={{ color: '#fff' }}>
                    PICKUP
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={5}>
                <div
                  className={cls.sm_step3}
                  style={{ background: t3Hue, boxShadow: `0 0 0 0 ${t3Hue}`, animation: t3Pulse }}
                >
                  <Typography className={cls.sm_stepTxt} style={{ color: '#fff' }}>
                    DELIVERY
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <>
          <div className={cls.lg_tracker}>
            <Grid container spacing={0} wrap='nowrap' style={{ height: '64px' }}>
              <Grid item xs={2}>
                <div
                  className={cls.lg_step1}
                  style={{ background: t1Hue, boxShadow: `0 0 0 0 ${t1Hue}`, animation: t1Pulse }}
                >
                  <Typography className={cls.lg_stepTxt} style={{ color: '#fff' }}>
                    PLAN
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={5}>
                <div
                  className={cls.lg_step2}
                  style={{ background: t2Hue, boxShadow: `0 0 0 0 ${t2Hue}`, animation: t2Pulse }}
                >
                  <Typography className={cls.lg_stepTxt} style={{ color: '#fff' }}>
                    PICKUP
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={5}>
                <div
                  className={cls.lg_step3}
                  style={{ background: t3Hue, boxShadow: `0 0 0 0 ${t3Hue}`, animation: t3Pulse }}
                >
                  <Typography className={cls.lg_stepTxt} style={{ color: '#fff' }}>
                    DELIVERY
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}
