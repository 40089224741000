import gql from "graphql-tag";
import fragments from './fragments';

const subscriptions = {
  locations: (updatedDate) => gql`
    subscription {
      locations(
        order_by: {updatedat: asc},
        where: {
          updatedat: {
            _gte: "${updatedDate}"
          }
        }
      ) {
       ...Location
      }
    }
    ${fragments.location}
  `,
  lanes: (updatedDate) => gql`
    subscription {
      lanes(
        order_by: {updatedat: asc},
        where: {
          updatedat: {
            _gte: "${updatedDate}"
          }
        }
      ) {
        ...Lane
      }
    }
    ${fragments.lane}
  `,
  customers: (updatedDate) => gql`
    subscription {
      customers(
        order_by: {updatedat: asc},
        where: {
          updatedat: {
            _gte: "${updatedDate}"
          }
        }
      ) {
        ...Customer
      }
    }
    ${fragments.customer}
  `,
  moves: (lastId, startDate, updatedDate) => gql`
  subscription {
    moves(
      order_by: {updatedat: asc},
      where: {
        _or: {
          id: {
            _gt: "${lastId}"
          },
          pickup_time: {
            _gte: "${startDate}"
          },
          updatedat: {
            _gte: "${updatedDate}"
          }
        }
      }
    ) {
      ...Move
    }
  }
  ${fragments.move}
  `,
  movesByRange: gql`
    subscription movesByDate(
      $startDate: timestamptz!
      $endDate: timestamptz!
      ) {
        moves(order_by: {id: desc}, 
          where: {active: {_eq: 1},
                move_type: {_ilike: "drive"},
            _or: [
              {pickup_time: {_gte: $startDate, _lte: $endDate}},
              {_and: [{ready_by: {_gte: $startDate, _lte: $endDate}},
                {pickup_time: {_is_null: true}}]},
            ]
        }
        ) {
          ...Move
          accessorials {
            ...Accessorials
          }
        }
      }
      ${fragments.move}
      ${fragments.accessorials}
    `,
  //returns all moves with pickup_time within range OR if no pickup_time, with ready_by within range
  allMovesByRange: gql`
    subscription movesByDate(
      $startDate: timestamptz!
      $endDate: timestamptz!
      ) {
        moves(order_by: {id: desc}, 
          where: {active: {_eq: 1},
            _or: [
              {pickup_time: {_gte: $startDate, _lte: $endDate}},
              {_and: [{ready_by: {_gte: $startDate, _lte: $endDate}},
                {pickup_time: {_is_null: true}}]},
            ]
        }
        ) {
          ...Move
        }
      }
      ${fragments.move}
    `,
  favoriteLanes: gql`
    subscription {
      favoritelanes {
        ...FavoriteLane
      }
    }
    ${fragments.favoriteLane}
  `,
  favoriteLocations: gql`
    subscription {
      favoritelocations {
        ...FavoriteLocation
      }
    }
    ${fragments.favoriteLocation}
  `,
  allLocations: gql`
    subscription {
      locations(
        order_by: {name: asc},
      ) {
        ...Location
      }
    }
    ${fragments.location}
  `,
  allLanes: gql`
    subscription {
      lanes(
        order_by: {description: asc},
      ) {
        ...Lane
      }
    }
    ${fragments.lane}
  `,
  allCustomerLocationsAndLanes: gql`
    subscription {
      favoritelanes {
        ...FavoriteLane
      }
      favoritelocations {
        ...FavoriteLocation
      }
      locations(
        order_by: {name: asc},
      ) {
        ...Location
      }
      lanes(
        order_by: {description: asc},
      ) {
        ...Lane
      }
    }
    ${fragments.favoriteLane}
    ${fragments.favoriteLocation}
    ${fragments.location}
    ${fragments.lane}
  `,
  getMoveById: gql`
    subscription lookup_move($id: bigint!) {
      moves(where: {id: {_eq: $id}}, order_by: {delivery_successful: desc}, limit: 1) {
      ...Move
      }
    }
    ${fragments.move}
  `,
};

export default subscriptions;