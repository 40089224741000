import React, { useContext, useState, useEffect } from 'react';
import {
  makeStyles,
  Container,
  Typography,
  Button,
  ButtonGroup,
  TextField,
  MenuItem,
  InputAdornment,
  Icon,
  Select,
  FormControl,
} from '@material-ui/core';
import Loading from '../utils/Loading';
import { Query } from 'react-apollo';
import { GlobalContext } from '../../global-context';
import gql from 'graphql-tag';
import { LanesTable, LanesRow, TableSort } from './lanes/';
import DotMenu from '../reusable/DotMenu';

const log = false;

////////// TOOLS //////////
const defaultOrder = `desc`;
const defaultOrderBy = `id`;

////////// COMPONENT //////////
export default function LaneTableView(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [customerId, setCustomerId] = useState(localStorage.getItem('lanes_search') || 1);
  const [totalRecords, setTotalRecords] = useState(0);

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [expandedRowId, setExpandedRowId] = useState(0);

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(rowsPerPage);

  const [prevBool, setPrevBool] = useState(true);
  const [nextBool, setNextBool] = useState(false);

  const [pageNum, setPageNum] = useState(0);
  const [pageStart, setPageStart] = useState(0);
  const [pageEnd, setPageEnd] = useState(rowsPerPage);

  useEffect(() => {
    getAggregate();
  }, [customerId]);

  useEffect(() => {
    const start = pageNum * rowsPerPage - pageNum;
    const end = pageNum * rowsPerPage + rowsPerPage + pageNum;

    setPages(start, end);
    setButtons(start, end);
    setLimit(rowsPerPage);
  }, [pageNum, rowsPerPage, totalRecords]);

  const getAggregate = () => {
    if (ctx && ctx.userIsAuthenticated()) {
      ctx.apolloClient
        .query({
          query: GET_TOTAL_LANES,
          variables: { customerId: customerId },
        })
        .then(res => {
          setTotalRecords(res.data.lanes_aggregate.aggregate.count);
        })
        .catch(err => {
          console.log(err);
          throw err;
        });
    }
  };

  const setPages = (start, end) => {
    if (pageNum >= 0 && totalRecords) {
      setOffset(start);
      setPageStart(pageNum * rowsPerPage + pageNum);
      if (end < totalRecords && pageNum !== 0) {
        setPageEnd(end);
      } else if (pageNum === 0) {
        if (end > totalRecords) {
          setPageEnd(totalRecords);
        } else setPageEnd(rowsPerPage);
      } else setPageEnd(totalRecords);
    }
  };

  const setButtons = (start, end) => {
    if (totalRecords > 0) {
      if (end >= totalRecords) {
        setNextBool(true);
      } else setNextBool(false);

      if (start <= 0) {
        setPrevBool(true);
      } else setPrevBool(false);
    } else {
      setNextBool(true);
      setPrevBool(true);
    }
  };

  const handlePrev = () => {
    if (pageNum > 0) {
      setPageNum(pageNum - 1);
    }
  };

  const handleNext = () => {
    setPageNum(pageNum + 1);
  };

  const handleCustomerChange = async event => {
    log && console.log('CUSTOMER ID: ', event.target.value);
    if (event.target.value > 0) {
      setCustomerId(event.target.value);
      localStorage.setItem('lanes_search', event.target.value);
    } else {
      setCustomerId(null);
    }

    setPageNum(0);
  };

  const applyFilters = rows => {
    if (!search || search.length < 1) return rows;
    else {
      return rows.filter(
        row =>
          (row.description && row.description.toLocaleLowerCase().includes(search)) ||
          (row.customer.name && (row.customer.name + ``).toLocaleLowerCase().includes(search)) ||
          (row.id && (row.id + ``).toLocaleLowerCase().includes(search))
      );
    }
  };

  const buildContextMenuActions = lane => {
    return [{ name: `lane-details`, label: `View\xa0Lane\xa0Details`, id: lane.id, handler: handleContextMenuClick }];
  };

  const handleContextMenuClick = async (e, data, target) => {
    if (data.action.name === `lane-details`) {
      return setWindow(`lanes/${data.id}`);
    }
  };

  const setWindow = param => {
    return (window.location.href = param);
  };

  const GET_CUSTOMERS = gql`
    query {
      customers(order_by: { id: asc }) {
        id
        name
      }
    }
  `;

  const GET_TOTAL_LANES = gql`
    query MyQuery($customerId: bigint) {
      lanes_aggregate(where: { customer: { id: { _eq: $customerId } } }) {
        aggregate {
          count
        }
      }
    }
  `;

  const GET_LANES = gql`
    query MyQuery($customerId: bigint, $limit: Int, $offset: Int) {
      lanes(
        where: { customer: { id: { _eq: $customerId } }, active: { _eq: "1" } }
        order_by: { updatedat: desc }
        limit: $limit
        offset: $offset
      ) {
        customer {
          id
          name
        }
        distance_miles
        id
        description
      }
    }
  `;

  return (
    <>
      {ctx && ctx.userIsAuthenticated() && (
        <div>
          <div className={cls.headers}>
            <Container className={cls.leftHeaders}>
              <Typography className={cls.lanesHeader}>Lanes</Typography>
            </Container>
            <Container maxWidth='lg'>
              <div className={cls.rightHeaders}>
                <Query
                  query={GET_CUSTOMERS}
                  onError={err => {
                    console.error(err);
                    //Was causing infinited reload on error
                    // this.context.handleNotifications(true, "error", "Query failed to retrieve customers data");
                  }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return 'Loading...';
                    if (error) return `Error! ${error.message}`;
                    const customers = data.customers;
                    return (
                      <TextField
                        className={cls.customerInput}
                        select
                        name='customer'
                        placeholder='Select a customer...'
                        value={customerId ? customerId : 0}
                        onChange={handleCustomerChange}
                        style={cls.customerSelect}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                              <Icon color='disabled' fontSize='small'>
                                group
                              </Icon>
                            </InputAdornment>
                          ),
                        }}
                      >
                        <MenuItem key={'All Customers'} value={0}>
                          <em>All Customers</em>
                        </MenuItem>{' '}
                        :
                        {customers.map(customer => (
                          <MenuItem key={customer.name} value={customer.id}>
                            {customer.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    );
                  }}
                </Query>
                <Button
                  className={cls.btnEl}
                  onClick={() => {
                    return setWindow('lanes/add');
                  }}
                  variant='contained'
                  color='primary'
                >
                  {' '}
                  Create Lane{' '}
                </Button>
              </div>
            </Container>
          </div>
          <Query
            query={GET_LANES}
            variables={{
              customerId: customerId,
              offset: offset,
              limit: limit,
            }}
            onError={err => {
              console.error(err);
              // ctx.handleNotifications(true, "error", "Query failed to retrieve lane records");
            }}
            fetchPolicy='cache-and-network'
          >
            {({ loading, error, data, fetchMore, refetch }) => {
              if (loading) return <Loading />;
              if (error) {
                console.log(`Error getting lane records:`, error);
                return (
                  <div className={cls.rootTable}>
                    <Container maxWidth='sm'>
                      <div className={cls.notFound}>
                        <Typography className={cls.notFoundTxt}>ERROR GETTING LANE RECORDS</Typography>
                      </div>
                    </Container>
                  </div>
                );
              }
              if (data) {
                const filteredData = applyFilters(data.lanes);
                const headers = [
                  { id: `id`, alignLeft: true, numeric: true, label: `ID` },
                  { id: `customer`, alignLeft: true, numeric: false, label: `Customer` },
                  { id: `lane`, alignLeft: true, numeric: true, label: `Lane` },
                  { id: `distance`, alignLeft: true, numeric: true, label: `Distance` },
                ];
                const rows = filteredData.map(lane => {
                  return {
                    id: lane.id || 'N/A',
                    customer: lane.customer.name || 'N/A',
                    lane: lane.description || 'N/A',
                    distance: lane.distance_miles || '--',
                  };
                });

                return (
                  <>
                    <div className={cls.rootTable}>
                      {data.lanes && data.lanes.length > 0 ? (
                        <>
                          <Container maxWidth='lg'>
                            <LanesTable
                              title={`${totalRecords} Lane Records`}
                              hideMobile={true}
                              size={`small`}
                              headers={headers}
                              rows={rows}
                              actions={[]}
                              order={order}
                              orderBy={orderBy}
                              setSearch={setSearch}
                              setOrder={setOrder}
                              setOrderBy={setOrderBy}
                              setExpandedRowId={setExpandedRowId}
                              refetch={refetch}
                              refreshPersistAs='lanes'
                              onLoadMore={() =>
                                fetchMore({
                                  variables: {
                                    customerId: customerId,
                                  },
                                  updateQuery: (prev, { fetchMoreResult }) => {
                                    if (!fetchMoreResult) return prev;
                                    // return Object.assign({}, prev, {
                                    //     lanes: [...prev.lanes, ...fetchMoreResult.lanes]
                                    // });
                                  },
                                })
                              }
                            >
                              {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                                .slice(0, rowsPerPage)
                                .map(row => (
                                  <LanesRow
                                    onClick={() => {
                                      return setWindow(`/lanes/${row.id}`);
                                    }}
                                    key={`lane-${row.id}-row`}
                                    rowId={row.id}
                                    expandedRowId={expandedRowId}
                                    setExpandedRowId={setExpandedRowId}
                                    columns={[
                                      { align: 'left', value: row.id },
                                      { align: 'left', value: row.customer },
                                      { align: 'left', value: row.lane },
                                      { align: 'left', value: `${row.distance}\xa0mi` },
                                    ]}
                                    className={cls.row}
                                  />
                                ))}
                            </LanesTable>
                          </Container>
                        </>
                      ) : (
                        <Container maxWidth='sm'>
                          <div className={cls.notFound}>
                            <Typography className={cls.notFoundTxt}>NO CUSTOMER RECORDS FOUND</Typography>
                          </div>
                        </Container>
                      )}

                      <div className={cls.pagination}>
                        <div className={cls.perPage}>
                          <Typography className={cls.perLabel}>Rows&nbsp;Per&nbsp;Page&nbsp;</Typography>
                          <FormControl>
                            <Select
                              className={cls.perSelect}
                              labelId='demo-simple-select-label'
                              id='demo-simple-select'
                              value={rowsPerPage || 50}
                              onChange={event => setRowsPerPage(event.target.value)}
                            >
                              <MenuItem value={25}>25</MenuItem>
                              <MenuItem value={50}>50</MenuItem>
                              <MenuItem value={100}>100</MenuItem>
                            </Select>
                          </FormControl>
                        </div>

                        <Typography className={cls.pageCount}>
                          {pageStart}&nbsp;to&nbsp;{pageEnd}&nbsp;of&nbsp;{totalRecords}&nbsp;&nbsp;
                        </Typography>

                        <ButtonGroup color='primary' aria-label='outlined primary button group'>
                          <Button onClick={handlePrev} disabled={prevBool}>
                            PREV
                          </Button>
                          <Button onClick={handleNext} disabled={nextBool}>
                            NEXT
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    <div className={cls.rootTable}>
                      <Container maxWidth='sm'>
                        <div className={cls.notFound}>
                          <Typography className={cls.notFoundTxt}>NO CUSTOMER RECORDS FOUND</Typography>
                        </div>
                      </Container>
                    </div>
                  </>
                );
              }
            }}
          </Query>
        </div>
      )}
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  headers: {
    marginTop: theme.spacing(4),
  },
  leftHeaders: {
    width: '100%',
    marginBottom: '-65px',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
    },
  },
  lanesHeader: {
    fontWeight: '700',
    fontSize: '22px',
    marginLeft: theme.spacing(1),
  },
  rightHeaders: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2),
    },
  },
  customerInput: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '20rem',
  },
  btnEl: {
    boxShadow: `none`,
    '&:hover, &:active': {
      boxShadow: `none`,
    },
  },
  headerBreak: {
    marginTop: '2%',
  },
  rootTable: {
    verticalAlign: 'top',
    position: 'relative',
    width: '100%',
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  row: {
    height: 45,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    color: theme.palette.text.primary,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      height: '2rem',
    },
    [theme.breakpoints.up('xl')]: {
      paddingRight: '15%',
    },
  },
  pageCount: {
    fontSize: '13px',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  perPage: {
    display: 'flex',
    fontSize: '13px',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginTop: '-5px',
      marginRight: theme.spacing(1),
    },
  },
  perSelect: {
    fontSize: '13px',
    marginTop: '-5px',
    marginLeft: theme.spacing(1),
  },
  perLabel: {
    fontSize: '13px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      marginLeft: theme.spacing(1),
    },
  },
}));
