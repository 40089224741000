import React, { useContext, useState, useEffect } from 'react';
import { makeStyles, DialogTitle, DialogContent, DialogContentText, Typography, CircularProgress, Dialog, DialogActions, Button, IconButton, Icon, Tooltip } from '@material-ui/core';
import { GlobalContext } from '../../../global-context'
import gql from "graphql-tag";
import RecentDriverACHSelect from './RecentDriverACHSelect'
import axios from 'axios';
import moment from 'moment';

let log = false;

export default function MarkAsPaidModal({
  open,
  setOpen,
  appayments  //expects an array of appayment record objects
}) {
  const ctx = useContext(GlobalContext)
  const cls = useStyles()

  const [loading, setLoading] = useState(false)
  const [achTransaction, setAchTransaction] = useState(null)

  const handleTransactionChange = event => {
    setAchTransaction(event.target.value)
  }

  const handleSetTxn = (txn) => {
    setAchTransaction(txn)
  }

  const handleUpdateQuickbooksPayment = async txn_id => {
    await axios({
      method: 'post',
      url: '/.netlify/functions/handleSuggestedACHTransactions',
      data: { update: true, transaction: txn_id, appayments: appayments },
      headers: {
        'authorization': `Bearer ${ctx.userToken}`
      }
    })
  }

  const handleSaveAPPayment = () => {
    setLoading(true)
    try {
      ctx.apolloClient.mutate({
        mutation: gql`
          mutation updateAPPayment(
            $move_ids: [bigint!]!
            $txn_id: String!
            ) {
            update_appayments(
              where: {move_id: {_in: $move_ids}}
              _set: {
                status: "paid"
                ach_transaction_id: $txn_id
                updatedat: "now()"
              }
              ) {
                affected_rows
                returning {
                  id
                }
              }
            }
          `,
        variables: {
          move_ids: appayments.map(obj => obj.move_id),
          txn_id: achTransaction.toString(),
        },
      })
        .then(async res => {
          setLoading(false)
          if (res.data.update_appayments.affected_rows > 0) {
            let qty = appayments.length;
            await handleUpdateQuickbooksPayment(achTransaction);
            // ctx.handleNotifications(true, "success", `Updated ${qty} AP ${Number(qty) > 1 ? 'payments' : 'payment'}.`);
            setOpen(false);
          }
        })
        .catch(err => ctx.handleNotifications(true, "error", `Failed to update AP payment: ${err.toString()}`))
    } catch (err) {
      ctx.handleNotifications(true, "error", `Failed to update AP payment: ${err.toString()}`)
      setLoading(false)
    }
  }

  return (
    <Dialog
      open={open}
    >
      <DialogTitle>
        Mark As Paid
          <Typography component="div" variant="h6" color="textSecondary" className={cls.subText}>
          ${Number((appayments.reduce((a, b) => a + (b['amount'] || 0), 0)).toFixed(2))}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Did you already pay exactly <b className={cls.boldTxt}>${Number((appayments.reduce((a, b) => a + (b['amount'] || 0), 0)).toFixed(2))}</b> to <b className={cls.boldTxt}>{appayments[0] ? appayments[0].move.driver_name : ''}</b> within the past 4 days?<br />
          <br />
          If so, mark it paid by choosing that specific bank transaction from the list below. We preloaded the list with the most likely recent direct deposits found in our accounting system.          <br /><br />
        </DialogContentText>
        {loading ? (
          <CircularProgress
            style={{
              display: "block",
              margin: "auto",
              position: "absolute",
              left: "0",
              right: "0",
            }}
          />
        ) : (<>
          <RecentDriverACHSelect
            fullwidth
            label="Recent bank transactions"
            onChange={handleTransactionChange}
            setAchTransaction={handleSetTxn}
            driverName={appayments[0] ? appayments[0].move.driver_name : ''}
            totalPayAmount={Number((appayments.reduce((a, b) => a + (b['amount'] || 0), 0)).toFixed(2))}
            value={achTransaction}
          />
          <div>
            <br /><em>Use the refresh button to fetch again if you've made the ACH while on this screen. If you still don't see your direct deposit listed, then double check that you paid the correct amount. <b className={cls.boldTxt}>The amount at the top here must must match exactly to the amount paid.</b></em>
          </div>
        </>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading || !achTransaction}
          className={cls.button}
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => handleSaveAPPayment()}
        >
          Mark Paid
        </Button>
        <Button
          disabled={loading}
          className={cls.button}
          size="small"
          color="secondary"
          variant="outlined"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 250,
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(5)
  },
  refreshBtn: {
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.secondary.veryLight
    }
  }
}));