import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
// import AppBar from '@material-ui/core/AppBar';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import WarningIcon from "@material-ui/icons/Warning";
import { Tooltip } from "@material-ui/core";
// import NewReleasesIcon from '@material-ui/icons/NewReleases';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  animateJiggle: {
    "-webkit-animation": "jiggle 0.2s infinite",
    "-moz-animation-duration": "0.2s",
    "-moz-animation-name": "jiggle",
    "-moz-animation-iteration-count": "infinite",
    "-webkit-transform": "rotate(-3deg)",
    "-moz-transform": "rotate(-3deg)",
  },
}));

export default function RegionTabs(props) {
  const classes = useStyles();
  const StyledBadge = withStyles((theme) => ({
    badge: {
      top: "50%",
      right: -10,
      // The border color match the background color.
      border: `2px solid ${
        theme.palette.type === "light"
          ? theme.palette.grey[200]
          : theme.palette.grey[900]
      }`,
    },
  }))(Badge);
  const StyledTab = withStyles((theme) => ({
    wrapper: {
      position: "absolute",
      bottom: "7px",
    },
  }))(Tab);

  const hasCanceledMoves = (region) => {
    let showError = false;
    props.plans.map((plan) => {
      if (plan.region_id === region.id) {
        if (
          plan.moves.filter(
            (o) =>
              o.cancel_status &&
              ["delivered", "started"].indexOf(o.cancel_status) === -1
          ).length > 0
        )
          showError = true;
      }
    });
    return showError;
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={props.value}
        onChange={props.handleChange}
        aria-label="region tabs"
        indicatorColor="primary"
        textColor="primary"
      >
        <StyledTab
          key={`RegionTab0`}
          label={
            <StyledBadge
              badgeContent={props.unplannedMoves.length}
              color="primary"
              // className={props.unplannedMoves.length > 0 ? classes.animateJiggle : ''}
            >
              <div>All Regions</div>
            </StyledBadge>
          }
          value={0}
        />
        {props.regions && props.regions.length > 0
          ? props.regions.map((region, index) => {
              return (
                <StyledTab
                  key={`RegionTab${region.id}`}
                  label={
                    <StyledBadge
                      badgeContent={
                        props.unplannedMoves.filter(
                          (move) =>
                            move.lane &&
                            move.lane.pickup &&
                            move.lane.pickup.region_id &&
                            move.lane.delivery &&
                            move.lane.delivery.region_id &&
                            (move.lane.pickup.region_id === region.id ||
                              move.lane.delivery.region_id === region.id)
                        ).length
                      }
                      color="primary"
                      className={
                        props.unplannedMoves.filter(
                          (move) =>
                            move.lane &&
                            move.lane.pickup &&
                            move.lane.pickup.region_id &&
                            move.lane.delivery &&
                            move.lane.delivery.region_id &&
                            (move.lane.pickup.region_id === region.id ||
                              move.lane.delivery.region_id === region.id)
                        ).length > 0
                          ? classes.animateJiggle
                          : ""
                      }
                    >
                      {hasCanceledMoves(region) ? (
                        <Tooltip title="There are planned moves that have been canceled.">
                          <WarningIcon color="error" />
                        </Tooltip>
                      ) : null}
                      <div>{region.name}</div>
                    </StyledBadge>
                  }
                  value={region.id}
                />
              );
            })
          : null}
      </Tabs>
    </div>
  );
}
