import React, { useState, useContext, useEffect } from 'react';
import { GlobalContext } from '../../../global-context';
import gql from 'graphql-tag';
import { makeStyles, Typography, Button, Icon, TextField, InputAdornment, MenuItem } from '@material-ui/core';
import RegionSelect from '../../reusable/RegionSelect';

const log = true;

////////// COMPONENT //////////
export default function LocationDetailsEdit(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const { location } = props;

  const [type, setType] = useState(location.type || ``);
  const [name, setName] = useState(location.name || ``);
  const [address, setAddress] = useState(location.address || ``);
  const [regionId, setRegionId] = useState(location.region_id || ``);
  const [nickname, setNickname] = useState(location.nickname || ``);
  const [email, setEmail] = useState(location.email || ``);
  const [phone, setPhone] = useState(location.phone || ``);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    getDisabledButton();
  }, [location, type, name, address, regionId, nickname, email, phone]);

  const handleInputChange = setHandler => event => {
    setHandler(event.target.value);
  };

  const getDisabledButton = () => {
    if (name.length < 1 || address.length < 1) setDisabled(true);
    else if (location.type !== type) setDisabled(false);
    else if (location.name !== name) setDisabled(false);
    else if (location.address !== address) setDisabled(false);
    else if (location.region_id !== regionId) setDisabled(false);
    else if (location.nickname !== nickname) setDisabled(false);
    else if (location.email !== email) setDisabled(false);
    else if (location.phone !== phone) setDisabled(false);
    else setDisabled(true);
  };

  const updateLocation = async () => {
    if (!disabled) {
      const variables = {
        id: location.id,
        type: type,
        name: name,
        address: address,
        regionId: regionId,
        nickname: nickname,
        email: email,
        phone: phone,
      };

      await ctx.apolloClient
        .mutate({
          mutation: UPDATE_LOCATION,
          variables: variables,
        })
        .then(res => {
          if (res.data) {
            log && console.log(`>> Successfully updated location:`, res.data.update_locations.returning[0]);
            ctx.handleNotifications(true, `success`, `Successfully updated location.`);
          }
        })
        .catch(err => {
          console.log(`<< Failed to update location:`, err);
          ctx.handleNotifications(true, `error`, `Failed to update location: ${err}`);
        });
    }
  };

  return (
    <>
      <div className={cls.main}>
        <div className={cls.paper}>
          <Typography className={cls.title}>Required Fields</Typography>

          <div className={cls.break} />

          <TextField
            select
            required
            fullWidth
            label='Type'
            placeholder='Specify a location type...'
            value={type}
            onChange={handleInputChange(setType)}
            margin='dense'
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    label_important
                  </Icon>
                </InputAdornment>
              ),
            }}
            className={cls.field}
          >
            <MenuItem value={`customer`}>Standard</MenuItem>
            <MenuItem value={`consumer business`}>Consumer Business</MenuItem>
            <MenuItem value={`consumer residential`}>Consumer Residential</MenuItem>
          </TextField>

          <div className={cls.break} />

          <TextField
            required
            fullWidth
            label='Name'
            placeholder='Enter a name...'
            value={name}
            onChange={handleInputChange(setName)}
            margin='dense'
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    local_offer
                  </Icon>
                </InputAdornment>
              ),
            }}
            className={cls.field}
          />

          <div className={cls.break} />

          <TextField
            disabled
            required
            fullWidth
            label='Address'
            placeholder='Enter an address...'
            value={address}
            onChange={handleInputChange(setAddress)}
            margin='dense'
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    room
                  </Icon>
                </InputAdornment>
              ),
            }}
            className={cls.field}
          />

          <div className={cls.break} />

          <RegionSelect
            required
            allowAny
            label='Region'
            value={regionId || 0}
            onChange={handleInputChange(setRegionId)}
          />

          <div className={cls.bigBreak} />

          <Typography className={cls.title}>Optional Fields</Typography>

          <div className={cls.break} />

          <TextField
            fullWidth
            label='Nickname'
            placeholder='(Optional) Enter a nickname...'
            value={nickname}
            onChange={handleInputChange(setNickname)}
            margin='dense'
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    loyalty
                  </Icon>
                </InputAdornment>
              ),
            }}
            className={cls.field}
          />

          <div className={cls.break} />

          <TextField
            fullWidth
            type='email'
            label='Email'
            placeholder='(Optional) Enter a related email...'
            value={email}
            onChange={handleInputChange(setEmail)}
            margin='dense'
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    email
                  </Icon>
                </InputAdornment>
              ),
            }}
            className={cls.field}
          />

          <div className={cls.break} />

          <TextField
            fullWidth
            type='phone'
            label='Phone'
            placeholder='(Optional) Enter a related phone number...'
            value={phone}
            onChange={handleInputChange(setPhone)}
            margin='dense'
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    phone_iphone
                  </Icon>
                </InputAdornment>
              ),
            }}
            className={cls.field}
          />

          <div className={cls.break} />

          <div className={cls.actions}>
            <Button
              className={!disabled ? cls.action : cls.actionDisabled}
              disabled={disabled}
              size='large'
              variant='outlined'
              color='primary'
              onClick={() => updateLocation()}
            >
              Save Location
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  main: {},
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  title: {
    fontSize: 21,
    fontWeight: 500,
  },
  field: {
    margin: 0,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  action: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  actionDisabled: {
    backgroundColor: '#00000016',
    '&:hover': {
      backgroundColor: '#00000016',
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
}));

////////// GRAPHQL //////////
const UPDATE_LOCATION = gql`
  mutation update_location(
    $id: bigint!
    $type: String!
    $name: String!
    $address: String!
    $regionId: bigint
    $nickname: String
    $email: String
    $phone: String
  ) {
    update_locations(
      where: { id: { _eq: $id } }
      _set: {
        type: $type
        name: $name
        address: $address
        region_id: $regionId
        nickname: $nickname
        email: $email
        phone: $phone
      }
    ) {
      affected_rows
      returning {
        id
        region_id
        place_id
        type
        name
        address
        nickname
        email
        phone
        latitude
        longitude
      }
    }
  }
`;
