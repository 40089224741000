import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ShareIcon from '@material-ui/icons/Share';
import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { GlobalContext } from '../../../global-context';
import moment from 'moment';
import DotMenu from '../../reusable/DotMenu';
import { withRouter } from 'react-router';
import helpers from '../../utils/helpers';
import copy from 'copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import axios from 'axios';

// var _ = require("lodash");

const log = false;

// let counter = 0;
// function createData(name, calories, fat, carbs, protein) {
//   counter += 1;
//   return { id: counter, name, calories, fat, carbs, protein };
// }

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { numeric: false, disablePadding: true, label: '' },
  { id: 'id', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'reference_num', numeric: false, disablePadding: true, label: 'Ref #' },
  { id: 'pickup_time', numeric: false, disablePadding: true, label: 'Date' },
  { id: 'vehicle_stock', numeric: false, disablePadding: true, label: 'Stock #' },
  { id: 'vehicle_vin', numeric: false, disablePadding: true, label: 'VIN' },
  { id: 'vehicle', numeric: false, disablePadding: true, label: 'Vehicle' },
  { id: 'delivery_name', numeric: false, disablePadding: true, label: 'Delivery Location' },
  { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
  { id: 'menu', numeric: false, disablePadding: true, label: '' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding='checkbox'>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              color='primary'
            />
          </TableCell>
          {rows.map(row => (
            <TableCell
              key={row.id + 'Cell'}
              align='left'
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <Tooltip
                key={row.id + 'Tooltip'}
                title='Sort'
                placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                enterDelay={300}
              >
                <TableSortLabel
                  key={row.id + 'Label'}
                  active={orderBy === row.id}
                  direction={order}
                  onClick={row.id ? this.createSortHandler(row.id) : null}
                >
                  {row.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    // paddingRight:  theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(3),
    boxShadow: `none`,
    '&:hover, &:active': {
      boxShadow: `none`,
    },
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  spacer: {
    flex: '1 1 1%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  actionBtn: {
    // display: "inline-block",
    // float: "right"
  },
  // button: {
  //   margin: theme.spacing,
  //   backgroundColor: '#3f51b5',
  //   color: 'white',
  //   position: 'relative',
  //   bottom: 8,

  // },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color='inherit' variant='subtitle1'>
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant='h6' id='tableTitle'>
            Moves
            <Button className={classes.button} color='primary' variant='contained' onClick={props.handleCreateNew}>
              Create New
            </Button>
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <React.Fragment>
            <Tooltip title='Get a shareable URL for the selected move(s)'>
              <IconButton className={classes.actionBtn} aria-label='Share'>
                <ShareIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Edit the selected move'>
              <IconButton className={classes.actionBtn} aria-label='Edit' disabled={numSelected > 1}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        ) : (
          <Tooltip title='Reset Filter'>
            <IconButton aria-label='Reset Filter' onClick={props.clearFilter}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  paper: {
    width: '100%',
    borderRadius: theme.shape.paperRadius,
    marginTop: theme.spacing(3),
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  table: {
    minWidth: 400,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  image: {
    paddingTop: '3px',
    paddingBottom: '3px',
  },
  tableRow: {
    transition: 'all .2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.01)',
    },
  },
  clickableItem: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

class MovesTableView extends React.Component {
  state = {
    order: 'desc',
    orderBy: 'id',
    selected: [],
    page: 0,
    rowsPerPage: 100,
  };

  componentDidMount = () => {
    this.props.subscribeToNewMoves();
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: this.props.moves.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    this.setState({ selected: newSelected });
  };

  handleCreateNew = event => {
    event.preventDefault();
    this.props.history.push('/moves/add/');
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  clearFilter = () => {
    this.setState({ order: 'desc', orderBy: 'id' });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  openMoveDetails = id => {
    let path = `/moves/${id}`;
    this.props.history.push(path);
  };

  handleRerun = moveId => {
    try {
      axios({
        method: `POST`,
        url: `/.netlify/functions/rerunARMove`,
        data: {
          id: moveId,
          user: this.context.userProfile.email,
        },
        headers: {
          authorization: `Bearer ${this.context.userToken}`,
        },
      })
        .then(res => {
          log && console.log(`Rerun armove success:`, res);
        })
        .catch(err => {
          console.log(`An error occured while attempting to rerun armove:`, err);
        });
    } catch (err) {
      console.log(`An error occured while attempting to rerun armove:`, err);
    }
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, this.props.moves.length - page * rowsPerPage);
    return (
      <React.Fragment>
        <ToastContainer
          position='top-center'
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className={classes.paper}>
          <EnhancedTableToolbar
            selectedID={this.state.selected[0]}
            numSelected={selected.length}
            clearFilter={this.clearFilter}
            handleCreateNew={this.handleCreateNew}
          />
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby='tableTitle'>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={this.props.moves.length}
              />
              <TableBody>
                {stableSort(this.props.moves, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(n => {
                    // const lane = helpers.findLaneById(this.context.lanes, n.lane_id);
                    // n.delivery_name = lane ? lane.delivery.name : "No delivery recorded";
                    if (log) console.log('move:', n);
                    const isSelected = this.isSelected(n.id);
                    const moveActions = [
                      {
                        label: 'See move details',
                        function: () =>
                          this.props.history.push({
                            pathname: `/moves/${n.id}`,
                            state: { move: n },
                          }),
                      },
                      {
                        label: 'Move this car again',
                        function: () =>
                          this.props.history.push(
                            helpers.createNewMoveURL({
                              vehicle_stock: n.vehicle_stock,
                              vehicle_vin: n.vehicle_vin,
                              vehicle_color: n.vehicle_color,
                              vehicle_year: n.vehicle_year,
                              vehicle_make: n.vehicle_make,
                              vehicle_model: n.vehicle_model,
                              manual_flag: n.manual_flag,
                              // autofills pickup field for new move with previous move's destination
                              destination_location_id: n.lane.destination_location_id,
                            })
                          ),
                      },
                      {
                        label: 'Edit Move',
                        function: () => this.props.history.push(`/moves/${n.id}/edit/`),
                      },
                      {
                        label: 'View Lane on Map',
                        function: () => this.props.history.push(`/map/lanes/${n.lane.id}`),
                      },
                      {
                        label: 'Re-run Through AR Engine',
                        function: () => {
                          this.handleRerun(n.id);
                        },
                      },
                      {
                        label: 'Get shareable URL',
                        function: () => {
                          copy(
                            !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
                              ? `https://localhost:8888`
                              : `https://${process.env.REACT_APP_A0_CB_SD}.hopdrive.io` +
                                  helpers.createNewMoveURL({
                                    vehicle_stock: n.vehicle_stock,
                                    vehicle_vin: n.vehicle_vin,
                                    vehicle_color: n.vehicle_color,
                                    vehicle_year: n.vehicle_year,
                                    vehicle_make: n.vehicle_make,
                                    vehicle_model: n.vehicle_model,
                                    manual_flag: n.manual_flag,
                                    // autofills pickup field for new move with previous move's destination
                                    destination_location_id: n.lane.destination_location_id,
                                  })
                          );
                          toast.success('URL copied to clipboard', {
                            position: 'top-center',
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                          });
                        },
                      },
                    ];
                    return (
                      <TableRow
                        hover
                        className={classes.tableRow}
                        role='checkbox'
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                      >
                        <TableCell padding='checkbox'>
                          <Checkbox
                            color='primary'
                            checked={isSelected}
                            onClick={event => this.handleClick(event, n.id)}
                          />
                        </TableCell>
                        <TableCell component='th' scope='row' padding='none'>
                          <img className={classes.image} src='https://via.placeholder.com/75' alt='Vehicle' />
                        </TableCell>
                        <TableCell
                          className={classes.clickableItem}
                          onClick={() => this.openMoveDetails(n.id)}
                          align='left'
                        >
                          {n.id}
                        </TableCell>
                        <TableCell
                          className={classes.clickableItem}
                          onClick={() => this.openMoveDetails(n.id)}
                          align='left'
                        >
                          {n.reference_num}
                        </TableCell>
                        <TableCell
                          className={classes.clickableItem}
                          onClick={() => this.openMoveDetails(n.id)}
                          align='left'
                        >
                          {n.pickup_time ? moment(n.pickup_time).format('LLLL') : moment(n.ready_by).format('LLLL')}
                        </TableCell>
                        <TableCell
                          className={classes.clickableItem}
                          onClick={() => this.openMoveDetails(n.id)}
                          align='left'
                        >
                          {n.vehicle_stock}
                        </TableCell>
                        <TableCell
                          className={classes.clickableItem}
                          onClick={() => this.openMoveDetails(n.id)}
                          align='left'
                        >
                          {n.vehicle_vin}
                        </TableCell>
                        <TableCell
                          className={classes.clickableItem}
                          onClick={() => this.openMoveDetails(n.id)}
                          align='left'
                        >
                          {n.vehicle_year} {n.vehicle_color} {n.vehicle_make} {n.vehicle_model}
                        </TableCell>
                        <TableCell
                          className={classes.clickableItem}
                          onClick={() => this.openMoveDetails(n.id)}
                          align='left'
                        >
                          {n.lane
                            ? n.lane.delivery
                              ? n.lane.delivery.name
                              : 'No destination recorded'
                            : 'No destination recorded'}
                        </TableCell>
                        <TableCell
                          className={classes.clickableItem}
                          onClick={() => this.openMoveDetails(n.id)}
                          align='left'
                        >
                          {n.status}
                        </TableCell>
                        <TableCell align='left'>
                          {' '}
                          <DotMenu actions={moveActions} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component='div'
            count={this.props.moves.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
      </React.Fragment>
    );
  }
}

MovesTableView.contextType = GlobalContext;

MovesTableView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(MovesTableView));
