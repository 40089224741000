import React, { Component } from 'react';
import './index.css';
import { GlobalContext } from './global-context';
import Layout from './components/Layout';
import ApolloClient from 'apollo-client';
import { WebSocketLink } from 'apollo-link-ws';
import { HttpLink } from 'apollo-link-http';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ApolloProvider } from 'react-apollo';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { withWidth } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as Sentry from '@sentry/react';
import { withAuth0 } from '@auth0/auth0-react';
import Loading from './components/utils/Loading';
import _sdk from '@hopdrive/sdk';

let log = false;

const theme = createMuiTheme({
  palette: {
    default: {
      main: `#323232`,
      light: `#646464`,
      dark: `#161616`,
      contrastText: `#ffffff`,
    },

    primary: {
      main: `#f44232`,
      light: `#f88876`,
      dark: `#e82010`,
      contrastText: `#ffffff`,
    },
    secondary: {
      main: `#486496`,
      light: `#7696c0`,
      dark: `#2b3d59`,
      contrastText: `#ffffff`,
    },
    tertiary: {
      main: `#509090`,
      light: `#88b4b4`,
      dark: `#406464`,
      contrastText: `#ffffff`,
    },
    quaternary: {
      main: `#ffc050`,
      light: `#ffda99`,
      dark: `#e8a020`,
      contrastText: `#ffffff`,
    },

    info: {
      main: `#2080ff`,
      light: `#64b5f6`,
      dark: `#1976d2`,
      contrastText: `#ffffff`,
    },
    error: {
      main: `#ff2050`,
      light: `#ffa0a8`,
      dark: `#d41025`,
      contrastText: `#ffffff`,
    },
    warning: {
      main: `#ffa040`,
      light: `#ffb74d`,
      dark: `#f57c00`,
      contrastText: `#ffffff`,
    },
    success: {
      main: `#20c820`,
      light: `#81c784`,
      dark: `#388e3c`,
      contrastText: `#ffffff`,
    },

    text: {
      primary: `#323232`,
      secondary: `#32323296`,
      disabled: `#32323272`,
      hint: `#32323272`,
      contrast: `#ffffff`,
    },

    background: {
      paper: `#ffffff`,
      default: `#fafafa`,
      light: `#f8f8f8`,
      main: `#f4f4f4`,
      dark: `#f0f0f0`,
    },

    fade: [
      `#00000010`,
      `#00000020`,
      `#00000030`,
      `#00000040`,
      `#00000050`,
      `#00000060`,
      `#00000080`,
      `#000000a0`,
      `#000000b4`,
      `#000000c8`,
    ],

    border: `#ddd`,

    divider: `#32323224`,
    softDivider: `#32323216`,
    hardDivider: `#32323232`,

    action: {
      hover: `#32323212`,
      selected: `#32323224`,
      focus: `#32323236`,
      active: `#323232a0`,
      disabled: `#32323264`,
      disabledBackground: `#32323236`,
    },
  },

  border: [
    `1px solid #ddd`,
    `2px solid #ddd`,
    `3px solid #ddd`,
  ],

  shape: {
    borderRadius: `4px`,
    paperRadius: `8px`,
  },

  shadow: {
    main: `0 0 12px #00000024`,
    soft: `0 0 16px #00000016`,
    harsh: `0 0 8px #00000040`,
  },

  typography: { useNextVariants: true },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth0: props.auth0,
      // The below function 'userIsAuthenticated()' determines if a user is both logged in through Auth0 and has an Apollo Client initialized
      // The 'auth0' prop has a boolean 'isAuthenticated' that identifies whether or not the current user is logged in
      // Additionally, we set our own boolean 'apolloInitialized' in state when a user sets up a new Apollo Client with their JWT
      // If both of these values are true, then the user is deemed 'authenticated'
      userIsAuthenticated: () => (this.state.auth0.isAuthenticated && this.state.apolloInitialized ? true : false),
      apolloInitialized: false,
      theme: theme,
      width: props.width,
      notificationShow: false,
      notificationVariant: '',
      notificationMessage: '',
      handleNotifications: async (
        show,
        variant = this.state.notificationVariant,
        message = this.state.notificationMessage
      ) =>
        this.setState({
          notificationShow: show,
          notificationVariant: variant,
          notificationMessage: message,
        }),
      userProfile: {},
      userToken: '',
      redirectPath: null,
      logout: () => {
        if (log) {
          console.log('running logout function');
        }
        props.auth0.logout({
          returnTo:
            !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
              ? `http://localhost:8888/`
              : `https://${process.env.REACT_APP_A0_CB_SD}.hopdrive.io/`,
        });
        this.setState({ userProfile: null, userToken: '', apolloInitialized: false, apolloClient: {} });
      },
      loadGlobalTrips: tripsSub => {
        if (log) {
          console.log('loading trips:', tripsSub);
        }
        this.setState({ trips: tripsSub });
      },
      loadGlobalLanes: lanesSub => {
        if (log) {
          console.log('loading lanes:', lanesSub);
        }
        this.setState({ lanes: lanesSub });
      },
      loadGlobalLocations: locationsSub => {
        if (log) {
          console.log('loading locations:', locationsSub);
        }
        this.setState({ locations: locationsSub });
      },
      loadGlobalCustomers: customersSub => {
        if (log) console.log('loading customers:', customersSub);
        this.setState({ customers: customersSub });
      },
      loadGlobalMoves: movesSub => {
        if (log) console.log('loading moves:', movesSub);
        this.setState({ moves: movesSub });
      },
      setUserAuth: (profile, token) => {
        if (log) console.log('profile', profile, 'and token', token);
        this.setState({ userProfile: profile });
        this.setState({ userToken: token });
      },
      apolloClient: {},
      sdk: null,
      setupApollo: token => {
        if (log) {
          console.log('Setting up the Apollo...');
        }
        const authLink = setContext(async (_, { headers }) => {
            return {
              headers: {
                ...headers,
                authorization: `Bearer ${token}`,
              },
            };
          }),
          wsurl = `wss://${process.env.REACT_APP_GQL_SD}.socialautotransport.com/v1/graphql`,
          httpurl = `https://${process.env.REACT_APP_GQL_SD}.socialautotransport.com/v1/graphql`,
          wsLink = new WebSocketLink({
            uri: wsurl,
            options: {
              lazy: true,
              reconnect: true,
              timeout: 30000,
              connectionParams: async () => {
                return {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                };
              },
            },
          }),
          httpLink = new HttpLink({
            uri: httpurl,
          }),
          link = split(
            // split based on operation type
            ({ query }) => {
              const { kind, operation } = getMainDefinition(query);
              return kind === 'OperationDefinition' && operation === 'subscription';
            },
            wsLink,
            authLink.concat(httpLink)
          ),
          client = new ApolloClient({
            link,
            cache: new InMemoryCache(),
          });
        if (log) {
          console.log('Apollo Client Initialized! ', client);
        }
        _sdk.configure({ apollo_client: client });
        this.setState({ apolloClient: client, apolloInitialized: true, sdk: _sdk });
      },
    };
  }
  buildSentryUserObj = userProfile => ({
    email: userProfile.email,
    name: userProfile.name,
    nickname: userProfile.nickname,
    user: userProfile['https://hasura.io/jwt/claims']['x-hasura-user-id'],
    role: userProfile['https://hasura.io/jwt/claims']['x-hasura-default-role'],
    allowed_roles: userProfile['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'],
    selected_regions: userProfile['https://hasura.io/jwt/claims']['x-hasura-selected-regions'],
    allowed_regions: userProfile['https://hasura.io/jwt/claims']['x-hasura-allowed-regions'],
  });

  // When the Auth0 provider updates with a new auth0 object, check against the current obj
  // If the new object is different, then update the global state
  componentWillReceiveProps = nextProps => {
    if (JSON.stringify({ ...nextProps.auth0 }) !== JSON.stringify({ ...this.state.auth0 }))
      this.setState({ auth0: nextProps.auth0 });
  };

  render() {
    if (this.state.auth0.isLoading) return <Loading />;
    else if (this.state.userIsAuthenticated()) {
      Sentry.setContext('user', this.buildSentryUserObj(this.state.auth0.user));
      log && console.log('Rendering with ApolloProvider now...');
      return (
        <MuiThemeProvider theme={theme}>
          <GlobalContext.Provider value={this.state}>
            <ApolloProvider client={this.state.apolloClient}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Layout />
              </MuiPickersUtilsProvider>
            </ApolloProvider>
          </GlobalContext.Provider>
        </MuiThemeProvider>
      );
    } else {
      if (log) {
        console.log('Rendering without ApolloProvider until user is logged in.');
      }
      return (
        <MuiThemeProvider theme={theme}>
          <GlobalContext.Provider value={this.state}>
            <Layout />
          </GlobalContext.Provider>
        </MuiThemeProvider>
      );
    }
  }
}

export default withWidth()(withAuth0(App));
