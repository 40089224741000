import { GlobalContext } from "../../global-context";
import { ApolloProvider } from "react-apollo";
import React from 'react';
import { Query } from "react-apollo";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import queries from '../utils/graphQL/queries';
import DateFnsUtils from '@date-io/date-fns';
import { Container, Grid, Select, MenuItem, OutlinedInput, FormControl, InputLabel, Button } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import axios from 'axios';
import moment from 'moment';
import Loading from '../utils/Loading';
import DriverPayPage from './driverPay/DriverPayPage';
import ReactToPrint from "react-to-print";
import PrintIcon from '@material-ui/icons/Print';

let log = false

const styles = theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  dropdown: {
    minWidth: 200
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
})

class DriverPay extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().startOf('week').subtract(1, 'week'),
      endDate: moment().startOf('week').subtract(1, 'day'),
      drivers: [],
      selectedDriverId: '',
      selectedDriverObj: {},
    }
  }

  componentWillMount = () => {
    if (this.context && this.context.userIsAuthenticated()) this.getTookanDrivers()
  }

  // Return a list of all drivers from Tookan's API
  getTookanDrivers = () => {
    if (log) console.log("Retrieving drivers from Tookan API");
    const tookanBaseURL = `https://${process.env.REACT_APP_TKN_SD}.socialautotransport.com/V2`;
    if (log) console.log(process.env);
    axios({
      method: "post",
      url: `${tookanBaseURL}/get_available_agents`,
      data: {
        api_key: this.context.userProfile["https://api_keys.io/jwt/claims"]["TookanKey"]
      }
    }).then(res => {
      if (log) console.log("response from getTookanDrivers (res.data):", res.data)
      this.setState({ drivers: res.data.data })
    })
  }

  // Match the id selected to a Tookan driver obj and set to state
  handleDriverSelect = event => {
    const id = event.target.value
    if (id === null || id === '' || !id) {
      this.setState({ selectedDriverId: '', selectedDriverObj: {} })
    } else {
      let foundDriver = this.state.drivers.find(o => o.fleet_id === id)
      this.setState({ selectedDriverId: id, selectedDriverObj: foundDriver }, () => this.forceUpdate())
    }
  }

  handleDateSelect = name => event => this.setState({ [name]: moment(event) })

  assignPrintRef = (ref) => this.printRef = ref

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Container maxWidth="lg">
          <ApolloProvider client={this.context.apolloClient}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around" alignItems="center">
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="driver-select-dropdown">
                    Driver
                  </InputLabel>
                  <Select
                    className={classes.dropdown}
                    margin="none"
                    value={this.state.selectedDriverId}
                    onChange={this.handleDriverSelect}
                    input={<OutlinedInput labelWidth={44} name="driver_id" id="driver-select-dropdown" />}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {this.state.drivers.map(driver =>
                      <MenuItem key={driver.fleet_id + 'key'} value={driver.fleet_id}>{driver.username}</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <KeyboardDatePicker
                  margin="normal"
                  id="startDate"
                  label="Start Date"
                  value={this.state.startDate}
                  onChange={this.handleDateSelect("startDate")}
                  KeyboardButtonProps={{
                    'aria-label': 'change start date',
                  }}
                />
                <KeyboardDatePicker
                  margin="normal"
                  id="endDate"
                  label="End Date"
                  value={this.state.endDate}
                  onChange={this.handleDateSelect("endDate")}
                  KeyboardButtonProps={{
                    'aria-label': 'change end date',
                  }}
                />
                <ReactToPrint
                  trigger={() =>
                    <Button size="small" variant="contained" color="primary" className={classes.button} disabled={!this.state.selectedDriverId > 0}>
                      <PrintIcon className={classes.leftIcon} />
                      Print
                </Button>
                  }
                  pageStyles={'@page { size: auto;  margin: 4cm; } @media print { body { -webkit-print-color-adjust: exact; } }'}
                  content={() => this.printRef}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            { this.context && this.context.userIsAuthenticated() && (
              <Query
                query={queries.allMovesByRange}
                variables={{ startDate: moment(this.state.startDate).format(), endDate: moment(this.state.endDate).add(1, "day").format() }}
                //Was causing infinited reload on error
                // onError={err => {console.error(err);this.context.handleNotifications(true, "error", "Query failed to retrieve moves data")}}
                >
                {({ ...result }) => {
                  if (result.loading) return <Loading />;
                  if (result.error) return `Error! ${result.error.message}`;
                  return <DriverPayPage
                    moves={Array.from(result.data.moves || [])}
                    driverId={this.state.selectedDriverId}
                    driver={this.state.selectedDriverObj}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    assignPrintRef={this.assignPrintRef}
                  />
                }}
              </Query>)
            }
          </ApolloProvider>
        </Container>
      </div>
    )
  }
}

DriverPay.contextType = GlobalContext;

DriverPay.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DriverPay);