import 'date-fns';
import React, { Component } from "react";
import { GlobalContext } from "../../global-context";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MovesTableView from './moves/MovesTableView';
import DateFilter from "../reusable/DateFilter";
import moment from 'moment';
import { Container, Typography, MenuItem, Grid } from "@material-ui/core";
import { Query } from 'react-apollo';
import gql from "graphql-tag";
import fragments from '../utils/graphQL/fragments';
import Loading from '../utils/Loading';

let log = false;

const SEARCH_MOVES = gql`
query get_moves_by_fields(
    $startDate: timestamptz!
    $endDate: timestamptz!
    $searchString: String!
    $searchInt: bigint!
) {
    moves(
        order_by: {updatedat: desc}, 
        where: {
          _or: [
            {id: {_eq: $searchInt}}, 
            {vehicle_color: {_ilike: $searchString}}, 
            {vehicle_make: {_ilike: $searchString}}, 
            {vehicle_model: {_ilike: $searchString}}, 
            {vehicle_stock: {_ilike: $searchString}}, 
            {vehicle_vin: {_ilike: $searchString}}, 
            {vehicle_year: {_ilike: $searchString}},
            {lane: 
              {description: {_ilike: $searchString}}
            }
          ],
           _and:           
          [
            {active: {_eq: "1"}},
            {ready_by: {
            _gte: $startDate,
            _lt: $endDate
            }
            }
          ],

        }
      ) 
      {
        ...Move
      }
    }
    ${fragments.move}
  `;

const SUBSCRIBE_TO_SEARCHED_MOVES = gql`
subscription subscribe_to_searched_moves(
    $startDate: timestamptz!
    $endDate: timestamptz!
    $searchString: String!
    $searchInt: bigint!
) {
    moves(
        order_by: {updatedat: desc}, 
        where: {
          _or: [
            {id: {_eq: $searchInt}}, 
            {vehicle_color: {_ilike: $searchString}}, 
            {vehicle_make: {_ilike: $searchString}}, 
            {vehicle_model: {_ilike: $searchString}}, 
            {vehicle_stock: {_ilike: $searchString}}, 
            {vehicle_vin: {_ilike: $searchString}}, 
            {vehicle_year: {_ilike: $searchString}},
            {lane: 
              {description: {_ilike: $searchString}}
            }
          ],
           _and:           
          [
            {active: {_eq: "1"}},
            {ready_by: {
            _gte: $startDate,
            _lt: $endDate
            }
            }
          ],

        }
      ) {
      ...Move
    }
}
${ fragments.move}
`;

// Values for dropdown which will be used to determine date range
const filterOptions = [
    { name: "today", label: "today" },
    { name: "week to date", label: "week to date" },
    { name: "month to date", label: "month to date" },
    { name: "year to date", label: "year to date" },
    { name: "all time", label: "all time" },
    { name: "custom range", label: "custom range" },
];

// Custom CSS to inject into className as classes.<class defined below> (e.g. className={classes.textField})
const styles = theme => ({
    root: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
      },
    textField: {
        marginTop: "0px !important",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: "0px !important",
        // width: 200,
    },
    menuItem: {
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
    },
});


class SearchResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: moment("March 15th 2018", "MMMM Do YYYY").format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            dateRangeLabel: "all time",
        }
    };

    // Sets dates from local storage - if no range is stored it will default to all time
    componentWillMount = () => {
        const localLabel = localStorage.getItem('dateRangeLabelSR');
        switch (localLabel) {
            case 'today': case 'week to date': case 'month to date': case 'year to date': case 'all time':
                this.setState({
                    dateRangeLabel: localLabel
                })
                this.handleDateRangeConversion(localLabel)
                break;
            case 'custom range':
                this.setState({
                    dateRangeLabel: localLabel,
                    startDate: localStorage.getItem('startDateSR'),
                    endDate: localStorage.getItem('endDateSR')
                })
                break;
            default:
                this.setState({
                    startDate: moment("March 15th 2018", "MMMM Do YYYY").format("YYYY-MM-DD"),
                    endDate: moment().format("YYYY-MM-DD"),
                    dateRangeLabel: 'all time'
                })
        }
    };

    // Match the name of a child component's event and set the value of the corresponding state property
    // Sets dates in localStorage as well
    handleChangeFromChild = (name, value) => {
        this.setState({ [name]: value });
        localStorage.setItem(name+'SR', value);
    };

    // Match a child component's label name to a state property and set its value using the trigger event
    // Sets dateRangeLabel in localStorage as well
    handleDateRangeSelect = (event) => {
        if (log) console.log(event.target)
        this.setState({ dateRangeLabel: event.target.value }, () => {
            this.handleDateRangeConversion(this.state.dateRangeLabel);
        })
        this.setLocalDateRange(event.target.value);
    };

    // Set the date range in state to match the chosen range parameter, set by user
    handleDateRangeConversion = async (dateRange) => {
        switch (dateRange) {
            case 'today':
                await this.setState({
                    endDate: moment().format("YYYY-MM-DD"),
                    startDate: moment().format("YYYY-MM-DD"),
                })
                break;
            case 'week to date':
                await this.setState({
                    endDate: moment().format("YYYY-MM-DD"),
                    startDate: moment().startOf('week').format("YYYY-MM-DD"),
                })
                break;
            case 'month to date':
                await this.setState({
                    endDate: moment().format("YYYY-MM-DD"),
                    startDate: moment().startOf('month').format("YYYY-MM-DD"),
                })
                break;
            case 'year to date':
                await this.setState({
                    endDate: moment().format("YYYY-MM-DD"),
                    startDate: moment().startOf('year').format("YYYY-MM-DD"),
                })
                break;
            case 'all time':
                await this.setState({
                    endDate: moment().format("YYYY-MM-DD"),
                    startDate: moment("March 15th 2018", "MMMM Do YYYY").format("YYYY-MM-DD"),
                })
                break;
            default:
                if (log) console.log("Unknown selection");
        }
    };

    // sets and removes items from localStorage
    setLocalDateRange = (range) => {
        if (range === 'custom range') {
            localStorage.setItem('dateRangeLabelSR', range);
            localStorage.setItem('startDateSR', this.state.startDate);
            localStorage.setItem('endDateSR', this.state.endDate);
        } else {
            localStorage.setItem('dateRangeLabelSR', range);
            localStorage.removeItem('startDateSR');
            localStorage.removeItem('endDateSR');
        }
    }


    render() {
        const { classes } = this.props;
        const searchString = "%"+this.props.location.state.search+"%";
        const searchInt = isNaN(Number(this.props.location.state.search))===true? 0 : Number(this.props.location.state.search);
        console.log('searchInt', searchInt)
        return (
            <div className={classes.root}>
                <Container maxWidth="lg">
                <Grid container>
                    <Grid item xs={12}>
                        <Typography inline="true" variant="h6">
                            Show Results for
                        <TextField
                                id="index-select-date-range"
                                select
                                value={this.state.dateRangeLabel}
                                inputProps={{
                                    className: classes.menuItem,
                                }}
                                onChange={this.handleDateRangeSelect}
                                className={classes.textField}
                                margin="normal"
                            >
                                {filterOptions.map(option => (
                                    <MenuItem key={option.label} value={option.label}>
                                        <Typography inline="true" variant="h6">{option.label}</Typography>
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <DateFilter
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            handleChange={this.handleChangeFromChild}
                            dateRangeLabel={this.state.dateRangeLabel}
                        />
                    </Grid>
                </Grid>

                {/* Query the GQL server based on the selected date range and return all corresponding moves */}
                { this.context && this.context.userIsAuthenticated() && (
                    <Query
                        query={SEARCH_MOVES}
                        variables={
                            {
                                searchString: searchString,
                                searchInt: searchInt,
                                startDate: moment(this.state.startDate).format(),
                                endDate: moment(this.state.endDate).add(1, "day").format(),
                            }
                        }>
                        {({ subscribeToMore, ...result }) => {
                            if (log) console.log("result:", result)
                            if (result.loading) return <Loading />;
                            if (result.error) return `Error! ${result.error.message}`;
                            return (
                                <MovesTableView
                                    // Pass a subscribeToMore function to the child component as a prop that will be called on componentDidMount
                                    //  This will initialize the subscription to keep the cache up to date with current data 
                                    subscribeToNewMoves={() =>
                                        subscribeToMore({
                                            document: SUBSCRIBE_TO_SEARCHED_MOVES,
                                            variables: { 
                                                searchString: searchString,                             
                                                searchInt: searchInt,
                                                startDate: moment(this.state.startDate).format(), 
                                                endDate: moment(this.state.endDate).add(1, "day").format() },
                                            updateQuery: (prev, { subscriptionData }) => {
                                                if (log) console.log("subscriptionData:", subscriptionData, "prev:", prev);
                                                if (!subscriptionData.data) return prev;
                                                return Object.assign({}, prev, subscriptionData.data)
                                            }
                                        })
                                    }
                                    // Feed the array of moves returned from the query as a prop, this will update automatically due to the subscribeToMore function called in child
                                    moves={Array.from(result.data.moves || [])}
                                />
                            );
                        }}
                    </Query>)
                }
                </Container>
            </div>
        )
    }
}

SearchResults.contextType = GlobalContext;

SearchResults.propTypes = {
    classes: PropTypes.object.isRequired,
};

// withStyles makes the custom CSS classes defined above available as classNames
export default withStyles(styles)(SearchResults);
