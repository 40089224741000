import React, { useContext } from "react";
import BusinessRulesTableView from './businessRules/BusinessRulesTableView.js'
import gql from "graphql-tag";
import { Subscription } from "react-apollo";
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../utils/Loading';
import { Container } from '@material-ui/core';
import { GlobalContext } from "../../global-context";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));

export default function BusinessRules() {
  const context = useContext(GlobalContext);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        { context && context.userIsAuthenticated() && (
          <Subscription
            subscription={GET_ALL_RULES}
            // variables={{ startDate: moment(startDate).format(), endDate: moment(endDate).add(1, "day").format() }}
            //Was causing infinited reload on error
            // onError={err => context.handleNotifications(true, "error", "Query failed to retrieve moves data: " + err.toString())}
            >
            {({ ...result }) => {
              if (result.loading) return <Loading />;
              if (result.error) return `Error! ${result.error.message}`;
              return (
                <BusinessRulesTableView rules={result.data.businessrules} />
              );
            }}
          </Subscription>)
        }
      </Container>
    </div>
  )
}

const GET_ALL_RULES = gql`
query get_rules {
    businessrules(where: {active: {_eq: true}}) {
        id
        name
        type
        ruletype{
            id
            name
            description
        }
        description
      jsonb
    }
  }
`;