/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

// MoveAttributeAutoSuggest class begins on line 165
// Some of the functional components are not utilized but allow for additional functionality such as multiple values as opposed to single-value inputs

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: 220,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class MoveAttributeAutoSuggest extends React.Component {

  // Generates a list of matching attributes after typing at least 3 characters in the input field
  getSuggestions = () => {
    if (this.props[this.getAttributeName()] && this.props[this.getAttributeName()].trim().length > 2) {
      return this.props.queriedMoves.map(move => ({
        value: move[this.props.attribute],
        label: move[this.props.attribute]
      }))
    } else {
      return []
    }
  };

  // Gets a prop name from the attribute prop passed in
  getAttributeName = () => {
    let attArr = this.props.attribute.split('_');
    return attArr[1];
  }

  render() {
    const { classes, theme } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            value={this.props[this.getAttributeName()] ? this.props[this.getAttributeName()] : ''}
            displayEmpty={true}
            classes={classes}
            styles={selectStyles}
            options={this.getSuggestions()}
            components={components}
            textFieldProps={{
              onChange: this.props.handleChange(this.getAttributeName()),
              value: this.props[this.getAttributeName()] ? this.props[this.getAttributeName()] : '',
              InputLabelProps: {
                shrink: true,
              },
            }}
            inputValue={this.props[this.getAttributeName()] ? this.props[this.getAttributeName()] : ''}
            onChange={this.props.handleAttributeChange(this.getAttributeName())}
            placeholder={this.props.inputLabel}
            isClearable
            id={`react-select-${this.getAttributeName()}`}
          />
          <div className={classes.divider} />
        </NoSsr>
      </div>
    );
  }
}

MoveAttributeAutoSuggest.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MoveAttributeAutoSuggest);
